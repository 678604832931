import { mapValues } from "lodash";
import { EnumField } from "../../../fields/EnumField";
import { communicationMediumConfigs } from "../type";

type CommunicationMediumFieldProps = {
  label?: string;
};

export function CommunicationMediumField({
  label,
}: CommunicationMediumFieldProps) {
  return (
    <EnumField
      label={label || "Medium"}
      source="medium"
      colors={mapValues(communicationMediumConfigs, (v) => v.color["400"])}
      labels={mapValues(communicationMediumConfigs, (v) => v.label)}
    />
  );
}
