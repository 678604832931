import { Alert } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecordContext } from "react-admin";
import type { Project } from "../type";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const ListingImportField = () => {
  const project = useRecordContext<Project>();

  if (!project?.lastListingImport) {
    return null;
  }

  if (project.lastListingImport.errorMessages.length > 0) {
    return (
      <Alert severity="error">
        Incomplet listing import{" "}
        {dayjs
          .duration(dayjs(project.lastListingImport.date).diff())
          .humanize(true)}
        :
        <ul>
          {project.lastListingImport.errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </Alert>
    );
  } else {
    return (
      <Alert severity="success">
        Dernier import du listing locataire complet il y a{" "}
        {dayjs
          .duration(dayjs().diff(project.lastListingImport.date))
          .humanize()}
      </Alert>
    );
  }
};
