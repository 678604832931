import { Divider, Grid, Paper } from "@mui/material";
import { useRecordContext } from "react-admin";
import { YearlyNormalizationSelect } from "../../../../../layout/dashboard/YearlyNormalizationSelect";
import { ConsumptionGraph } from "../../../../../layout/dashboard/widgets/Consumption";
import { ContractType } from "../../../../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../../../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../../../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../../../../layout/dashboard/widgets/HeaterState";
import { SavingsAverageGraph } from "../../../../../layout/dashboard/widgets/SavingsAverage";
import { SavingsState } from "../../../../../layout/dashboard/widgets/SavingsState";
import { V1Alert } from "../../../../devices/reporting/V1Alert";
import type { Project } from "../../../type";

export const ProjectOverview = () => {
  const project = useRecordContext<Project>();

  if (!project) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YearlyNormalizationSelect />
        </Grid>
        <Grid item xs={12}>
          {project && <V1Alert filter={{ projectId: project.id }} />}
        </Grid>
        <ConsumptionGraph filter={project && { projectId: project.id }} />
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={1}>
            <SavingsAverageGraph
              filter={project && { projectId: project.id }}
            />
          </Paper>
        </Grid>

        <HealthRisk filter={project && { projectId: project.id }} />
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={6}>
          <SavingsState filter={project && { projectId: project.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContractType filter={project && { projectId: project.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HeatersState filter={project && { projectId: project.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DevicesState filter={project && { projectId: project.id }} />
        </Grid>
      </Grid>
    </>
  );
};
