import Icon from "@mui/icons-material/QrCode2";
import { useExport } from "../../../actions/useExport";
import { ActionMenuItem, ActionsMenu } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const FeedbackActions = () => {
  const { mutate } = useExport(() => dataProvider.exportResidentAppAnalytics());

  return (
    <ActionsMenu>
      <ActionMenuItem
        label="Export QR Code visits"
        icon={<Icon />}
        onClick={mutate}
      />
    </ActionsMenu>
  );
};
