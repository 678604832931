import { Typography } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
} from "react-admin";
import { ZendeskTicketStatusField } from "../../fields/ZendeskTicketStatusField";
import { ZendeskTicketUrlField } from "../../fields/ZendeskTicketUrlField";
import { ZendeskUserUrlField } from "../../fields/ZendeskUserUrlField";

export function ZendeskTicketsTab() {
  return (
    <ReferenceField reference="end-user-zendesk-tickets" source="id">
      <Typography variant="h6">
        Zendesk tickets
        <ZendeskUserUrlField />
      </Typography>
      <ArrayField source="zendeskTickets">
        <Datagrid
          bulkActionButtons={false}
          expand={
            <TextField
              label="Description"
              source="description"
              display="block"
              sx={{ whiteSpace: "pre-wrap" }}
            />
          }
        >
          <DateField label="Date" source="createdAt" showTime />
          <ZendeskTicketStatusField label="Status" />
          <TextField label="Type de demandeur" source="askingType" />
          <TextField
            label="Analyse technique de la cause du problème"
            source="problemCause"
          />
          <ZendeskTicketUrlField label="Open in Zendesk" />
        </Datagrid>
      </ArrayField>
    </ReferenceField>
  );
}
