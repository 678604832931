import {
  Datagrid,
  EditButton,
  List,
  ListActions,
  TextField,
} from "react-admin";
import { MaintenanceSpreadsheetField } from "./fields/MaintenanceSpreadsheetField";

export const MaintenanceProviderList = () => {
  return (
    <List perPage={100} actions={<ListActions hasCreate />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <MaintenanceSpreadsheetField label="Maintenance Spreadsheet" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};
