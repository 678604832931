import { Alert, Grid } from "@mui/material";
import dayjs from "dayjs";
import { useWatch } from "react-hook-form";

export const SameDayAlert = () => {
  const schedule = useWatch({ name: "schedule" });

  if (!schedule) {
    return null;
  }
  if (schedule.recurrence === "NONE") {
    if (!schedule.day) {
      return null;
    }
    if (schedule.day !== dayjs().format("YYYY-MM-DD")) {
      return null;
    }
  } else {
    if (!schedule.startDate) {
      return null;
    }
    if (schedule.startDate !== dayjs().format("YYYY-MM-DD")) {
      return null;
    }
  }
  return (
    <Grid item sm={12}>
      <Alert severity="warning">
        Dans certains cas aux limites, un effacement définie le jour même peut
        ne pas être appliqué. Merci de prévenir l'équipe technique pour
        s'assurer du bon fonctionnement de l'effacement
      </Alert>
    </Grid>
  );
};
