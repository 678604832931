import UserIcon from "@mui/icons-material/People";
import { UserCreate, UserEdit } from "./UserEdit";
import { UserList } from "./UserList";

export const userResource = {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  icon: UserIcon,
  recordRepresentation: "email",
};

export { default as UserIcon } from "@mui/icons-material/People";
