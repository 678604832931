import {
  useListContext,
  useRecordContext,
  useResourceContext,
} from "react-admin";

export const useBulkActionFilter = () => {
  const { selectedIds, filterValues } = useListContext();
  const record = useRecordContext();
  const resource = useResourceContext();

  let filter = filterValues ? { ...filterValues } : {};
  // search does not count as an active filter
  delete filter.q;

  if (selectedIds?.length > 0) {
    filter.id = selectedIds;
  }
  if (resource === "devices" && record) {
    filter.id = record.id;
  }
  if (resource === "projects" && record) {
    filter = {
      projectId: record.id,
    };
  }
  if (resource === "groups" && record) {
    filter = {
      group: {
        $in: [record.id],
      },
    };
  }
  if (resource === "residences" && record) {
    filter = {
      residenceId: record.id,
    };
  }

  return filter;
};
