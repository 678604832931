import DeviceConfigPersonaIcon from "@mui/icons-material/Diversity1";
import { DeviceConfigPersonaCreate, DeviceConfigPersonaEdit } from "./Edit";
import { DeviceConfigPersonaList } from "./List";
import { DeviceConfigPersonaShow } from "./Show";

export const deviceConfigPersonaResource = {
  list: DeviceConfigPersonaList,
  edit: DeviceConfigPersonaEdit,
  create: DeviceConfigPersonaCreate,
  show: DeviceConfigPersonaShow,
  icon: DeviceConfigPersonaIcon,
  recordRepresentation: "name",
};

export { default as DeviceConfigPersonaIcon } from "@mui/icons-material/Diversity1";
