import {
  Datagrid,
  NumberField,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { DeploymentProgress } from "../../project/fields/DeploymentProgress";
import { ProjectDeploymentEndDate } from "../../project/fields/ProjectDeploymentEndDate";
import { ProjectDeploymentStartDate } from "../../project/fields/ProjectDeploymentStartDate";
import { ProjectStageField } from "../../project/fields/Status";

export const DealProjectsTab = () => {
  return (
    <ReferenceManyField
      reference="projects"
      target="dealId"
      sort={{ field: "createdAt", order: "ASC" }}
      perPage={1000}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <ProjectStageField label="Status" />
        <DeploymentProgress
          entity="project"
          label="Deployment"
          showCount={false}
        />
        <ProjectDeploymentStartDate label="Deployment Start Date" />
        <ProjectDeploymentEndDate label="Deployment End Date" />
        <NumberField label="Housings" source="housingCount" />
      </Datagrid>
    </ReferenceManyField>
  );
};
