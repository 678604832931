import { CountEnumChart } from "../../../layout/dashboard/widgets/CountEnumChart";
import { interventionSourcingTypeLabels } from "../types";
import type { InterventionSourcingType } from "../types";
import { useInstallationPieFilter } from "./useInstallationPieFilter";

export const SourcingTypeStats = ({
  entity,
}: {
  entity: "deal" | "project";
}) => {
  const { counts, filteredValue, toggleFilter } = useInstallationPieFilter(
    "sourcingType",
    entity
  );

  return (
    <CountEnumChart
      title="Type de RDV"
      onClick={(point: any) => toggleFilter(point.custom.status)}
      data={
        counts &&
        Object.keys(counts).map((status) => ({
          name:
            status in interventionSourcingTypeLabels
              ? interventionSourcingTypeLabels[
                  status as InterventionSourcingType
                ]
              : "Vide",
          custom: {
            status,
          },
          y: counts[status],
          sliced: filteredValue === status,
        }))
      }
    />
  );
};
