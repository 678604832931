import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { InterventionType } from "../types";

export const ReplacedDeviceInput = () => {
  const type = useWatch({ name: "type" });

  if (type === InterventionType.REPLACEMENT) {
    return (
      <ReferenceInput
        reference="devices"
        source="replacedDeviceId"
        fullWidth
        perPage={1000}
        isRequired
      >
        <AutocompleteInput
          label="Replaced Device"
          optionText="name"
          fullWidth
        />
      </ReferenceInput>
    );
  }

  return null;
};
