import type { Dayjs } from "dayjs";
import { useState } from "react";

export const granularities = [
  {
    key: "monthly",
    value: "months",
    label: "mensuelle",
    format: (d: Dayjs) => d.format("YYYY-MM"),
    history: 12,
  },
  {
    key: "weekly",
    value: "weeks",
    label: "hebdomadaire",
    format: (d: Dayjs) => d.format("YYYY-MM-DD"),
    history: 12,
  },
  {
    key: "daily",
    value: "days",
    label: "quotidienne",
    format: (d: Dayjs) => d.format("YYYY-MM-DD"),
    history: 31,
  },
] as const;

export const useGranularity = () => {
  const [granularity, setGranularity] = useState<
    (typeof granularities)[number]
  >(granularities[1]);

  return [granularity, setGranularity] as const;
};
