import { Alert } from "@mui/material";
import { ReferenceField, WithRecord } from "react-admin";
import { useDeviceEndUser } from "../../../../../providers/data-hooks/useDeviceEndUser";
import { serializeEndUserName } from "../../../../end-users/fields/NameField";
import type { Device } from "../../../types";

export const PersonaAlert = ({ device }: { device: Device }) => {
  const { endUser } = useDeviceEndUser(device);

  if (!endUser?.deviceConfigPersona) {
    return null;
  }

  return (
    <Alert severity="error" sx={{ marginY: 1, width: "100%" }}>
      <b>{serializeEndUserName(endUser)}</b> is assigned persona{" "}
      <b>
        <ReferenceField
          reference="device-config-personas"
          record={endUser}
          source="deviceConfigPersona"
          link={false}
        >
          <WithRecord render={(persona) => persona.name} />
        </ReferenceField>
      </b>
      . Smart parameters can't be edited.
    </Alert>
  );
};
