import { Box, Grid } from "@mui/material";
import { ReferenceManyField, Show, TextField, WithRecord } from "react-admin";
import { DevicesDatagrid } from "../devices/List";
import { GroupActions } from "./actions";
import { RemoveFromGroupButton } from "./actions/RemoveFromGroup";
import { GroupIcon } from ".";

export const GroupShow = () => {
  return (
    <Show actions={<GroupActions />}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          margin={2}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GroupIcon fontSize="large" sx={{ marginRight: 1 }} />
            <TextField source="groupName" variant="h4" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField label="groupDescription" source="name" />
        </Grid>
        <WithRecord
          render={(record) => (
            <Grid item xs={12}>
              <ReferenceManyField
                reference="devices"
                target="group"
                label={false}
                perPage={1000}
                fullWidth
              >
                <DevicesDatagrid bulkActionButtons={false}>
                  <RemoveFromGroupButton groupId={record.id as string} />
                </DevicesDatagrid>
              </ReferenceManyField>
            </Grid>
          )}
        />
      </Grid>
    </Show>
  );
};
