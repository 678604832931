import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DateField, RecordContextProvider, ReferenceField } from "react-admin";
import { useActivityLogs } from "./hooks/useActivityLogs";
import { ShowActivityLog } from "./show/Show";

export const EditionHistory = () => {
  const { data, total } = useActivityLogs();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {"Edition history" + (data ? ` (${total})` : "")}
      </Typography>
      {data?.map((activityLog) => (
        <Accordion key={activityLog.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              <DateField record={activityLog} source="createdAt" showTime />
            </Typography>
            <Typography>
              {activityLog.action} by{" "}
              <ReferenceField
                record={activityLog}
                reference="users"
                source="userId"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RecordContextProvider value={activityLog}>
              <ShowActivityLog />
            </RecordContextProvider>
          </AccordionDetails>
        </Accordion>
      )) || <CircularProgress size={15} />}
    </>
  );
};
