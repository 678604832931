import { Grid } from "@mui/material";
import { DateInput, SelectInput } from "react-admin";
import { useWatch } from "react-hook-form";

export const ScheduleForm = () => {
  return (
    <>
      <Grid item sm={12}>
        <RecurrenceInput />
      </Grid>
      <RecurrenceForm />
    </>
  );
};

const RecurrenceForm = () => {
  const recurrence = useWatch({ name: "schedule.recurrence" });

  if (recurrence === "NONE") {
    return (
      <Grid item sm={12}>
        <DateInput
          key="schedule.day"
          source="schedule.day"
          label="Day"
          fullWidth
        />
      </Grid>
    );
  }

  if (recurrence === "DAILY" || recurrence === "WORKING_DAYS") {
    return (
      <>
        <Grid item sm={6}>
          <DateInput
            key="schedule.startDate"
            source="schedule.startDate"
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <DateInput
            key="schedule.endDate"
            source="schedule.endDate"
            fullWidth
          />
        </Grid>
      </>
    );
  }

  if (recurrence === "WEEKLY") {
    return (
      <>
        <Grid item sm={12}>
          <SelectInput
            source="schedule.weekday"
            choices={[
              { id: 1, name: "Lundi" },
              { id: 2, name: "Mardi" },
              { id: 3, name: "Mercredi" },
              { id: 4, name: "Jeudi" },
              { id: 5, name: "Vendredi" },
              { id: 6, name: "Samedi" },
              { id: 0, name: "Dimanche" },
            ]}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <DateInput source="schedule.startDate" fullWidth />
        </Grid>
        <Grid item sm={6}>
          <DateInput source="schedule.endDate" fullWidth />
        </Grid>
      </>
    );
  }

  return null;
};

const RecurrenceInput = () => {
  return (
    <SelectInput
      source="schedule.recurrence"
      fullWidth
      choices={[
        {
          id: "NONE",
          name: "None",
        },
        {
          id: "DAILY",
          name: "Daily",
        },
        {
          id: "WEEKLY",
          name: "Weekly",
        },
        {
          id: "WORKING_DAYS",
          name: "Working Days",
        },
      ]}
    />
  );
};
