import { NumberInput } from "react-admin";
import type { NumberInputProps } from "react-admin";

export const NoWheelNumberInput = (props: NumberInputProps) => {
  return (
    <NumberInput
      // see https://github.com/mui/material-ui/issues/7960
      // generates corrupted data for device config edition
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      {...props}
    />
  );
};
