import { useCallback } from "react";
import { createStateContext } from "react-use";

const [useActionMenuState, ActionMenuStateProvider] =
  createStateContext<null | HTMLElement>(null);

export const useCloseMenu = () => {
  const [, setAnchorEl] = useActionMenuState();

  return useCallback(() => setAnchorEl(null), [setAnchorEl]);
};

export { useActionMenuState, ActionMenuStateProvider };
