import { CountEnumChart } from "../../../layout/dashboard/widgets/CountEnumChart";
import { useInstallationPieFilter } from "./useInstallationPieFilter";

export const SourcingStatusStats = ({
  entity,
}: {
  entity: "deal" | "project";
}) => {
  const { counts, filteredValue, toggleFilter } = useInstallationPieFilter(
    "sourcingStatus",
    entity
  );

  return (
    <CountEnumChart
      title="Statut RDV"
      onClick={(point) => toggleFilter(point.name)}
      data={
        counts &&
        Object.keys(counts).map((status) => ({
          name: status,
          y: counts[status],
          sliced: filteredValue === status,
        }))
      }
    />
  );
};
