import { chain } from "lodash";
import { useRecordContext } from "react-admin";
import { useDeviceDailyStats } from "../../../../../../../providers/data-hooks/useDeviceDailyStats";
import type { Device } from "../../../../../types";
import { addHeatmapColors } from "./heatmap-utils";

export const useDailyEnergyTimeline = (options: { enabled: boolean }) => {
  const record = useRecordContext<Device>();
  const { data } = useDeviceDailyStats(record?.id, options);

  return addHeatmapColors(
    chain(data)
      .keyBy((e) => e.day)
      .mapValues((e) => ({ value: e.energy }))
      .value() ?? {},
    energyPalette
  );
};

export const energyPalette = [
  "#ffffcc",
  "#ffeda0",
  "#fed976",
  "#feb24c",
  "#fd8d3c",
  "#fc4e2a",
  "#e31a1c",
  "#bd0026",
  "#800026",
];
