import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { uniq } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Form, SaveButton, SelectInput, useResourceContext } from "react-admin";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { useAllowedModes } from "../../../device-config/Edit";
import { Parameters } from "../../../device-config/parameters";
import type { DevicesFilter } from "../../types";
import { ReportPilotChange } from "./ReportPilotChange";
import { useEditDeviceConfig } from "./useEditDeviceConfig";

export const ChangePilotConfig = ({ filter }: { filter: DevicesFilter }) => {
  const resource = useResourceContext();
  const valuesRef = useRef({});
  const { edit, results, submitted, devices, editDevice, isPending } =
    useEditDeviceConfig(`Updated from ${resource} update`, filter);
  const [savedValues, setValues] = useState<any>();

  const [expandedPanels, setExpandedPanels] = useState(["base", "hc"]);

  useEffect(() => {
    if (submitted) {
      setExpandedPanels((v) => [...v, "devices"]);
    }
  }, [submitted]);

  const v1HCModes = useAllowedModes(
    {
      majorHWVersion: 1,
      bypassedRemoteSwitch: true,
    },
    true
  );
  const v1BaseModes = useAllowedModes(
    {
      majorHWVersion: 1,
      bypassedRemoteSwitch: true,
    },
    false
  );
  const v2HCModes = useAllowedModes(
    {
      majorHWVersion: 2,
      bypassedRemoteSwitch: true,
    },
    true
  );
  const v2BaseModes = useAllowedModes(
    {
      majorHWVersion: 2,
      bypassedRemoteSwitch: true,
    },
    true
  );

  return (
    <DialogActionMenuItem
      label="Edit Config"
      icon={<SettingsIcon />}
      dialog={
        <ActionDialog maxWidth="lg" fullWidth>
          <Form
            onSubmit={(values: any) => {
              edit(values);
              setValues(values);
            }}
            record={valuesRef.current}
          >
            <DialogTitle>Change Pilot Config</DialogTitle>
            <DialogContent>
              {[
                { title: "Base devices", path: "base", hc: false },
                { title: "HP/HC devices", path: "hc", hc: true },
              ].map(({ title, path, hc }) => (
                <Accordion
                  key={path}
                  expanded={expandedPanels.includes(path)}
                  onChange={(_, isExpanded) => {
                    setExpandedPanels((values) =>
                      isExpanded
                        ? [...values, path]
                        : values.filter((v) => v !== path)
                    );
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SelectInput
                      source={`${path}.mode`}
                      choices={uniq([
                        ...(hc ? v1HCModes : v1BaseModes),
                        ...(hc ? v2HCModes : v2BaseModes),
                      ]).map((mode) => ({
                        id: mode,
                        name: mode,
                      }))}
                      fullWidth
                    />
                    <Parameters source={`${path}.parameters`} isHPHC={hc} />
                  </AccordionDetails>
                </Accordion>
              ))}
              <Accordion
                expanded={expandedPanels.includes("devices")}
                onChange={(_, isExpanded) => {
                  setExpandedPanels((values) =>
                    isExpanded
                      ? [...values, "devices"]
                      : values.filter((v) => v !== "devices")
                  );
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">
                    Devices ({devices?.length ?? "..."})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ReportPilotChange
                    devices={devices || []}
                    results={results}
                    retry={(device) => editDevice(device, savedValues)}
                  />
                </AccordionDetails>
              </Accordion>
            </DialogContent>
            <DialogActions>
              <ActionDialogButton
                onClick={(_, close) => {
                  if (isPending) {
                    if (
                      !window.confirm(
                        "You are trying to close this dialog whereas some configs are not yet applied. This might generate bugs."
                      )
                    ) {
                      return;
                    }
                    close();
                  }
                }}
              >
                {submitted ? "Close" : "Cancel"}
              </ActionDialogButton>
              {!submitted && (
                <SaveButton variant="contained" label="Change Pilot Config" />
              )}
            </DialogActions>
          </Form>
        </ActionDialog>
      }
    />
  );
};
