import Icon from "@mui/icons-material/Engineering";
import { useBulkActionFilter } from "../../../../actions/useBulkActionFilter";
import { useExport } from "../../../../actions/useExport";
import { ActionMenuItem } from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";

export const ExportIncidents = () => {
  const filter = useBulkActionFilter();

  const { mutate } = useExport(() => dataProvider.exportIncidents(filter));
  return (
    <ActionMenuItem
      label="Export Filtered Incidents"
      icon={<Icon />}
      onClick={mutate}
    />
  );
};
