import { useListContext } from "react-admin";

type InlineListProps = {
  source: string;
};

export const InlineList = ({ source }: InlineListProps) => {
  const { data } = useListContext();

  return <>{data?.map((record) => record[source]).join(", ")}</>;
};
