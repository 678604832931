import { Table, TableBody, TableCell, TableRow } from "@mui/material";

type UpdateManyActivityLogProps = {
  payload: any;
};

export const UpdateManyActivityLog = ({
  payload,
}: UpdateManyActivityLogProps) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Target</TableCell>
          <TableCell>
            {Object.entries(payload.filter ?? {}).map(([key, value]) => (
              <li key={key}>{`${key}=${value}`}</li>
            ))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Update</TableCell>
          <TableCell>
            {Object.entries(payload.data ?? {}).map(([key, value]) => (
              <li key={key}>{`${key}=${value}`}</li>
            ))}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
