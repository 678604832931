import {
  useGetMany,
  useGetManyReference,
  useGetOne,
  useRecordContext,
} from "react-admin";

export const useLastDeviceConfig = () => {
  const record = useRecordContext();

  const { data } = useGetManyReference(
    "device-configs",
    {
      target: "deviceId",
      id: record?.id,
      pagination: { perPage: 1, page: 1 },
      sort: { field: "createdAt", order: "DESC" },
    },
    { enabled: Boolean(record) }
  );

  if (!data || data.length === 0) {
    return null;
  }

  return data[0];
};

export const useGroups = () => {
  const record = useRecordContext();

  const { data } = useGetMany(
    "groups",
    {
      ids: record?.group,
    },
    { enabled: Boolean(record) }
  );

  return (data ?? []).map((group) => group.groupName);
};

export const useOwner = () => {
  const record = useRecordContext();

  const { data } = useGetOne(
    "users",
    {
      id: record?.owner,
    },
    { enabled: Boolean(record?.owner) }
  );

  return data;
};
