export type DeviceConfig = {
  id: string;
  createdAt: string;
  mode: Mode;
  notes?: string;
  parameters: any;
};

export enum Mode {
  SET_POINT = "SetPoint",
  OBSERVATION = "Observation",
  PRE_OBSERVATION = "PreObservation",
  SCHEDULE = "Schedule",
  SMART = "Smart",
  SMART_ENERGY = "Smart Energy",
  SMART_FLEX = "Smart Flex EDF",
  SMART_FLEX_v2 = "Smart Flex EDF V2",
}

export const MODES_COMPATIBLE_WITH_PERSONAS = [Mode.SMART, Mode.SMART_ENERGY];
