import { FunctionField } from "react-admin";
import type { Housing } from "../types";

export const AddressField = ({ label }: { label?: string }) => (
  <FunctionField<Housing>
    label={label}
    render={(record) => record && formatAddress(record)}
  />
);

export const formatAddress = (record: Housing) =>
  `${record.additionalAddress ? `${record.additionalAddress} - ` : ""} ${
    record.address
  } ${record.zipCode} ${record.city}`;
