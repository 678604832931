import { FunctionList } from "../../../../../display/FunctionList";
import { DenseCommunication } from "../../../../communications/fields/DenseCommunication";

export function CommunicationSummary() {
  return (
    <FunctionList>
      <DenseCommunication />
    </FunctionList>
  );
}
