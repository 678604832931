import type { RaRecord } from "react-admin";

export const prepareFiles =
  <R extends RaRecord>(imageSources: (keyof R)[]) =>
  async (record: R) => {
    let result = { ...record };

    for (const source of imageSources) {
      const data =
        record[source]?.rawFile &&
        (await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            resolve(reader.result);
          });
          reader.onerror = reject;

          reader.readAsBinaryString(record[source]?.rawFile);
        }));

      result = {
        ...result,
        [source]: data,
      };
    }

    return result;
  };
