import ListingIcon from "@mui/icons-material/ListAlt";
import { Badge, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { Project } from "../type";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const ListingLink = ({ label }: DatagridOrFilterRequiredProps) => {
  const record = useRecordContext<Project>();

  if (!record) {
    return null;
  }

  return (
    <Tooltip
      title={
        record.lastListingImport &&
        `Last import ${dayjs
          .duration(dayjs(record.lastListingImport.date).diff(dayjs()))
          .humanize(true)}`
      }
    >
      <IconButton
        href={`https://docs.google.com/spreadsheets/d/${record?.listingSpreadsheetId}`}
        target="_blank"
        rel="noreferrer"
        disabled={!record?.listingSpreadsheetId}
        aria-label={label}
      >
        <Badge
          badgeContent={record.lastListingImport?.errorMessages.length || null}
          color="error"
        >
          <ListingIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
