import { CircularProgress, Stack, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useDemandResponseEntityDetails } from "../../../../providers/data-hooks/useDemandResponseEntityDetails";
import type { DemandResponseEntity } from "../../type";

export const DemandResponseEntityInconsistentPDLsTab = () => {
  const record = useRecordContext<DemandResponseEntity>();
  const { data, isLoading } = useDemandResponseEntityDetails(record?.id);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack>
      {data?.pdlsWithNoHousing.map((pdl) => (
        <Typography variant="body1">{pdl}</Typography>
      ))}
    </Stack>
  );
};
