import { useFormContext, useWatch } from "react-hook-form";
import { EditableListField } from "../../../../fields/EditableListField";
import { IncidentActor } from "../../types";

export function ActionInput() {
  const assignedTo = useWatch({ name: "assignedTo" });
  const currentValue = useWatch({ name: "action" });
  const { setValue } = useFormContext();

  if (!assignedTo) {
    return null;
  }

  const label =
    assignedTo === IncidentActor.MAINTENANCE_PROVIDER
      ? "Mission order"
      : "Action";

  return (
    <EditableListField
      label={label}
      value={currentValue ?? ""}
      onChange={(value, description) => {
        setValue("action", value);
        if (description) {
          setValue("comment", description);
        }
      }}
      itemDescription={{ enabled: true, label: `${label} description` }}
      itemName={{
        singular: label,
        plural: `${label}s`,
        article: label === "Action" ? "an" : "a",
      }}
      listId={`Incident actions for ${assignedTo}`}
      sx={{ marginBottom: 1 }}
    />
  );
}
