import type { ReactNode } from "react";

export function SavingsV2Tag(): ReactNode {
  return (
    <div
      style={{
        backgroundColor: "rgba(48, 145, 236, 0.9)",
        color: "#fff",
        borderRadius: 5,
        padding: `1px 3px`,
        fontWeight: "bold",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        fontSize: "10px",
        verticalAlign: "middle",
      }}
    >
      savings v2
    </div>
  );
}
