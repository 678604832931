import FlexIcon from "@mui/icons-material/Battery5Bar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import SecurityIcon from "@mui/icons-material/Security";
import { Box, Collapse, Divider, IconButton } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { Menu, Layout as RaLaout, useStore } from "react-admin";
import { ActivityLogIcon } from "../resources/activity-logs";
import { AlertIcon } from "../resources/alerts";
import { CommunicationIcon } from "../resources/communications";
import { DealIcon } from "../resources/deal";
import { DemandResponseEntityIcon } from "../resources/demand-response-entities";
import { DeviceConfigPersonaIcon } from "../resources/device-config-persona";
import { DeviceIcon } from "../resources/devices";
import { ElectricityPriceIcon } from "../resources/electricity-prices";
import { EndUserIcon } from "../resources/end-users";
import { FeedbackIcon } from "../resources/feedback";
import { FirmwareIcon } from "../resources/firmware";
import { GroupIcon } from "../resources/group";
import { HousingIcon } from "../resources/housing";
import { IncidentIcon } from "../resources/incidents";
import { InterventionIcon } from "../resources/intervention";
import { MaintenanceProviderIcon } from "../resources/maintenance-provider";
import { ClientIcon } from "../resources/organization";
import { PostIcon } from "../resources/posts";
import { ProjectIcon } from "../resources/project";
import { ResidenceIcon } from "../resources/residence";
import { UserIcon } from "../resources/users";
import { Appbar } from "./AppBar";

export const Layout = (props: any) => (
  <RaLaout {...props} appBar={Appbar} menu={MyMenu} />
);

const MyMenu = () => {
  return (
    <Menu>
      <Menu.DashboardItem />
      <SubMenu
        primaryText="Devices"
        leftIcon={<FolderIcon color="action" />}
        first
        initiallyOpened
      >
        <Menu.Item
          to="/devices"
          primaryText="Devices"
          leftIcon={<DeviceIcon />}
        />
        <IfCanAccess resource="incidents" action="read">
          <Menu.Item
            to="/incidents"
            primaryText="Incidents"
            leftIcon={<IncidentIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="alerts" action="read">
          <Menu.Item
            to="/alerts"
            primaryText="Alerts [LEGACY]"
            leftIcon={<AlertIcon />}
          />
        </IfCanAccess>
      </SubMenu>
      <SubMenu
        primaryText="Clients & Projects"
        leftIcon={<FolderIcon color="action" />}
        initiallyOpened
      >
        <IfCanAccess resource="organizations" action="read">
          <Menu.Item
            to="/organizations"
            primaryText="Clients"
            leftIcon={<ClientIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="deals" action="read">
          <Menu.Item to="/deals" primaryText="Deals" leftIcon={<DealIcon />} />
        </IfCanAccess>
        <IfCanAccess resource="projects" action="read">
          <Menu.Item
            to="/projects"
            primaryText="Projects"
            leftIcon={<ProjectIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="interventions" action="read">
          <Menu.Item
            to="/interventions"
            primaryText="Interventions"
            leftIcon={<InterventionIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="residences" action="read">
          <Menu.Item
            to="/residences"
            primaryText="Residences"
            leftIcon={<ResidenceIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="housings" action="read">
          <Menu.Item
            to="/housings"
            primaryText="Housings"
            leftIcon={<HousingIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="end-users" action="read">
          <Menu.Item
            to="/end-users"
            primaryText="End Users"
            leftIcon={<EndUserIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="feedbacks" action="read">
          <Menu.Item
            to="/feedbacks"
            primaryText="Feedbacks"
            leftIcon={<FeedbackIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="communications" action="read">
          <Menu.Item
            to="/communications"
            primaryText="Communications"
            leftIcon={<CommunicationIcon />}
          />
        </IfCanAccess>
      </SubMenu>
      <SubMenu
        primaryText="Administration"
        leftIcon={<SecurityIcon color="action" />}
      >
        <IfCanAccess resource="users" action="read">
          <Menu.Item to="/users" primaryText="Users" leftIcon={<UserIcon />} />
        </IfCanAccess>
        <IfCanAccess resource="device-config-personas" action="read">
          <Menu.Item
            to="/device-config-personas"
            primaryText="Personas"
            leftIcon={<DeviceConfigPersonaIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="activity-logs" action="read">
          <Menu.Item
            to="/activity-logs"
            primaryText="Activity Logs"
            leftIcon={<ActivityLogIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="firmwares" action="read">
          <Menu.Item
            to="/firmwares"
            primaryText="Firmwares"
            leftIcon={<FirmwareIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="posts" action="read">
          <Menu.Item to="/posts" primaryText="Posts" leftIcon={<PostIcon />} />
        </IfCanAccess>
        <IfCanAccess resource="maintenance-providers" action="read">
          <Menu.Item
            to="/maintenance-providers"
            primaryText="Maintenance Providers"
            leftIcon={<MaintenanceProviderIcon />}
          />
        </IfCanAccess>
      </SubMenu>
      <SubMenu primaryText="Flex" leftIcon={<FlexIcon color="action" />}>
        <IfCanAccess resource="demand-response-entities" action="read">
          <Menu.Item
            to="/demand-response-entities"
            primaryText="EDE"
            leftIcon={<DemandResponseEntityIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="electricity-prices" action="read">
          <Menu.Item
            to="/electricity-prices"
            primaryText="Electricity Prices"
            leftIcon={<ElectricityPriceIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource="groups" action="read">
          <Menu.Item
            to="/groups"
            primaryText="Groups"
            leftIcon={<GroupIcon />}
          />
        </IfCanAccess>
      </SubMenu>
    </Menu>
  );
};

type HeaderProps = {
  leftIcon: any;
  primaryText: string;
  first?: boolean;
  children: any;
  initiallyOpened?: boolean;
};

const SubMenu = ({
  leftIcon,
  primaryText,
  first,
  children,
  initiallyOpened,
}: HeaderProps) => {
  const [isOpen, setIsOpen] = useStore(
    `menu.${primaryText}.open`,
    initiallyOpened ?? false
  );

  return (
    <>
      {!first && <Divider />}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Menu.Item
          to=""
          disabled
          primaryText={primaryText}
          leftIcon={leftIcon}
        ></Menu.Item>
        <IconButton
          onClick={() => setIsOpen((v) => !v)}
          edge="end"
          size="small"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};
