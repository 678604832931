import Icon from "@mui/icons-material/LocalShipping";
import { Divider } from "@mui/material";
import { useRecordContext } from "react-admin";
import {
  ActionsMenu,
  ConfirmActionMenuItem,
  EditAction,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import type { Housing } from "../../types";
import { InstallAction } from "./Install";

export const HousingActions = () => {
  const record = useRecordContext<Housing>();

  if (!record) {
    return null;
  }

  return (
    <ActionsMenu>
      <EditAction />
      <Divider />
      <InstallAction />
      <ConfirmActionMenuItem
        label="Declare moving"
        onClick={() => dataProvider.declareMoving(record.id)}
        icon={<Icon />}
      />
    </ActionsMenu>
  );
};
