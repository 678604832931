import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  FormTab,
  ReferenceInput,
  ReferenceManyField,
  ReferenceOneField,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { ContractTypeField } from "../devices/fields/ContractTypeField";

const EditionForm = () => {
  return (
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput source="groupName" required={true} fullWidth />
        <TextInput source="groupDescription" multiline minRows={3} fullWidth />
        <ReferenceInput source="groupOwner" reference="users">
          <AutocompleteInput fullWidth />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Devices">
        <ReferenceManyField
          reference="devices"
          target="group"
          label={false}
          perPage={1000}
          fullWidth
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="IMEI" label="IMEI" />
            <ContractTypeField source="contractType" label="Contract Type" />
            <ReferenceOneField
              reference="device-configs"
              target="deviceId"
              sort={{ field: "createdAt", order: "DESC" }}
              label="Pilot mode"
            >
              <TextField source="mode" />
            </ReferenceOneField>
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  );
};

export const GroupEdit = () => (
  <Edit>
    <EditionForm />
  </Edit>
);

export const GroupCreate = () => (
  <Create>
    <EditionForm />
  </Create>
);
