import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SyncIcon from "@mui/icons-material/Sync";
import { Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import { dataProvider } from "../../../providers/data";
import type { Intervention } from "../types";

export const KizeoField = ({ label }: DatagridOrFilterRequiredProps) => {
  const record = useRecordContext<Intervention>();
  const refresh = useRefresh();
  const notify = useNotify();
  const { mutate: sync, isPending } = useMutation({
    mutationKey: ["kizeo-sync", record?.id],
    mutationFn: () => dataProvider.syncKizeo(record?.id ?? ""),
    onSuccess: (result) => {
      notify(result?.planned ? "Sync planned" : "Sync successful", {
        type: "success",
      });
      refresh();
    },
    onError: (error: any) => {
      notify(`Sync failed: ${error.message}`, {
        type: "error",
      });
    },
  });

  if (!record?.externalId) {
    return null;
  }

  const [, formId, dataId] = record.externalId.split("/");

  return (
    <Stack direction="row" spacing={1} title={label}>
      <Button
        href={`https://forms.kizeo.com/webapp/webapp/index.php?lang=fr&dataId=${dataId}&formId=${formId}#resume`}
        label="OPEN"
        startIcon={<OpenInNewIcon />}
        // target="_blank"
      />
      <Button
        label="Sync"
        onClick={() => sync()}
        disabled={isPending}
        startIcon={<SyncIcon />}
      />
    </Stack>
  );
};
