import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  Create,
  Edit,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import { SameDayAlert } from "./SameDayAlert";
import { ScheduleForm } from "./inputs/Schedule";
import { EndTimeInput, StartTimeInput } from "./inputs/Time";

export const DemandResponseEditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <ReferenceInput
            reference="demand-response-entities"
            source="entity"
            fullWidth
          >
            <AutocompleteInput label="Entity" optionText="name" fullWidth />
          </ReferenceInput>
        </Grid>
        <ScheduleForm />
        <Grid item sm={6}>
          <StartTimeInput />
        </Grid>
        <Grid item sm={6}>
          <EndTimeInput />
        </Grid>
        <SameDayAlert />
      </Grid>
    </SimpleForm>
  );
};

export const DemandResponseEdit = () => (
  <Edit mutationMode="pessimistic">
    <DemandResponseEditionForm />
  </Edit>
);

export const DemandResponseCreate = () => (
  <Create>
    <DemandResponseEditionForm />
  </Create>
);
