import { ReferenceOneField } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import { AlertCodeField } from "./AlertCodeField";

export const LatestCriticalAlert = ({
  label,
}: DatagridOrFilterRequiredProps) => {
  return (
    <ReferenceOneField
      reference="alerts"
      target="deviceId"
      filter={{
        critical: true,
        timeEnd: { $exists: false },
      }}
      sort={{ field: "createdAt", order: "DESC" }}
      label={label}
    >
      <AlertCodeField />
    </ReferenceOneField>
  );
};
