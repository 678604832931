import { Autocomplete, TextField } from "@mui/material";
import { useGetList } from "react-admin";
import type { AutocompleteInputProps } from "react-admin";
import { useURLFilter } from "../../../filters/useURLFilter";

export const SelectOrganization = (props: AutocompleteInputProps) => {
  const { data } = useGetList("organizations", {
    pagination: { perPage: 1000, page: 1 },
  });
  const [value, setValue] = useURLFilter(props.source ?? "organizationId");

  const selectedOption = data?.find((o) => o.id === value);

  return (
    <Autocomplete
      value={selectedOption}
      options={data ?? []}
      getOptionLabel={(o) => o.name}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      loading={!data}
      multiple
      onChange={(event, value) =>
        value.length > 0
          ? setValue(value.map((v) => v.id))
          : setValue(undefined)
      }
    />
  );
};
