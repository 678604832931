/* eslint-disable @typescript-eslint/no-this-alias */
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import type {
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesScatterOptions,
} from "highcharts";
import AnnotationsModule from "highcharts/modules/annotations";
import HighchartsReact from "highcharts-react-official";
import { merge, zip } from "lodash";
import { useEffect, useRef } from "react";
import { useGetManyReference } from "react-admin";
import { dataProvider } from "../../../../../../providers/data";
import { serializeConfigFrame } from "../../../../../frames/config/type";
import { SeriesName } from "../../../../types";
import type { Device } from "../../../../types";
import { HistoryItemType } from "../../history";
import { useFrameData } from "../useFrameData";
import { NoData } from "./NoData";
import { getScheduleSeries } from "./schedule-series";
import { updateData } from "./updateData";
import { useDemandResponseOccurences } from "./useDemandResponseOccurences";

AnnotationsModule(Highcharts);

const TELEMETRY_GAP_SIZE = 3;

type TemperatureChartProps = {
  title?: string;
  device: Device;
  startDate: Date;
  endDate: Date;
  displayedSeries?: SeriesName[];
  paramsOptions: Highcharts.Options;
  cursor?: number;
  isFetching?: boolean;
  additionalSeries?: SeriesLineOptions[];
};

const MAX_POINTS = 2000;

const historyItemMarker: Record<string, string> = {
  [HistoryItemType.ALERT]: "error",
  [HistoryItemType.INTERVENTION]: "construction",
  [HistoryItemType.TICKET]: "call",
  [HistoryItemType.PRODUCTION]: "factory",
  [HistoryItemType.CONFIG]: "tune",
};

export const TemperatureChart = ({
  title = "Temperature evolution",
  device,
  startDate,
  endDate,
  displayedSeries,
  paramsOptions,
  cursor,
  additionalSeries,
  isFetching,
}: TemperatureChartProps) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const { dataFrames, configAckFrames, configFrames } = useFrameData({
    startDate,
    endDate,
  });
  const { data: configs } = useGetManyReference("device-configs", {
    target: "deviceId",
    id: device.id,
    pagination: { perPage: 1, page: 1 },
    sort: { field: "createdAt", order: "DESC" },
  });

  const { data: history } = useQuery({
    queryKey: ["devices", "history", { id: device.id }],
    queryFn: () => dataProvider.getDeviceHistory(device.id),
    enabled: Boolean(device),
  });

  const demandResponses = useDemandResponseOccurences(
    device.id,
    startDate,
    endDate
  );

  const config = configs?.[0];

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    chartRef.current.chart.setTitle({ text: title });

    if (!dataFrames || !configAckFrames || isFetching === true || !history) {
      chartRef.current.chart.showLoading();
      return;
    }
    chartRef.current.chart.hideLoading();

    const tooltip: Highcharts.SeriesTooltipOptionsObject = {
      pointFormatter() {
        const point = this;
        const visibleSeries = point.series.chart.series
          .filter((series) => series.visible)
          .filter(
            (series) =>
              ![SeriesName.SCHEDULE, SeriesName.CONFIG_ACK].includes(
                series.name as SeriesName
              )
          )
          .filter(
            (series) =>
              (series as any).processedYData[point.index] !== undefined
          );
        return visibleSeries
          .map(
            (series) =>
              `<b>${series.name}:</b> ${
                (series as any).processedYData[point.index]
              }`
          )
          .join("<br/>");
      },
    };

    const gaps: [number, number][] = [];
    for (let i = 1; i < dataFrames.length; i++) {
      const previousTimestamp = dataFrames[i - 1].timestamp;
      const currentTimestamp = dataFrames[i].timestamp;
      if (
        previousTimestamp <
        currentTimestamp - TELEMETRY_GAP_SIZE * 60 * 1000 * device.Telemetry
      ) {
        gaps.push([previousTimestamp, currentTimestamp]);
      }
    }

    const yMax =
      configAckFrames.length > 0
        ? Math.max(...configAckFrames.map((frame) => frame.set_point)) + 20
        : 90;

    const series = new Array<
      (SeriesLineOptions | SeriesScatterOptions | SeriesColumnOptions) & {
        display?: boolean;
      }
    >(
      {
        name: SeriesName.T1,
        data: getSerie(dataFrames, "last_temperature1", gaps),
        step: "left",
        type: "line",
        color: "#7cb5ec",
        tooltip,
      },
      {
        name: SeriesName.T1_AVG,
        data: getSerie(dataFrames, "temperature1", gaps),
        step: "left",
        type: "line",
        color: "#434348",
        tooltip,
      },
      {
        name: SeriesName.T2,
        data: getSerie(dataFrames, "last_temperature2", gaps),
        step: "left",
        type: "line",
        color: "#90ed7d",
        tooltip,
      },
      {
        name: SeriesName.T2_AVG,
        data: getSerie(dataFrames, "temperature2", gaps),
        step: "left",
        type: "line",
        color: "#f6a35b",
        tooltip,
      },
      {
        name: SeriesName.T_DIFF,
        data: getSerie(
          dataFrames,
          (frame) => Math.abs(frame.temperature1 - frame.temperature2),
          gaps
        ),
        step: "left",
        type: "line",
        color: "#f15c80",
        tooltip,
      },
      {
        name: SeriesName.SCHEDULE,
        data: getScheduleSeries(
          config?.weeklyInstructions || [],
          // increasing schedule start date boundaries is a quick fix
          // so that the curve matches previous instruction before
          // first point in boundaries
          dayjs(startDate).subtract(1, "week").toDate(),
          endDate
        ),
        step: "left",
        type: "line",
        color: "#f45b5c",
        tooltip: {
          pointFormatter() {
            const point = this;
            return `${(point as any).custom.setPoint}±${(
              (point as any).custom.hysteresis / 10
            ).toFixed(1)}°C (${(point as any).custom.origin})`;
          },
        },
      },
      {
        name: SeriesName.SCHEDULE_2,
        display: device.majorHWVersion === 3,
        data: getScheduleSeries(
          (config?.weeklyInstructions || []).map((instruction: any) => ({
            setPoint: instruction.setPoint2 ?? instruction.setPoint,
            hysteresis: instruction.hysteresis2 ?? instruction.hysteresis,
            time: instruction.time,
          })),
          // increasing schedule start date boundaries is a quick fix
          // so that the curve matches previous instruction before
          // first point in boundaries
          dayjs(startDate).subtract(1, "week").toDate(),
          endDate
        ),
        step: "left",
        type: "line",
        color: "#9c27b0",
        tooltip: {
          pointFormatter() {
            const point: any = this;
            return `${point.custom.setPoint2}±${(
              point.custom.hysteresis2 / 10
            ).toFixed(1)}°C (${point.custom.origin})`;
          },
        },
      },
      {
        name: SeriesName.CONFIG_ACK,
        type: "line",
        data: configAckFrames.map((frame) => {
          return {
            x: frame.timestamp,
            y: frame.set_point,
            custom: {
              label:
                frame.set_point === 90
                  ? `Reboot`
                  : `${frame.set_point} ±${(frame.hysteresis / 10).toFixed(
                      1
                    )}°C`,
            },
          };
        }),
        tooltip: {
          headerFormat: "{point.key}",
          xDateFormat: "%d-%m %H:%M:%S",
          pointFormat: "<br /><b>{point.custom.label}</b>",
        },
        step: "left",
        color: "#e4d354",
        dataLabels: {
          enabled: true,
          format: "{point.custom.label}",
        },
      },
      {
        name: SeriesName.CONFIG_ACK_2,
        display: device.majorHWVersion === 3,
        type: "line",
        data: configAckFrames.map((frame) => {
          return {
            x: frame.timestamp,
            y: frame.set_point2 ?? frame.set_point,
            custom: {
              label:
                frame.set_point === 90
                  ? `Reboot`
                  : `${frame.set_point2 ?? frame.set_point} ±${(
                      (frame.hysteresis2 ?? frame.hysteresis) / 10
                    ).toFixed(1)}°C`,
            },
          };
        }),
        tooltip: {
          headerFormat: "{point.key}",
          xDateFormat: "%d-%m %H:%M:%S",
          pointFormat: "<br /><b>{point.custom.label}</b>",
        },
        step: "left",
        color: "#FFC107",
        dataLabels: {
          enabled: true,
          format: "{point.custom.label}",
        },
      },
      {
        name: SeriesName.CONFIG,
        type: "line",
        data: (configFrames ?? []).map((frame) => {
          return {
            x: new Date(frame.ts).getTime(),
            y: frame.set_point,
            custom: {
              label: serializeConfigFrame(frame),
            },
          };
        }),
        tooltip: {
          headerFormat: "{point.key}",
          xDateFormat: "%d-%m %H:%M:%S",
          pointFormat: "<br /><b>{point.custom.label}</b>",
        },
        step: "left",
        color: "#4caf50",
      },
      {
        name: SeriesName.CONFIG_2,
        display: device.majorHWVersion === 3,
        type: "line",
        data: (configFrames ?? []).map((frame) => {
          return {
            x: new Date(frame.ts).getTime(),
            y: frame.set_point2 ?? frame.set_point,
            custom: {
              label: serializeConfigFrame(frame),
            },
          };
        }),
        tooltip: {
          headerFormat: "{point.key}",
          xDateFormat: "%d-%m %H:%M:%S",
          pointFormat: "<br /><b>{point.custom.label}</b>",
        },
        step: "left",
        color: "#8bc34a",
      },
      {
        name: SeriesName.ENERGY_CUMULATIVE,
        type: "line",
        color: "#91e8e0",
        data: getSerie(dataFrames, "energy", gaps),
        yAxis: 1,
        step: "left",
        display: device.majorHWVersion !== 1,
        tooltip,
      },
      {
        name: SeriesName.ENERGY,
        type: "column",
        color: "#673ab7",
        data: getSerie(
          zip(dataFrames.slice(0, -1), dataFrames.slice(1)).map(([a, b]) => ({
            timestamp: a!.timestamp,
            value: Math.max(0, b!.energy! - a!.energy!),
          })),
          "value",
          gaps
        ),
        yAxis: 1,
        display: device.majorHWVersion !== 1,
        tooltip,
      },
      {
        name: SeriesName.ON_TIMER,
        type: "line",
        color: "#2b908f",
        data: getSerie(dataFrames, "on_timer", gaps),
        yAxis: 1,
        step: "left",
        display: true,
        tooltip,
      },
      {
        name: SeriesName.HISTORY,
        type: "scatter",
        data: history.map((item) => ({
          x: Date.parse(item.date),
          y: yMax,
          tooltip: `<b>${item.type}</b><br/>${item.title || ""}<br/>${
            item.content || ""
          }`,
          marker: {
            symbol: `url(markers/${historyItemMarker[item.type]}.svg)`,
            width: 20,
            height: 20,
          },
          label: item.type,
        })),
        xAxis: 0,
        yAxis: 0,
        display: true,
        tooltip: {
          pointFormat: "{point.tooltip}",
        },
        dataLabels: {
          enabled: true,
          format: "{point.label}",
          y: -5,
        },
      }
    );

    chartRef.current.chart.xAxis[0].removePlotLine("cursor");
    if (cursor) {
      chartRef.current.chart.xAxis[0].addPlotLine({
        color: "#f44336",
        width: 2,
        value: cursor,
        id: "cursor",
      });
    }

    if (demandResponses) {
      for (const demandResponse of demandResponses) {
        chartRef.current.chart.xAxis[0].addPlotBand({
          color: "#E7EBF0",
          from: new Date(demandResponse.startDate).getTime(),
          to: new Date(demandResponse.endDate).getTime(),
          id: `demandResponse-${new Date(demandResponse.startDate).getTime()}`,
        });
      }
    }

    updateData(
      [
        ...series,
        ...(additionalSeries || []).map((s) => ({ ...s, display: true })),
      ]
        .filter(({ display }) => display !== false)
        .map((series) => ({
          ...series,
          visible:
            !displayedSeries ||
            displayedSeries.includes(series.name as SeriesName),
        })),
      chartRef.current.chart,
      { start: startDate, end: endDate }
    );

    if (additionalSeries?.length) {
      for (const serie of chartRef.current.chart.series) {
        if (!additionalSeries.find((s) => s.name === serie.name)) {
          serie.setState("inactive");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
  }, [
    dataFrames,
    configAckFrames,
    device,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
    JSON.stringify(displayedSeries),
    startDate,
    endDate,
    cursor,
    isFetching,
    additionalSeries,
    title,
    config,
    configFrames,
    demandResponses,
    history,
  ]);

  if (
    dataFrames?.length === 0 &&
    configAckFrames?.length === 0 &&
    configFrames?.length === 0 &&
    additionalSeries?.length === 0
  ) {
    return <NoData />;
  }

  if (dataFrames && dataFrames.length > MAX_POINTS - 1) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
        }}
      >
        Time range is too wide
      </div>
    );
  }

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={merge(chartOptions, paramsOptions)}
    />
  );
};

const getSerie = <Data extends { timestamp: number }>(
  data: Data[] | undefined,
  field: keyof Data | ((datum: Data) => number),
  gaps: [number, number][]
) => {
  if (!data) {
    return [];
  }

  const serie: [number, number | null][] = [];
  for (const datum of data) {
    const timestamp = datum.timestamp;
    const value = typeof field === "function" ? field(datum) : datum[field];

    const gap = gaps.find(
      (gap) => gap[0] === timestamp || gap[1] === timestamp
    );
    if (gap) {
      serie.push([timestamp, null]);
    }

    serie.push([timestamp, typeof value === "number" ? value : null]);
  }

  return serie;
};

const chartOptions: Highcharts.Options = {
  tooltip: {
    xDateFormat: "%d-%m %H:%M",
  },
  chart: {
    zooming: {
      type: "x",
    },
    animation: false,
  },
  xAxis: {
    type: "datetime",
    tickPixelInterval: 150,
  },
  yAxis: [
    {
      minPadding: 0.2,
      maxPadding: 0.2,
      opposite: false,
      title: {
        text: "Temperature",
        margin: 10,
      },
    },
    {
      minPadding: 0.2,
      maxPadding: 0.2,
      title: {
        text: "On Timer",
        margin: 10,
      },
      opposite: true,
    },
  ],
  time: {
    useUTC: false,
  },
  credits: {
    enabled: false,
  },
};
