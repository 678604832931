import { jwtDecode } from "jwt-decode";
import type { AuthProvider } from "react-admin";
import { API_BASE_URL } from "../config";
import { axiosClient } from "./data";
import { init } from "./store";

const LOCAL_STORAGE_KEY = "ELAX_TOKEN";

export const authProvider: AuthProvider = {
  // send username and password to the auth server and get back credentials
  login: async (params) => {
    const response = await axiosClient.post(
      `${API_BASE_URL.replace("/admin", "")}/user/signin`,
      { email: params.username, password: params.password }
    );
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.message);
    }
    init(response.data.backOfficeConfig);
    localStorage.setItem(LOCAL_STORAGE_KEY, response.data.token);
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: (error) => {
    if (
      error.response?.status === 401 &&
      error.response.data?.message?.includes("Invalid token")
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = getUnverifiedTokenPayload();

    if (!token || token.exp < Date.now() / 1000) {
      return Promise.reject({ redirectTo: "/login" });
    }

    return Promise.resolve();
  },
  // remove local credentials and notify the auth server that the user logged out
  logout: async () => {
    try {
      await axiosClient.post(
        `${API_BASE_URL.replace("/admin", "")}/user/logout`
      );
    } catch {
      // do nothing
    }
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  },
  getPermissions: () => {
    const payload = getUnverifiedTokenPayload();
    return Promise.resolve(roles[payload.role] || []);
  },
  getIdentity: async () => {
    const payload = getUnverifiedTokenPayload();

    return axiosClient
      .get(`/users/${payload._id}`)
      .then((response) => response.data);
  },
};

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY);
export const setToken = (token: string) =>
  localStorage.setItem(LOCAL_STORAGE_KEY, token);

enum Role {
  SANDBOX = "Sandbox",
  ADMIN = "Admin",
  ELAX = "Elax",
  ANONYMOUS = "Anonymous",
}

export const getUnverifiedTokenPayload = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!token) {
    return { _id: "", role: Role.ANONYMOUS, exp: 0 };
  }
  return jwtDecode<{ _id: string; role: Role; exp: number }>(token);
};

export const roles = {
  [Role.SANDBOX]: [
    { action: "*", resource: "groups" },
    { action: "*", resource: "devices" },
    { action: "*", resource: "devices.*" },
    { action: "*", resource: "devices.tab.*" },
    { action: "*", resource: "devices.tab.status", type: "deny" },
    { action: "*", resource: "devices.tab.savings", type: "deny" },
    { action: "read", resource: "devices.reports", type: "deny" },
    { action: "read", resource: "devices.filters", type: "deny" },
    { action: "edit", resource: "devices.status", type: "deny" },
    {
      action: "edit",
      resource: "devices.dataFrameFrequencyAlertThresholdInMinutes",
      type: "deny",
    },
    { action: "edit", resource: "device-configs.complex-modes", type: "deny" },
    { action: "uninstall", resource: "devices", type: "deny" },
    { action: "use", resource: "groups.actions", type: "deny" },
    { action: "read", resource: "dashboard", type: "deny" },
  ],
  [Role.ADMIN]: [{ action: "*", resource: "*" }],
  [Role.ELAX]: [{ action: "*", resource: "*" }],
  [Role.ANONYMOUS]: [],
};
