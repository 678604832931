import { ReferenceField, TextField } from "react-admin";
import { CopyRecordFieldValueToClipboard } from "../../../../../actions/CopyRecordFieldValueToClipboard";
import HeaterPositionField, {
  getHeaterPosition,
} from "../../../../heater/fields/HeaterPositionField";
import HeaterPowerField, {
  getHeaterPower,
} from "../../../../heater/fields/HeaterPowerField";
import HeaterVolumeField, {
  getHeaterVolume,
} from "../../../../heater/fields/HeaterVolumeField";
import HeatingStateField, {
  heatingStateLabels,
} from "../../../../heater/fields/HeatingStateField";
import type { Heater } from "../../../../heater/type";
import InfoList from "../InfoList";

const heaterToString = (heater: Heater) => {
  return [
    `Heating Quality: ${heater.state && heatingStateLabels[heater.state]}`,
    `Modèle: ${heater.model || "Unknown"}`,
    `Position: ${getHeaterPosition(heater)}`,
    `Power: ${getHeaterPower(heater)}`,
    `Volume: ${getHeaterVolume(heater)}`,
  ]
    .filter(Boolean)
    .join("\n");
};

export default function HeaterInfoList() {
  return (
    <ReferenceField reference="heaters" source="heater" link={false}>
      <InfoList
        label="Heater"
        secondaryAction={
          <CopyRecordFieldValueToClipboard<Heater>
            formatValue={heaterToString}
          />
        }
      >
        <InfoList.Item label="Heating quality">
          <HeatingStateField />
        </InfoList.Item>

        <InfoList.Collapsible>
          <InfoList.Item label="Modèle">
            <TextField source="model" emptyText="Unknown" variant="body1" />
          </InfoList.Item>

          <InfoList.Item label="Position">
            <HeaterPositionField />
          </InfoList.Item>

          <InfoList.Item label="Power">
            <HeaterPowerField />
          </InfoList.Item>

          <InfoList.Item label="Volume">
            <HeaterVolumeField />
          </InfoList.Item>
        </InfoList.Collapsible>
      </InfoList>
    </ReferenceField>
  );
}
