import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { CreateAction } from "../../../layout/actions-menu/CreateAction";
import { codeToLabel } from "../../alerts/fields/AlertCodeField";
import { ActionInput } from "../edit/inputs/ActionInput";
import { InterventionAccountingInput } from "../edit/inputs/InterventionAccountingInput";
import { MaintenanceProviderInput } from "../edit/inputs/MaintenanceProviderInput";
import { PriorityInput } from "../edit/inputs/PriorityInput";
import { IncidentStatus, incidentActorLabels } from "../types";

export const CreateIncident = () => {
  return (
    <CreateAction record={{ disabledAutoUpdate: true }}>
      <SimpleForm minWidth={400}>
        <ReferenceInput
          reference="devices"
          source="deviceId"
          isRequired
          fullWidth
        >
          <AutocompleteInput
            source="name"
            optionText="name"
            fullWidth
            size="small"
          />
        </ReferenceInput>
        <SelectInput
          source="type"
          validate={required()}
          choices={Object.entries(codeToLabel)
            .filter(([, { usedInIncidents }]) => usedInIncidents)
            .map(([key, { label }]) => ({ id: key, name: label }))}
          fullWidth
        />
        <SelectInput
          source="status"
          validate={required()}
          defaultValue={IncidentStatus.OPENED}
          fullWidth
          choices={Object.values(IncidentStatus).map((status) => ({
            id: status,
            name: status,
          }))}
        />
        <SelectInput
          source="assignedTo"
          validate={required()}
          choices={Object.entries(incidentActorLabels).map(
            ([key, { label }]) => ({ id: key, name: label })
          )}
          fullWidth
        />
        <MaintenanceProviderInput source="maintenanceProviderId" />
        <InterventionAccountingInput source="interventionAccounting" />
        <PriorityInput source="priority" />
        <ActionInput />
        <TextInput
          source="comment"
          label="Comment"
          helperText="Can be shared with maintenance provider"
          multiline
          minRows={4}
          fullWidth
        />
      </SimpleForm>
    </CreateAction>
  );
};
