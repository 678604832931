import { Divider, Grid, Paper } from "@mui/material";
import { useRecordContext } from "react-admin";
import { YearlyNormalizationSelect } from "../../../layout/dashboard/YearlyNormalizationSelect";
import { ConsumptionGraph } from "../../../layout/dashboard/widgets/Consumption";
import { ContractType } from "../../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../../layout/dashboard/widgets/HeaterState";
import { SavingsAverageGraph } from "../../../layout/dashboard/widgets/SavingsAverage";
import { SavingsState } from "../../../layout/dashboard/widgets/SavingsState";
import { V1Alert } from "../../devices/reporting/V1Alert";
import type { Deal } from "../type";

export const DealOverviewTab = () => {
  const deal = useRecordContext<Deal>();

  if (!deal) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YearlyNormalizationSelect />
        </Grid>
        <Grid item xs={12}>
          {deal && <V1Alert filter={{ dealId: deal.id }} />}
        </Grid>
        <ConsumptionGraph filter={deal && { dealId: deal.id }} />
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={1}>
            <SavingsAverageGraph filter={deal && { dealId: deal.id }} />
          </Paper>
        </Grid>

        <HealthRisk filter={deal && { dealId: deal.id }} />
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={6}>
          <SavingsState filter={deal && { dealId: deal.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContractType filter={deal && { dealId: deal.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HeatersState filter={deal && { dealId: deal.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DevicesState filter={deal && { dealId: deal.id }} />
        </Grid>
      </Grid>
    </>
  );
};
