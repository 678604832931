import type { MutationFunction } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";
import { useNotify, useRefresh } from "react-admin";

export const useNotifyAction = <TData = unknown, TVariables = void>(
  action: MutationFunction<TData, TVariables>,
  messages?: { successMessage?: string; errorPrefixMessage?: string }
) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const key = useRef(Math.random());

  return useMutation<TData, unknown, TVariables>({
    mutationKey: [key],
    mutationFn: action,
    onSuccess: () => {
      notify(messages?.successMessage || "Success");
      refresh();
    },
    onError: (error: any) => {
      notify(`${messages?.errorPrefixMessage || "Failed"}: ${error.message}`, {
        type: "error",
      });
    },
  });
};
