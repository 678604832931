import { Alert, Divider, Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import { YearlyNormalizationSelect } from "../../../../layout/dashboard/YearlyNormalizationSelect";
import { ConsumptionGraph } from "../../../../layout/dashboard/widgets/Consumption";
import { ContractType } from "../../../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../../../layout/dashboard/widgets/HeaterState";
import { SavingsAverageGraph } from "../../../../layout/dashboard/widgets/SavingsAverage";
import { SavingsState } from "../../../../layout/dashboard/widgets/SavingsState";
import type { Organization } from "../../types";

export const ClientDashboard = () => {
  const client = useRecordContext<Organization>();

  if (!client) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info" sx={{ margin: 1 }}>
          Retrouvez le nouveau dashboard pour ce client sur metabase:{" "}
          <a
            href={`https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc?tab=64-estimations-annuelles-%C3%A9conomie-par-bailleur&bailleurs=${encodeURIComponent(
              client.name
            )}`}
          >
            Voir le dashboard metabase
          </a>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <YearlyNormalizationSelect />
      </Grid>
      <ConsumptionGraph filter={client && { organizationId: client.id }} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SavingsAverageGraph filter={client && { organizationId: client.id }} />
      </Grid>
      <HealthRisk filter={client && { organizationId: client.id }} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <ContractType filter={client && { organizationId: client.id }} />
      </Grid>
      <Grid item xs={6}>
        <SavingsState filter={client && { organizationId: client.id }} />
      </Grid>
      <Grid item xs={6}>
        <HeatersState filter={client && { organizationId: client.id }} />
      </Grid>
      <Grid item xs={6}>
        <DevicesState filter={client && { organizationId: client.id }} />
      </Grid>
    </Grid>
  );
};
