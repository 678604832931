import { Box, Grid, Typography } from "@mui/material";
import {
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { DemandResponseEntityIcon } from "..";
import { useDemandResponseEntityDetails } from "../../../providers/data-hooks/useDemandResponseEntityDetails";
import { DemandResponseEntityActionsMenu } from "../actions";
import type { DemandResponseEntity } from "../type";
import { DemandResponseEntityHousingsTab } from "./tabs/DemandResponseEntityHousingsTab";
import { DemandResponseEntityInconsistentPDLsTab } from "./tabs/DemandResponseEntityInconsistentPDLsTab";
import { DemandResponseEntityPoolsTab } from "./tabs/DemandResponseEntityPoolsTab";
import { DemandResponseEntitySummaryTab } from "./tabs/DemandResponseEntitySummaryTab";

export const DemandResponseEntityShow = () => {
  return (
    <Show actions={<DemandResponseEntityActions />}>
      <DemandResponseEntityShowContent />
    </Show>
  );
};

const DemandResponseEntityShowContent = () => {
  const record = useRecordContext<DemandResponseEntity>();

  const { data, isLoading } = useDemandResponseEntityDetails(record?.id);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center">
            <DemandResponseEntityIcon
              fontSize="large"
              sx={{ marginRight: 1 }}
            />
            <TextField source="name" variant="h4" sx={{ marginRight: 1 }} />
          </Box>
          <Box display="flex" alignItems="center">
            <FunctionField
              render={(record: any) =>
                record && (
                  <Typography variant="h6">
                    {record.type} - {record.contractType}
                  </Typography>
                )
              }
            />
          </Box>
          <Box display="flex" alignItems="center">
            <FunctionField
              render={(record: DemandResponseEntity) =>
                record && (
                  <Typography variant="h6">
                    {isLoading ? "..." : data?.housingCount} housings /{" "}
                    {record.pdls.length} PDLs
                  </Typography>
                )
              }
            />
          </Box>
        </Grid>
      </Grid>
      <TabbedShowLayout>
        <Tab label="Summary">
          <DemandResponseEntitySummaryTab />
        </Tab>
        <Tab label="Pools & Effacements" path="demand-response-pools">
          <DemandResponseEntityPoolsTab />
        </Tab>
        <Tab label="Housings sample" path="housings">
          <DemandResponseEntityHousingsTab />
        </Tab>
        <Tab label="PDLs with no housing" path="pdls-with-no-housings">
          <DemandResponseEntityInconsistentPDLsTab />
        </Tab>
      </TabbedShowLayout>
    </>
  );
};

export const DemandResponseEntityActions = () => (
  <TopToolbar>
    <DemandResponseEntityActionsMenu />
  </TopToolbar>
);
