import MoreIcon from "@mui/icons-material/MoreHoriz";
import {
  Dialog,
  Button as DialogButton,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import type { ButtonProps, DialogProps, SxProps } from "@mui/material";
import type { ComponentProps, FunctionComponent, ReactNode } from "react";
import { createStateContext } from "react-use";
import type { Except } from "type-fest";
import { useCloseMenu } from "./context";

function withDialogContext<C extends FunctionComponent<any>>(Component: C) {
  const WithDialogContext = function (props: ComponentProps<C>) {
    return (
      <ActionDialogStateProvider>
        <Component {...props} />
      </ActionDialogStateProvider>
    );
  };
  WithDialogContext.displayName = `withDialogContext(${Component.displayName})`;
  return WithDialogContext;
}

const [useActionDialogState, ActionDialogStateProvider] =
  createStateContext<boolean>(false);

type ActionMenuItemProps = {
  label: string;
  icon: ReactNode;
  dialog: ReactNode | ((closeDialog: () => void) => ReactNode);
  disabled?: boolean;
};

export const DialogActionMenuItem = withDialogContext(
  ({ label, icon, dialog, disabled }: ActionMenuItemProps) => {
    const [, setOpen] = useActionDialogState();
    const closeDialog = () => setOpen(false);

    return (
      <>
        <MenuItem onClick={() => setOpen(true)} disabled={disabled}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
          <MoreIcon color="action" sx={{ marginLeft: 3 }} />
        </MenuItem>
        {typeof dialog === "function" ? dialog(closeDialog) : dialog}
      </>
    );
  }
);

export const DialogActionIconButton = withDialogContext(
  ({
    dialog,
    ...props
  }: {
    dialog: ReactNode;
    sx: SxProps;
    children: ReactNode;
  }) => {
    const [, setOpen] = useActionDialogState();

    return (
      <>
        <IconButton {...props} onClick={() => setOpen(true)} />
        {dialog}
      </>
    );
  }
);

export const ActionDialog = (props: Except<DialogProps, "open">) => {
  const [open, setOpen] = useActionDialogState();
  const closeMenu = useCloseMenu();

  return (
    <Dialog
      {...props}
      open={open}
      onClose={(...args) => {
        setOpen(false);
        if (props.onClose) {
          props.onClose(...args);
        }
        closeMenu();
      }}
    ></Dialog>
  );
};

export const ActionDialogButton = ({
  onClick,
  ...props
}: Except<ButtonProps, "onClick"> & {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    close: () => void
  ) => void;
}) => {
  const [, setOpen] = useActionDialogState();
  const closeMenu = useCloseMenu();

  const close = () => {
    setOpen(false);
    closeMenu();
  };
  return (
    <DialogButton
      {...props}
      onClick={async (event) => {
        if (onClick) {
          onClick(event, close);
        } else {
          close();
        }
      }}
    />
  );
};
