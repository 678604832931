import { IfCanAccess } from "@react-admin/ra-rbac";
import {
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  WithRecord,
  useGetList,
} from "react-admin";
import { useWatch } from "react-hook-form";
import AutocompleteTagInput from "../device-tags/inputs/AutocompleteTagInput";
import { DeviceStatus } from "./types";
import type { Device } from "./types";

export const EditDevice = () => {
  const { data: firmwares } = useGetList("firmwares");

  return (
    <Edit redirect="show" mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="name" fullWidth />
        <TextInput source="notes" fullWidth />
        <ReferenceArrayInput
          reference="groups"
          source="group"
          perPage={1000}
          fullWidth
        >
          <SelectArrayInput optionText="groupName" fullWidth />
        </ReferenceArrayInput>
        <TextInput source="Telemetry" label="Message Frequency" fullWidth />
        <WithRecord<Device>
          render={(device) =>
            device.majorHWVersion === 1 ? (
              <></>
            ) : (
              <SelectInput
                source="targetFirmwareVersion"
                choices={
                  firmwares?.map((firmware) => ({
                    id: firmware.version,
                    name: firmware.version,
                  })) || []
                }
                fullWidth
              />
            )
          }
        />
        <IfCanAccess
          action="edit"
          resource="devices.dataFrameFrequencyAlertThresholdInMinutes"
        >
          <SelectInput
            source="dataFrameFrequencyAlertThresholdInMinutes"
            label="Message Frequency Alert Threshold"
            helperText="An alert will be raised if no message is received within this duration"
            fullWidth
            choices={[
              { name: "30 minutes (base contracts only)", id: 30 },
              { name: "1 hour (base contracts only)", id: 60 },
              { name: "2 hours (base contracts only)", id: 120 },
              { name: "6 hours (base contracts only)", id: 60 * 6 },
              { name: "12 hours (base contracts only)", id: 60 * 12 },
              { name: "1 day (default)", id: 1440 },
              { name: "2 days", id: 1440 * 2 },
              { name: "3 days", id: 1440 * 3 },
              { name: "4 days", id: 1440 * 4 },
              { name: "1 week", id: 1440 * 7 },
              { name: "2 weeks", id: 1440 * 14 },
              { name: "3 weeks", id: 1440 * 21 },
              { name: "1 month", id: 1440 * 30 },
              { name: "2 months", id: 1440 * 30 * 2 },
              { name: "6 months", id: 1440 * 30 * 6 },
            ]}
          />
        </IfCanAccess>
        <IfCanAccess action="edit" resource="devices.status">
          <SelectInput
            source="status"
            label="Status"
            fullWidth
            choices={Object.values(DeviceStatus).map((status) => ({
              id: status,
              name: status,
              disabled: [
                DeviceStatus.OUT_OF_ORDER,
                DeviceStatus.PRODUCTION,
                DeviceStatus.USED,
              ].includes(status),
            }))}
            helperText="To change status to USED: apply an installation intervention -- To change a status to OUT_OF_ORDER: apply a removal intervention"
          />
        </IfCanAccess>
        <AutoReboot />
        <IfCanAccess action="edit" resource="devices.ActuatorMode">
          <SelectInput
            source="ActuatorMode"
            label="Actuator Mode"
            fullWidth
            choices={[
              { id: 0, name: "Offload" },
              { id: 1, name: "Heater" },
            ]}
          />
        </IfCanAccess>
        <IfCanAccess action="edit" resource="devices.tags">
          <AutocompleteTagInput />
        </IfCanAccess>
      </SimpleForm>
    </Edit>
  );
};

const AutoReboot = () => {
  const status = useWatch({ name: "status" });

  if (status !== DeviceStatus.TEST) {
    return null;
  }

  return (
    <BooleanInput
      source="activateTestAutoReboot"
      label="Activate auto reboot on test devices"
    />
  );
};
