import EnergyIcon from "@mui/icons-material/Battery5Bar";
import PowerIcon from "@mui/icons-material/Bolt";
import HardwareIcon from "@mui/icons-material/Memory";
import ProductionFrameIcon from "@mui/icons-material/PrecisionManufacturing";
import TemperatureIcon from "@mui/icons-material/Thermostat";
import { Box } from "@mui/material";
import type { ProductionFrame } from "../../../../../frames/production/type";
import { IconInfo } from "./shared";
import type { FrameSpecs } from "./shared";

export const productionFrameSpecs: FrameSpecs<ProductionFrame> = {
  icon: <ProductionFrameIcon sx={{ color: "#607d8b" }} />,
  data: [
    {
      key: "Energy",
      label: (frame) => (
        <IconInfo icon={<EnergyIcon color="info" />}>
          {`${frame.energy}Wh`}
        </IconInfo>
      ),
    },
    {
      key: "Power",
      label: (frame) => (
        <IconInfo icon={<PowerIcon color="info" />}>
          {`${frame.power}W`}
        </IconInfo>
      ),
    },
    {
      key: "Temperature1" as const,
      label: (frame) => (
        <IconInfo
          first
          title={`T1 = ${frame.temperature1}°C`}
          icon={<TemperatureIcon color="error" />}
        >
          {`${frame.temperature1?.toFixed(1)}°C)`}
        </IconInfo>
      ),
    },
    {
      key: "Temperature2" as const,
      label: (frame) => (
        <IconInfo
          first
          title={`T1 = ${frame.temperature2}°C`}
          icon={<TemperatureIcon color="warning" />}
        >
          {`${frame.temperature2?.toFixed(1)}°C)`}
        </IconInfo>
      ),
    },
    {
      key: "hardwareVersion" as const,
      label: (frame) => (
        <IconInfo icon={<HardwareIcon color="info" />}>
          {frame.hardware_version}
        </IconInfo>
      ),
    },
    {
      key: "stpmCurrentParam" as const,
      label: (frame) => (
        <Box>{`STPM Current Param = ${frame.stpm_current_param}`}</Box>
      ),
    },
    {
      key: "stpmVoltParam" as const,
      label: (frame) => (
        <Box>{`STPM Volt Param = ${frame.stpm_volt_param}`}</Box>
      ),
    },
    {
      key: "Status",
      label: (frame) => <Box>{`Status = ${frame.status}`}</Box>,
    },
  ],
};
