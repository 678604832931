import { Grid } from "@mui/material";
import { Children } from "react";
import type { ReactNode } from "react";

export const FieldGrid = ({ children }: { children: ReactNode }) => {
  return (
    <Grid container spacing={1} columnSpacing={8} padding={1}>
      {Children.map(children, (child) => (
        <Grid item xs="auto">
          {child}
        </Grid>
      ))}
    </Grid>
  );
};
