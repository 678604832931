import SyncIcon from "@mui/icons-material/Sync";
import { useRecordContext } from "react-admin";
import { useNotifyAction } from "../../../actions/useNotifyAction";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const SynchroniseListing = () => {
  const record = useRecordContext();
  const mutation = useNotifyAction(
    () => dataProvider.importProject(record?.id as string),
    {
      successMessage: "Synchronization in progress (errors are sent on Slack)",
      errorPrefixMessage: "Failed to sync listing",
    }
  );

  return (
    <ActionMenuItem
      label="Synchronize Listing File"
      icon={<SyncIcon fontSize="small" />}
      onClick={() => mutation.mutate()}
      disabled={!record?.listingSpreadsheetId}
    />
  );
};
