import { Tab, Tabs, Typography } from "@mui/material";
import { IfCanAccess, useCanAccess } from "@react-admin/ra-rbac";
import { useEffect } from "react";
import { Title, useRedirect } from "react-admin";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import { DevicesDashboard } from "./Devices";
import { DashboardFilters } from "./Filters";
import { InterventionsDashboard } from "./Interventions";
import { SavingsDashboard } from "./Savings";
import { ToggleFiltersButton } from "./ToggleFiltersButton";
import { YearlyNormalizationSelect } from "./YearlyNormalizationSelect";

export const Dashboard = () => {
  const [filter] = useQueryParam("filter", JsonParam);
  const { pathname } = useLocation();
  const { canAccess, isLoading } = useCanAccess({
    action: "read",
    resource: "dashboard",
  });
  const redirect = useRedirect();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (canAccess === false) {
      redirect("/devices");
    }
  }, [canAccess, isLoading, redirect]);

  return (
    <>
      <Title title="Dashboard" />
      <IfCanAccess resource="dashboard" action="read">
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Dashboard <ToggleFiltersButton />
          <YearlyNormalizationSelect />
        </Typography>
        {filter && <DashboardFilters />}
        <Tabs
          value={pathname === "/dashboard" ? "/dashboard/savings" : pathname}
          sx={{ marginBottom: 2 }}
        >
          <Tab
            label="Économies"
            value="/dashboard/savings"
            to="/dashboard/savings"
            component={Link}
          />
          <Tab
            label="Boîtiers"
            value="/dashboard/devices"
            to="/dashboard/devices"
            component={Link}
          />
          <Tab
            label="Interventions"
            value="/dashboard/interventions"
            to="/dashboard/interventions"
            component={Link}
          />
        </Tabs>
        <Routes>
          <Route
            path="/"
            element={<SavingsDashboard filter={filter ?? {}} />}
          />
          <Route
            path="/savings"
            element={<SavingsDashboard filter={filter ?? {}} />}
          />
          <Route path="/interventions" element={<InterventionsDashboard />} />
          <Route
            path="/devices"
            element={<DevicesDashboard filter={filter ?? {}} />}
          />
        </Routes>
      </IfCanAccess>
    </>
  );
};
