import { Alert, Grid } from "@mui/material";
import { ConsumptionGraph } from "./widgets/Consumption";
import { ConsumptionProgressionGraph } from "./widgets/ConsumptionProgression";
import { MonthlySavingsGraph } from "./widgets/MonthlySavings";
import { SavingsAverageGraph } from "./widgets/SavingsAverage";
import { SavingsProgressionGraph } from "./widgets/SavingsProgression";
import { SavingsState } from "./widgets/SavingsState";

export const SavingsDashboard = ({ filter = {} }: { filter: any }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info" sx={{ margin: 1 }}>
          Retrouvez le nouveau dashboard sur metabase:{" "}
          <a href="https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc">
            Voir le dashboard metabase
          </a>
        </Alert>
      </Grid>
      <ConsumptionGraph filter={filter} />
      <Grid item xs={12} md={6}>
        <SavingsAverageGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SavingsState filter={filter} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MonthlySavingsGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SavingsProgressionGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConsumptionProgressionGraph filter={filter} />
      </Grid>
    </Grid>
  );
};
