import { get, set } from "lodash";
import { useCallback, useEffect } from "react";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";

export const useURLFilter = (source: string, nestQuery = true) => {
  const [value, setURLValue] = useQueryParam(
    "filter",
    withDefault(JsonParam, {})
  );

  useEffect(() => {
    const path = source.split(".").slice(0, -1).join(".");

    const filterValue = get(value, path);

    if (
      filterValue &&
      typeof filterValue === "object" &&
      Object.keys(filterValue).length === 0
    ) {
      setURLValue((previous: any) => ({ ...set(previous, path, undefined) }));
    }
  }, [value, setURLValue, source]);

  const setValue = useCallback(
    (newValue: any) => {
      setURLValue((previous: any) => ({
        ...(nestQuery
          ? set(previous, source, newValue)
          : { ...previous, [source]: newValue }),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
    [source, setURLValue]
  );

  return [get(value, source), setValue] as const;
};
