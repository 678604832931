import { Avatar, Box, Tooltip } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { Project } from "../type";

export const ProjectContactsField = () => {
  const record = useRecordContext<Project>();
  return (
    <Box display="flex">
      {record?.contacts?.map((contact, i) => (
        <Tooltip key={i} title={`${contact.role}: ${contact.name}`}>
          <Avatar
            alt={contact.name}
            variant="rounded"
            sx={{
              marginLeft: 1,
            }}
            src={contact.picture ?? undefined}
          >
            {contact.name[0]}
          </Avatar>
        </Tooltip>
      ))}
    </Box>
  );
};
