import { Typography } from "@mui/material";
import { FunctionField, ReferenceField, TextField } from "react-admin";
import type { Project } from "../type";

export default function ProjectNameField() {
  return (
    <FunctionField<Project>
      render={(record) =>
        record.deploymentMaintenanceProviderId ? (
          <>
            <TextField variant="body1" source="name" />
            <ReferenceField
              reference="maintenance-providers"
              source="deploymentMaintenanceProviderId"
              link={false}
            >
              {" ("}
              <TextField variant="body1" source="name" />)
            </ReferenceField>
          </>
        ) : (
          <Typography variant="body1">{record.name}</Typography>
        )
      }
    />
  );
}
