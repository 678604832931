import OpenIcon from "@mui/icons-material/OpenInNew";
import { Typography } from "@mui/material";
import { FunctionField } from "react-admin";
import { Link } from "react-router-dom";
import type { Communication } from "../type";

type CommunicationExternalIdFieldProps = {
  label?: string;
};

export function CommunicationExternalIdField({
  label,
}: CommunicationExternalIdFieldProps) {
  return (
    <FunctionField<Communication>
      render={(record) => {
        if (!record.externalId) {
          return null;
        }
        const link = getExternalLink(record.externalId);

        if (!link) {
          return (
            <Typography component="span" variant="body2">
              {record.externalId}
            </Typography>
          );
        }

        return (
          <Link to={link} target="_blank" title={label}>
            <OpenIcon fontSize="small" />
          </Link>
        );
      }}
    />
  );
}

export const getExternalLink = (externalId: string) => {
  if (externalId.startsWith("brevo/transactional-email/")) {
    return `https://app-smtp.brevo.com/log/preview/${externalId.replace(
      "brevo/transactional-email/",
      ""
    )}`;
  }

  if (externalId.startsWith("maileva/")) {
    return `https://espaceclient.maileva.com/courriers/${externalId.replace(
      "maileva/",
      ""
    )}`;
  }

  return;
};
