import ClearIcon from "@mui/icons-material/Clear";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useURLFilter } from "../../../filters/useURLFilter";

export const SelectZipCode = () => {
  const [value, setFilters] = useURLFilter("housing.zipCode");

  const zoneToValue = (zone: (typeof zones)[0]) =>
    JSON.stringify({
      $regex: "^(" + zone.departments.map((d) => `${d}`).join("|") + ")",
    });

  return (
    <FormControl fullWidth>
      <InputLabel>Zone Géographique</InputLabel>
      <Select
        label="Zone Géographique"
        value={JSON.stringify(value) ?? ""}
        placeholder="Zone Géographique"
        onChange={(event) => setFilters(JSON.parse(event.target.value))}
        renderValue={(value) => {
          const zone = zones.find((z) => zoneToValue(z) === value);
          return <>{zone?.label ?? ""}</>;
        }}
        endAdornment={
          <IconButton
            sx={{ display: value ? "" : "none" }}
            onClick={() => setFilters(undefined)}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        {zones.map((zone, index) => (
          <MenuItem
            key={index}
            value={zoneToValue(zone)}
            sx={{
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography>{zone.label}</Typography>
            <Typography paragraph variant="caption">
              {zone.description}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const zones = [
  {
    label: "Zone H1",
    description:
      "territoires où les températures hivernales sont les plus froides. Cette zone correspond aux départements de l’Est et du Nord de la France sous influence d’un climat semi-continental",
    departments: [
      "01",
      "02",
      "03",
      "05",
      "08",
      "10",
      "14",
      "15",
      "19",
      "21",
      "23",
      "25",
      "27",
      "28",
      "38",
      "39",
      "42",
      "43",
      "45",
      "51",
      "52",
      "54",
      "55",
      "57",
      "58",
      "59",
      "60",
      "61",
      "62",
      "63",
      "67",
      "68",
      "69",
      "70",
      "71",
      "73",
      "74",
      "75",
      "76",
      "77",
      "78",
      "80",
      "87",
      "88",
      "89",
      "90",
      "91",
      "92",
      "93",
      "94",
      "95",
    ],
  },
  {
    label: "Zone H2",
    description:
      "territoires où les hivers sont plus tempérés. Cette zone comprend en outre les départements de l’Ouest de la France qui sont sous l’influence d’un climat océanique",
    departments: [
      "04",
      "07",
      "09",
      "12",
      "16",
      "17",
      "18",
      "22",
      "24",
      "26",
      "29",
      "31",
      "32",
      "33",
      "35",
      "36",
      "37",
      "40",
      "41",
      "44",
      "46",
      "47",
      "48",
      "49",
      "50",
      "53",
      "56",
      "64",
      "65",
      "72",
      "79",
      "81",
      "82",
      "84",
      "85",
      "86",
    ],
  },
  {
    label: "Zone H3",
    description:
      "qui correspond aux territoires du pourtour méditerranéen, sous l’influence du climat méditerranéen",
    departments: ["06", "11", "13", "20", "30", "34", "66", "83"],
  },
];
