import { FunctionField } from "react-admin";
import type { Heater } from "../type";

export const getHeaterPosition = (heater: Heater) => {
  if (heater.vertical === true) return "Vertical";
  if (heater.vertical === false) return "Horizontal";
  return "Unknown";
};

export default function HeaterPositionField() {
  return <FunctionField<Heater> render={getHeaterPosition} variant="body1" />;
}
