import { Create, Edit, SelectInput, SimpleForm, TextInput } from "react-admin";

enum Roles {
  ADMIN = "Admin",
  ELAX = "Elax",
  SANDBOX = "Sandbox",
}

export const UserCreate = () => {
  return (
    <Create>
      <Form />
    </Create>
  );
};

export const UserEdit = () => (
  <Edit>
    <Form />
  </Edit>
);

const Form = () => (
  <SimpleForm>
    <TextInput source="email" />
    <SelectInput
      label="Role"
      source="role"
      choices={Object.values(Roles).map((role) => ({
        id: role,
        name: role,
      }))}
    />
  </SimpleForm>
);
