import {
  Datagrid,
  EditButton,
  ImageField,
  List,
  NumberField,
  SearchInput,
  TextField,
} from "react-admin";
import { PipedriveLink } from "../../fields/PipedriveLink";
import { ClientCategory } from "./fields/ClientCategory";

export const OrganizationList = () => {
  return (
    <List perPage={50} filters={[<SearchInput source="q" alwaysOn />]}>
      <Datagrid bulkActionButtons={false}>
        <ImageField
          label="Logo"
          source="logoSrc"
          sx={{
            "& img": { maxHeight: 30, maxWidth: 100, objectFit: "contain" },
          }}
        />
        <TextField label="Name" source="name" />
        <ClientCategory label="Category" source="category" sortable />
        <PipedriveLink
          label="Pipedrive link"
          source="pipedriveOrganisationId"
          resource="organization"
          size={20}
        />
        <NumberField label="Projects" source="stats.projectCount" />
        <TextField label="Heater Count" source="heaterCount" />
        <NumberField label="Installed Devices" source="stats.deviceCount" />
        <NumberField label="Admins" source="adminsCount" />
        <NumberField label="Viewers" source="viewersCount" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
