import { chain } from "lodash";
import { useRecordContext } from "react-admin";
import { useDeviceDailyStats } from "../../../../../../../providers/data-hooks/useDeviceDailyStats";
import type { Device } from "../../../../../types";
import { addHeatmapColors } from "./heatmap-utils";

export const useDataCoverageTimeline = (options: { enabled: boolean }) => {
  const record = useRecordContext<Device>();
  const { data } = useDeviceDailyStats(record?.id, options);

  return addHeatmapColors(
    chain(data)
      .keyBy((e) => e.day)
      .mapValues((e) => ({ value: e.data_coverage }))
      .value() ?? {},
    coveragePalette
  );
};

export const coveragePalette = [
  "#fff7fb",
  "#ece2f0",
  "#d0d1e6",
  "#a6bddb",
  "#67a9cf",
  "#3690c0",
  "#02818a",
];
