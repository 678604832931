import Highlight from "@tiptap/extension-highlight";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Youtube from "@tiptap/extension-youtube";
import StarterKit from "@tiptap/starter-kit";
import {
  ClearButtons,
  FormatButtons,
  ImageButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import type { RichTextInputProps } from "ra-input-rich-text";
import type { Except } from "type-fest";
import { YoutubeButton } from "./YoutubeButton";

const size = "small";

export const PostBodyInput = (
  props: Except<RichTextInputProps, "editorOptions" | "toolbar">
) => (
  <RichTextInput
    {...props}
    editorOptions={editorOptions}
    toolbar={
      <RichTextInputToolbar>
        <LevelSelect size={size} />
        <FormatButtons size={size} />
        <ListButtons size={size} />
        <LinkButtons size={size} />
        <ImageButtons size={size} />
        <YoutubeButton size={size} />
        <QuoteButtons size={size} />
        <ClearButtons size={size} />
      </RichTextInputToolbar>
    }
  />
);

const editorOptions = {
  extensions: [
    StarterKit,
    Underline,
    Link,
    Image.configure({
      inline: true,
    }),
    Highlight.configure({ multicolor: true }),
    Youtube.configure({
      inline: false,
      width: 560,
      height: 315,
      modestBranding: true,
      controls: true,
      nocookie: true,
      HTMLAttributes: {
        frameBorder: 0,
      },
    }),
  ],
};
