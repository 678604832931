import type { ReactNode } from "react";
import { RecordContextProvider, useRecordContext } from "react-admin";
import type { Identifier } from "react-admin";
import type { Residence } from "../types";

export const WithResidenceProjectDetails = ({
  projectId,
  children,
}: {
  projectId: Identifier;
  children: ReactNode;
  label?: string;
}) => {
  const residence = useRecordContext<Residence>();
  return (
    <RecordContextProvider
      value={residence?.projects.find((p) => p.projectId === projectId)}
    >
      {children}
    </RecordContextProvider>
  );
};
