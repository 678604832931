import { Box, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";
import { JsonParam, useQueryParam } from "use-query-params";
import { OptimizedReferenceOneField } from "../../../fields/OptimizedReferenceOneField";
import { CommunicationMedium } from "../../communications/type";
import { NameField } from "../../end-users/fields/NameField";
import { AddressField } from "../../housing/fields/Address";
import { DeploymentCommunicationsSentField } from "../../housing/fields/DeploymentCommunicationsSentField";
import { HousingLastInstallationField } from "../../housing/fields/HousingLastInstallationField";
import { HousingLastProjectField } from "../../housing/fields/HousingLastProjectField";
import { HousingStatusField } from "../../housing/fields/Status";
import type { Residence } from "../types";

export const ResidenceHousingList = () => {
  const residence = useRecordContext<Residence>();
  const lastProjectId = residence?.projects.at(-1)?.projectId;
  const [filters, setFilters] = useQueryParam("filters", JsonParam);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box>
          <InputLabel>
            Filter by deployment communication of latest project
          </InputLabel>
          <Select
            value={filters?.missingCommunications?.medium ?? ""}
            onChange={(event) =>
              setFilters({
                ...filters,
                missingCommunications: event.target.value
                  ? {
                      projectId: lastProjectId,
                      medium: event.target.value,
                    }
                  : undefined,
              })
            }
            size="small"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            <MenuItem value={CommunicationMedium.LETTER}>
              Pas de lettre envoyée malgré éligibilité
            </MenuItem>
            <MenuItem value={CommunicationMedium.EMAIL}>
              Pas d'email envoyé malgré éligibilité
            </MenuItem>
          </Select>
        </Box>
      </Stack>
      <ReferenceManyField
        reference="housings"
        target="residenceId"
        sort={{ field: "unitId", order: "ASC" }}
        filter={filters}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Unit ID" source="unitId" />
          <AddressField label="Address" />
          <OptimizedReferenceOneField
            reference="end-users"
            target="housingIds"
            label="End User"
            sortable={false}
            link="show"
            fields={[
              "title1",
              "title2",
              "firstName1",
              "firstName2",
              "lastName1",
              "lastName2",
            ]}
          >
            <NameField />
          </OptimizedReferenceOneField>
          <HousingStatusField label="Status" source="status" />
          <HousingLastInstallationField label="Equiped At">
            <DateField source="datetime" />
          </HousingLastInstallationField>
          <HousingLastInstallationField label="Planned At">
            <DateField source="plannedSlot.startDate" showTime />
          </HousingLastInstallationField>
          <HousingLastProjectField label="Last project" />
          {lastProjectId && (
            <DeploymentCommunicationsSentField
              label="Deployment letter sent"
              projectId={lastProjectId}
              medium={CommunicationMedium.LETTER}
            />
          )}
          {lastProjectId && (
            <DeploymentCommunicationsSentField
              label="Deployment email sent"
              projectId={lastProjectId}
              medium={CommunicationMedium.EMAIL}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
