import { EnumField } from "../../../fields/EnumField";
import {
  interventionSourcingStatusColors,
  interventionSourcingStatusLabels,
} from "../types";

export const InterventionSourcingStatusField = ({
  label,
}: {
  label?: string;
}) => {
  return (
    <EnumField
      label={label}
      source="sourcingStatus"
      colors={interventionSourcingStatusColors}
      labels={interventionSourcingStatusLabels}
    />
  );
};
