import { ReferenceField, TextField, useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import { InterventionType } from "../types";

export const InterventionDevice = ({
  label,
}: DatagridOrFilterRequiredProps) => {
  const intervention = useRecordContext();

  if (!intervention) {
    return null;
  }

  if (intervention.type === InterventionType.REPLACEMENT) {
    return (
      <>
        <ReferenceField
          label={label ?? "Device"}
          source="replacedDeviceId"
          reference="devices"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        {" → "}
        <ReferenceField
          label="Device"
          source="deviceId"
          reference="devices"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </>
    );
  }

  return (
    <ReferenceField
      label="Device"
      source="deviceId"
      reference="devices"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  );
};
