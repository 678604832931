import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import type { ReactNode } from "react";
import { useState } from "react";
import type { ButtonProps } from "react-admin";
import { Button } from "react-admin";
import type { Except } from "type-fest";

type DialogButtonProps = {
  children: ReactNode;
};

export function DialogButton({
  title,
  children,
  ...props
}: DialogButtonProps & Except<ButtonProps, "label" | "onClick">) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} label={title} {...props} />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
}
