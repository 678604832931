import { Autocomplete, TextField } from "@mui/material";
import type { SyntheticEvent } from "react";
import { useGetList } from "react-admin";
import type { AutocompleteInputProps } from "react-admin";
import { useURLFilter } from "../../../filters/useURLFilter";
import type { Project } from "../../project/type";

export const SelectProject = (props: AutocompleteInputProps) => {
  const { data } = useGetList<Project>("projects", {
    pagination: { perPage: 1000, page: 1 },
  });

  const [selectedProjectIds, setSelectedProjectIds] = useURLFilter(
    props.source ?? "projectId"
  );

  const projectsNameById = Object.fromEntries(
    (data || []).map((p) => [p.id, p.name])
  );
  const projectIds = Object.keys(projectsNameById);

  const onChange = (_event: SyntheticEvent, selection: string[]) =>
    setSelectedProjectIds(selection);

  return (
    <Autocomplete
      size="small"
      sx={{ minWidth: 200 }}
      value={selectedProjectIds}
      options={projectIds}
      getOptionLabel={(id) => projectsNameById[id]}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      loading={!data}
      multiple
      onChange={onChange}
    />
  );
};
