import type { RaThemeOptions } from "react-admin";
import { defaultLightTheme } from "react-admin";

export const elaxTheme: RaThemeOptions = {
  ...defaultLightTheme,
  palette: {
    ...defaultLightTheme.palette,
    background: {
      default: "#fafafb",
    },
    secondary: {
      light: "#6ec6ff",
      main: "#1470ef",
      dark: "#0069c0",
      contrastText: "#fff",
    },
  },
};
