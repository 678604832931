export type Project = {
  id: string;
  name: string;
  quotedDeviceCount?: number;
  listingSpreadsheetId?: string;
  lastListingImport?: {
    date: string;
    errorMessages: string[];
    errorCount: number;
    housingCount: number;
  };
  contacts: {
    role: "CSM" | "AM";
    email: string;
    name: string;
    picture: string | null;
  }[];
  calendlyUtmSource?: string;
  isDeploymentLetterEnabled?: boolean;
  deploymentMaintenanceProviderId?: string;
  deploymentLetter: {
    firstPageText?: string;
    secondPageText?: string;
  };
  housingCount: number;
  deployment: {
    stage: ProjectStage;
    deploymentStartDate?: string;
    deploymentEndDate?: string;
  };
  installationAppointmentSettings?: {
    brevoTemplateId: number;
    smsText: string;
    reminderBrevoTemplateId: number;
    reminderSMSText: string;
  };
};

export enum ProjectStage {
  NOT_STARTED_YET = "NOT_STARTED_YET",
  DEPLOYMENT_PREPARATION = "DEPLOYMENT_PREPARATION",
  DEPLOYMENT_IN_PROGRESS = "DEPLOYMENT_IN_PROGRESS",
  DEPLOYMENT_DONE = "DEPLOYMENT_DONE",
}

export const projectStageLabels: Record<ProjectStage, string> = {
  [ProjectStage.NOT_STARTED_YET]: "non démarré",
  [ProjectStage.DEPLOYMENT_PREPARATION]: "en préparation",
  [ProjectStage.DEPLOYMENT_IN_PROGRESS]: "en cours",
  [ProjectStage.DEPLOYMENT_DONE]: "fini",
};
