import dayjs from "dayjs";
import { FunctionField } from "react-admin";
import type { Device } from "../types";
import { useLastDeviceConfig } from "./DeviceConfig";

export default function DeviceModeField() {
  const lastDeviceConfig = useLastDeviceConfig();
  return (
    <FunctionField<Device>
      render={() =>
        lastDeviceConfig
          ? `${lastDeviceConfig.mode} (for ${dayjs
              .duration(dayjs().diff(lastDeviceConfig.createdAt))
              .humanize()})`
          : ""
      }
      variant="body1"
    />
  );
}
