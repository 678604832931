import { Box } from "@mui/material";
import { TextInput } from "react-admin";

export const ListItemForm = () => {
  return (
    <Box sx={{ minWidth: 300 }}>
      <TextInput label="List" source="listId" fullWidth required />
      <TextInput label="Value" source="value" fullWidth required />
      <TextInput label="Label" source="label" fullWidth />
    </Box>
  );
};
