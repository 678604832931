import { mean } from "lodash";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { Device } from "../types";

export const SavingsField = ({ label }: DatagridOrFilterRequiredProps) => {
  const device = useRecordContext<Device>({ label });

  if (!device?.referenceEnergy || !device.energy_ontimer_last_seven_days) {
    return <>-</>;
  }

  const values = device.energy_ontimer_last_seven_days.filter(
    (v) => !Number.isNaN(v)
  );

  if (values.length === 0) {
    return <>-</>;
  }

  return <>{((device.referenceEnergy - mean(values)) / 1000).toFixed(3)}</>;
};
