import HistoryIcon from "@mui/icons-material/History";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { Button } from "react-admin";
import { EditionHistory } from "./EditionHistory";
import { useActivityLogs } from "./hooks/useActivityLogs";

export const RecordDetails = () => {
  const [open, setOpen] = useState(false);

  const { record, canAccess, data, total } = useActivityLogs();

  if (total === 0 || !canAccess || !record) {
    return null;
  }

  if (!data) {
    return <CircularProgress size={15} />;
  }

  return (
    <>
      <Button
        startIcon={<HistoryIcon />}
        onClick={() => setOpen(true)}
        label={`${total} updates`}
      />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogContent>
          <EditionHistory />
        </DialogContent>
      </Dialog>
    </>
  );
};
