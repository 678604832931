import { Box, Stack, Tooltip } from "@mui/material";
import { DateField, Link, useRecordContext } from "react-admin";
import type { Communication } from "../type";
import {
  communicationMediumConfigs,
  communicationStatusConfigs,
  communicationTopicConfigs,
} from "../type";

export const DenseCommunication = () => {
  const record = useRecordContext<Communication>();

  if (!record) {
    return null;
  }

  const topicConfig = communicationTopicConfigs[record.topic];
  const statusConfig = communicationStatusConfigs[record.status];
  const mediumConfig = communicationMediumConfigs[record.medium];

  const Icon = mediumConfig?.icon;

  return (
    <Link to={`/communications/${record.id}/show`} underline="hover">
      <Stack direction="row" spacing={1} marginTop={1} alignItems="center">
        <DateField
          source="date"
          options={{ day: "2-digit", month: "2-digit", year: "2-digit" }}
        />
        <Tooltip
          title={`Medium: ${mediumConfig.label} - Status: ${
            statusConfig?.label ?? record.status
          }${record.statusDetails ? ` (${record.statusDetails})` : ""}`}
        >
          <Box
            style={{
              backgroundColor: topicConfig?.color["100"],
              color: topicConfig?.color["400"],
              borderLeft: `solid 6px ${statusConfig.color["400"]}`,
              borderRadius: 5,
              padding: `2px 5px`,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            {Icon && <Icon fontSize="small" sx={{ marginRight: 0.5 }} />}
            {topicConfig?.label ?? record.topic}
          </Box>
        </Tooltip>
      </Stack>
    </Link>
  );
};
