export type AlertHistory = {
  id: string;
  deviceId: string;
  code: AlertCode;
  timeStart: Date;
  timeEnd?: Date;
  mutedUntil?: Date;
  comment?: string;
  isFalsePositive?: boolean;
  message?: string;
};

export enum AlertCode {
  NO_HEATING = "no-heating",
  DEPRECATED_BROKEN_PROBE = "broken-probe",
  BROKEN_PROBE_LOW = "broken-probe-low",
  BROKEN_PROBE_HIGH = "broken-probe-high",
  LOST_CONNECTION = "msg-frequency",
  LOST_POWER = "lost-power",
  LOW_TEMPERATURE = "low-temperature",
  TARGET_TEMPERATURE = "target-temperature",
  CONTRACT_CHANGE = "detected-mode-change",
  SWITCH = "switch",
  VALVE_LEAKAGE = "valve-leakage",
  LEGIONELLOSE = "legionellose",
  THERMOSTAT = "legionellose-observation",
  ELECTRICAL_ISSUE = "electrical-issue",
  RELAY_POWER_KO = "relay-power-ko",
  VALVE_OBSTRUCTION = "valve-obstruction",
  TRIGGER_ALERT = "trigger-alert",
  LEGIONELLOSE_SCHEDULE = "legionellose-schedule",
  LEGIONELLOSE_SETPOINT = "legionellose-setpoint",
  HEATING_ALERT = "heating-alert",
  COOLING_SPEED = "cooling-speed",
  WATER_LEAKAGE = "water-leakage",
  WATER_LEAKAGE_1 = "water-leakage-1",
  WATER_LEAKAGE_2 = "water-leakage-2",
  TOO_MANY_REBOOTS = "too-many-reboots",
  DISCOMFORT_ALERT = "discomfort-alert",
  HP_HC_NOT_FOUND_1 = "hp_hc_not_found",
  HP_HC_NOT_FOUND_2 = "hp-hc-not-found",
}

export const alertCodeToFrenchLabel: Record<AlertCode, string> = {
  [AlertCode.NO_HEATING]: "Problème de chauffe",
  [AlertCode.DEPRECATED_BROKEN_PROBE]: "Sonde cassée",
  [AlertCode.BROKEN_PROBE_LOW]: "Sonde cassée (T°C trop élevée)",
  [AlertCode.BROKEN_PROBE_HIGH]: "Sonde cassée (T°C trop basse)",
  [AlertCode.LOST_CONNECTION]: "Perte de connexion",
  [AlertCode.LOST_POWER]: "Perte d'alimentation",
  [AlertCode.LOW_TEMPERATURE]: "low-temperature",
  [AlertCode.TARGET_TEMPERATURE]: "target-temperature",
  [AlertCode.CONTRACT_CHANGE]: "detected-mode-change",
  [AlertCode.SWITCH]: "Bypass activé",
  [AlertCode.VALVE_LEAKAGE]: "valve-leakage",
  [AlertCode.LEGIONELLOSE]: "legionellose",
  [AlertCode.THERMOSTAT]: "Thermostat mécanique trop bas",
  [AlertCode.ELECTRICAL_ISSUE]: "Problème électrique partiel",
  [AlertCode.RELAY_POWER_KO]: "relay-power-ko",
  [AlertCode.VALVE_OBSTRUCTION]: "valve-obstruction",
  [AlertCode.TRIGGER_ALERT]: "trigger-alert",
  [AlertCode.LEGIONELLOSE_SCHEDULE]: "legionellose-schedule",
  [AlertCode.LEGIONELLOSE_SETPOINT]: "legionellose-setpoint",
  [AlertCode.HEATING_ALERT]: "heating-alert",
  [AlertCode.COOLING_SPEED]: "cooling-speed",
  [AlertCode.WATER_LEAKAGE]: "water-leakage",
  [AlertCode.WATER_LEAKAGE_1]: "water-leakage-1",
  [AlertCode.WATER_LEAKAGE_2]: "water-leakage-2",

  [AlertCode.TOO_MANY_REBOOTS]: "Problème de redémarrages excessifs",
  [AlertCode.DISCOMFORT_ALERT]: "discomfort-alert",
  [AlertCode.HP_HC_NOT_FOUND_1]: "hp-hc-not-found",
  [AlertCode.HP_HC_NOT_FOUND_2]: "hp-hc-not-found",
};
