import { colors } from "@mui/material";
import { ChipField, useRecordContext } from "react-admin";
import type { ChipFieldProps } from "react-admin";
import type { Except } from "type-fest";

export default function TagField(props: Except<ChipFieldProps, "source">) {
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  return (
    <ChipField
      source="name"
      sx={{
        color: record.color || colors.grey[600],
        backgroundColor: (record.color || colors.grey[600]) + "42",
        fontWeight: "bold",
      }}
      {...props}
    />
  );
}
