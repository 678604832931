import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import type Highcharts from "highcharts";
import { useState } from "react";
import { useRedirect } from "react-admin";
import { dataProvider } from "../../../providers/data";
import {
  InterventionStatus,
  InterventionType,
} from "../../../resources/intervention/types";
import { Chart } from "../../Chart";

const granularities = [
  {
    value: "year" as const,
    history: 3,
  },
  {
    value: "month" as const,
    history: 12,
  },
  {
    value: "week" as const,
    history: 12,
  },
  {
    value: "day" as const,
    history: 31,
  },
];

export const InterventionsFlow = ({
  projectId,
  dealId,
}: {
  projectId?: string;
  dealId?: string;
}) => {
  const [granularity, setGranularity] = useState(granularities[1]);
  const redirect = useRedirect();

  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getInterventionCount(
        projectId
          ? undefined
          : dayjs().subtract(granularity.history, granularity.value).toDate(),
        granularity.value,
        projectId,
        dealId
      ),
    queryKey: [`interventions/count`, granularity.value, projectId, dealId],
    staleTime: 1000 * 60 * 5,
  });

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Installations",
    },
    xAxis: {
      categories: data?.map((d) => d.key),
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Nombre d'installations",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        dataLabels: {
          enabled: true,
        },
        events: {
          click: (event) => {
            const status = (event.point as any).custom.status;

            const timeFilter =
              status === InterventionStatus.PLANNED
                ? "plannedSlot.startDate"
                : "datetime";

            redirect(
              () =>
                `interventions?filter=${JSON.stringify({
                  projectId: projectId == null ? undefined : [projectId],
                  [timeFilter]: {
                    $gte: (event.point as any).custom.startDate,
                    $lte: (event.point as any).custom.endDate,
                  },
                  type: InterventionType.INSTALLATION,
                  status,
                })}`
            );
          },
        },
      },
    },
    series:
      data &&
      ((
        [
          InterventionStatus.SUCCESS,
          InterventionStatus.FAILED,
          InterventionStatus.NOT_BOOKED,
          InterventionStatus.PLANNED,
        ] as const
      ).map((status) => {
        return {
          name: status,
          type: "column",
          data: data.map(({ values, startDate, endDate }) => ({
            y: values[status] ?? 0,
            custom: {
              startDate,
              endDate,
              status,
            },
          })),
          color: colors[status],
          stack: status,
        };
      }) as any),
  };

  return (
    <Paper elevation={1} sx={{ position: "relative" }}>
      <FormControl
        sx={{ position: "absolute", right: 40, top: 5, zIndex: 10 }}
        size="small"
      >
        <InputLabel>Granularité</InputLabel>
        <Select
          label="Granularité"
          onChange={(event) => {
            setGranularity(
              granularities.find((g) => g.value === event.target.value)!
            );
          }}
          value={granularity.value}
        >
          {granularities.map((g) => (
            <MenuItem key={g.value} value={g.value}>
              {g.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Chart options={options} isLoading={!data} />
    </Paper>
  );
};

const colors = {
  [InterventionStatus.SUCCESS]: "#8bc34a",
  [InterventionStatus.FAILED]: "#f44336",
  [InterventionStatus.NOT_BOOKED]: "#eb7734",
  [InterventionStatus.PLANNED]: "#9e9e9e",
};
