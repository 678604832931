import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import type { SxProps } from "@mui/material";
import { CreateDialog } from "@react-admin/ra-form-layout";
import { useState } from "react";
import { Link, SimpleForm, TextInput, useGetList } from "react-admin";
import type { ListItem } from "../resources/list-item/types";

type OptionalFieldOptions = {
  enabled: boolean;
  label?: string;
  helperText?: string;
};

type EditableListFieldProps = {
  label: string;
  value: string;
  listId: string;
  itemName?: {
    singular: string;
    plural: string;
    article: "a" | "an";
  };
  itemLabel?: OptionalFieldOptions;
  itemDescription?: OptionalFieldOptions;
  onChange: (
    selectedValue: string,
    selectedDescription: string | undefined
  ) => void;
  sx?: SxProps;
  required?: boolean;
};

export function EditableListField({
  label,
  value,
  listId,
  itemName,
  onChange,
  sx,
  required,
  itemLabel,
  itemDescription,
}: EditableListFieldProps) {
  const { data: listItems, isLoading } = useGetList<ListItem>("list-items", {
    pagination: { page: 1, perPage: 1000 },
    filter: { listId },
  });
  const [showCreate, setShowCreate] = useState(false);

  return (
    <>
      <FormControl size="small" fullWidth sx={sx}>
        <InputLabel>{label}</InputLabel>
        <Select<string>
          label={label}
          fullWidth
          value={value}
          onChange={(event) => {
            const selectedListItem = listItems?.find(
              (item) => item.value === event.target.value
            );
            onChange(event.target.value, selectedListItem?.description);
          }}
          size="small"
          required={required}
        >
          {!isLoading &&
            listItems?.map((listItem) => (
              <MenuItem
                key={listItem.value}
                value={listItem.value}
                sx={{ whiteSpace: "normal" }}
              >
                {listItem.label ?? listItem.value}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText sx={{ color: "blue" }}>
          <Stack direction="row" justifyContent="space-between">
            <Box
              onClick={() => setShowCreate(true)}
              sx={{ color: "#1976d2", cursor: "pointer" }}
            >
              ➕ add {itemName?.article ?? "an"}{" "}
              {itemName?.singular.toLowerCase() ?? "item"}
            </Box>
            <Link
              to={`/list-items?${new URLSearchParams({
                filters: JSON.stringify({ listId }),
              }).toString()}`}
            >
              ✏️ edit {itemName?.plural.toLowerCase() ?? "items"}
            </Link>
          </Stack>
        </FormHelperText>
      </FormControl>
      <CreateDialog
        title={`Add ${itemName?.article ?? "an"} ${
          itemName?.singular.toLowerCase() ?? "item"
        }`}
        resource="list-items"
        record={{ listId }}
        isOpen={showCreate}
        close={() => setShowCreate(false)}
        redirect={false}
      >
        <SimpleForm>
          <Box sx={{ minWidth: 500 }}>
            <TextInput label={label} source="value" fullWidth required />
          </Box>
          {itemLabel?.enabled && (
            <Box sx={{ minWidth: 500 }}>
              <TextInput
                label={itemLabel.label ?? "Label"}
                source="label"
                fullWidth
                helperText={itemLabel?.helperText}
              />
            </Box>
          )}
          {itemDescription?.enabled && (
            <Box sx={{ minWidth: 500 }}>
              <TextInput
                label={itemDescription?.label ?? "Description"}
                source="description"
                fullWidth
                multiline
                rows={5}
                helperText={itemDescription?.helperText}
              />
            </Box>
          )}
        </SimpleForm>
      </CreateDialog>
    </>
  );
}
