import CallIcon from "@mui/icons-material/Call";
import ConstructionIcon from "@mui/icons-material/Construction";
import DoorIcon from "@mui/icons-material/DoorFront";
import FactoryIcon from "@mui/icons-material/Factory";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TuneIcon from "@mui/icons-material/Tune";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { brown, green, grey, purple, red } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecordContext } from "react-admin";
import type { ReactElement } from "react-markdown/lib/react-markdown";
import { dataProvider } from "../../../../../providers/data";
import type { Device } from "../../../types";

dayjs.extend(relativeTime);

export enum HistoryItemType {
  ALERT = "Alert",
  INTERVENTION = "Intervention",
  TICKET = "Zendesk Ticket",
  PRODUCTION = "Production",
  CONFIG = "Config",
  ABSENCE = "Absence",
}

const historyItemIcon: Record<string, ReactElement> = {
  [HistoryItemType.ALERT]: <WarningAmberIcon />,
  [HistoryItemType.INTERVENTION]: <ConstructionIcon />,
  [HistoryItemType.TICKET]: <CallIcon />,
  [HistoryItemType.PRODUCTION]: <FactoryIcon />,
  [HistoryItemType.CONFIG]: <TuneIcon />,
  [HistoryItemType.ABSENCE]: <DoorIcon />,
};

export const historyItemColor: Record<string, string> = {
  [HistoryItemType.ALERT]: red[500],
  [HistoryItemType.INTERVENTION]: brown[500],
  [HistoryItemType.TICKET]: purple[500],
  [HistoryItemType.PRODUCTION]: grey[500],
  [HistoryItemType.CONFIG]: green[500],
  [HistoryItemType.ABSENCE]: grey[300],
};

export const History = () => {
  const record = useRecordContext<Device>();
  const { data, isLoading } = useQuery({
    queryKey: ["devices", "history", { id: record?.id }],
    queryFn: () => record && dataProvider.getDeviceHistory(record.id),
    enabled: Boolean(record),
  });

  if (isLoading) return <CircularProgress />;
  if (!data) throw new Error("No data");

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {data.map((item) => (
              <TimelineItem>
                <TimelineOppositeContent sx={{ mt: 1.5 }} color="textSecondary">
                  <Typography fontWeight="bold">
                    {dayjs(item.date).format("DD/MM/YYYY HH:mm")}
                  </Typography>
                  <Typography>{dayjs(item.date).fromNow()}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: historyItemColor[item.type] }}
                  >
                    {historyItemIcon[item.type]}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {item.type}
                          </Typography>
                          <Typography variant="body1">{item.title}</Typography>
                          <Typography
                            variant="body2"
                            sx={{ whiteSpace: "pre-line" }}
                          >
                            {item.content}
                          </Typography>
                        </Box>
                        {item.url && (
                          <Button
                            variant="text"
                            href={item.url}
                            target="_blank"
                            startIcon={<OpenInNewIcon />}
                            sx={{ marginLeft: 2 }}
                          >
                            Open
                          </Button>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Grid>
      </Grid>
    </>
  );
};
