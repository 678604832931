import { DateField, ReferenceField, TextField } from "react-admin";
import { NameField } from "../../end-users/fields/NameField";
import { CommunicationExternalIdField } from "./CommunicationExternalId";
import { CommunicationMediumField } from "./CommunicationMedium";
import { CommunicationStatusField } from "./CommunicationStatus";
import { CommunicationTopicField } from "./CommunicationTopic";

export const communicationFields = {
  date: <DateField source="date" label="Date" showTime />,
  topic: <CommunicationTopicField label="Topic" />,
  medium: <CommunicationMediumField label="Medium" />,
  status: <CommunicationStatusField label="Status" />,
  resident: (
    <ReferenceField
      label="Resident"
      reference="end-users"
      source="context.endUserId"
      link="show"
    >
      <NameField />
    </ReferenceField>
  ),
  project: (
    <ReferenceField
      label="Project"
      reference="projects"
      source="context.projectId"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  ),
  organization: (
    <ReferenceField
      label="Organization"
      reference="organizations"
      source="context.organizationId"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  ),
  housing: (
    <ReferenceField
      label="Housing"
      source="context.housingId"
      reference="housings"
      link="show"
    >
      <TextField source="unitId" />
    </ReferenceField>
  ),
  maintenanceProvider: (
    <ReferenceField
      label="Maintenance Provider"
      source="context.maintenanceProviderId"
      reference="maintenance-providers"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  ),
  intervention: (
    <ReferenceField
      label="Intervention"
      source="context.interventionId"
      reference="interventions"
      link="show"
    >
      <TextField source="id" />
    </ReferenceField>
  ),
  externalId: <CommunicationExternalIdField label="Link" />,
  residenceContact: (
    <ReferenceField
      label="Residence Contact"
      source="context.residenceContactId"
      reference="residence-contacts"
      link={false}
    >
      <TextField source="email" />
    </ReferenceField>
  ),
};
