import { Grid } from "@mui/material";
import { Datagrid, Pagination, ReferenceManyField } from "react-admin";
import { communicationFields } from "../../../communications/fields";

export const Communications = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReferenceManyField
          reference="communications"
          target="context.organizationId"
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false}>
            {communicationFields.date}
            {communicationFields.topic}
            {communicationFields.medium}
            {communicationFields.status}
            {communicationFields.project}
            {communicationFields.project}
            {communicationFields.externalId}
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </Grid>
  );
};
