import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";

dayjs.extend(duration);
dayjs.extend(relativeTime);

type ConsumptionGraphProps = {
  filter: any;
};

export const SavingsProgressionGraph = ({ filter }: ConsumptionGraphProps) => {
  const [yearlyNormalized] = useYearlyNormalized();

  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getDevicesSavingProgression(filter, yearlyNormalized),
    queryKey: [`devices/savings/progression`, filter, yearlyNormalized],
    enabled: !!filter,
  });

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={{
          title: {
            text: "Évolution des économies",
          },
          subtitle: {
            text: data && `Last Update: ${dayjs().to(data.lastUpdateDate)}`,
          },
          xAxis: {
            crosshair: true,
            type: "datetime",
            tickPixelInterval: 150,
          },
          yAxis: [
            {
              title: {
                text: "€/an",
              },
            },
            {
              title: {
                text: "%",
              },
              opposite: true,
            },
          ],
          plotOptions: {
            spline: {
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 3,
                },
              },
              marker: {
                enabled: false,
              },
            },
          },
          tooltip: {
            shared: true,
            valueDecimals: 0,
            headerFormat: "<b>{point.key}</b>:<br />",
          },
          series: data && [
            {
              name: "Average (€/an)",
              type: "spline",
              color: "#2196f3",
              marker: {
                enabled: false,
              },
              data: data.data.map(
                (point) =>
                  ({
                    x: point.timestamp,
                    y: point.averageAnnualSavingsInEuro,
                  } as any)
              ),
            },
            ...getDecilesSeries(
              {
                name: "Median & deciles (€/an)",
                color: "#00bcd4",
                visible: false,
              },
              data.data.map((point) => ({
                timestamp: point.timestamp,
                values: point.decilesAnnualSavingsInEuro,
              }))
            ),
            {
              name: "Average (%)",
              type: "spline",
              color: "#4caf50",
              yAxis: 1,
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 0,
              },
              data: data.data.map((point) => ({
                x: point.timestamp,
                y: point.averageSavings,
              })),
            },
            ...getDecilesSeries(
              {
                name: "Median & deciles (%)",
                color: "#cddc39",
                yAxis: 1,
                visible: false,
              },
              data.data.map((point) => ({
                timestamp: point.timestamp,
                values: point.decilesSavings,
              }))
            ),
          ],
        }}
      />
    </Paper>
  );
};

const getDecilesSeries = (
  options: Partial<Highcharts.SeriesSplineOptions> & { name: string },
  data: { timestamp: number; values: number[] }[]
): Highcharts.SeriesOptionsType[] => {
  return [
    {
      ...options,
      name: options.name,
      id: options.name,
      type: "line",
      tooltip: {
        headerFormat: "<b>{point.key}</b>: ",
        valueDecimals: 0,
        pointFormat: "{point.custom.tooltipContent}",
      },
      marker: {
        enabled: false,
      },
      data: data.map(
        (point) =>
          ({
            x: point.timestamp,
            y: point.values[5],
            custom: {
              tooltipContent: `
              min: ${point.values[0].toFixed(0)}<br />
              decile 1: ${point.values[1].toFixed(0)}<br />
              decile 2: ${point.values[2].toFixed(0)}<br />
              decile 3: ${point.values[3].toFixed(0)}<br />
              decile 4: ${point.values[4].toFixed(0)}<br />
              median: ${point.values[5].toFixed(0)}<br />
              decile 6: ${point.values[6].toFixed(0)}<br />
              decile 7: ${point.values[7].toFixed(0)}<br />
              decile 8: ${point.values[8].toFixed(0)}<br />
              decile 9: ${point.values[9].toFixed(0)}<br />
              max: ${point.values[10].toFixed(0)}<br />
              `,
            },
          } as any)
      ),
    },
    ...[
      [1, 2, 0.1],
      [2, 3, 0.2],
      [3, 4, 0.3],
      [4, 5, 0.4],
      [5, 6, 0.4],
      [6, 7, 0.3],
      [7, 8, 0.2],
      [8, 9, 0.1],
    ].map(
      ([indexMin, indexMax, opacity]): Highcharts.SeriesOptionsType => ({
        type: "arearange" as const,
        linkedTo: options.name,
        yAxis: options.yAxis,
        data: data.map((point) => [
          point.timestamp,
          point.values[indexMin],
          point.values[indexMax],
        ]),
        marker: {
          enabled: false,
        },
        showInLegend: false,
        lineWidth: 0,
        color: options.color,
        fillOpacity: opacity,
        enableMouseTracking: false,
      })
    ),
  ];
};
