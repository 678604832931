import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceOneField,
  TextField,
} from "react-admin";
import { TimeAgoField } from "../../../fields/TimeAgoField";
import { DataTab } from "../../devices/show/tabs/data";
import { InterventionType } from "../../intervention/types";
import { AlertCodeField } from "../fields/AlertCodeField";
import { AlertStatusField } from "../fields/AlertStatusField";
import { alertFilters } from "./filters";

export const AlertList = () => {
  return (
    <List
      perPage={20}
      filters={alertFilters}
      filter={{ includeMuted: false, isFalsePositive: { $ne: true } }}
      sort={{ field: "timeStart", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={false}
        expand={
          <ReferenceField reference="devices" source="deviceId" link={false}>
            <DataTab />
          </ReferenceField>
        }
      >
        <TimeAgoField source="timeStart" label="Start Date" />
        <AlertStatusField label="Status" />
        <AlertCodeField label="Alert" />
        <ReferenceField
          reference="devices"
          source="deviceId"
          link="show"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="devices"
          source="deviceId"
          link="show"
          sortable={false}
        >
          <TextField source="VersionHardware" />
        </ReferenceField>
        <ReferenceField
          reference="devices"
          label="Installation Date"
          source="deviceId"
          sortable={false}
        >
          <ReferenceOneField
            reference="interventions"
            target="deviceId"
            sort={{ field: "createdAt", order: "DESC" }}
            filter={{
              type: [
                InterventionType.INSTALLATION,
                InterventionType.REPLACEMENT,
              ],
            }}
            sortable={false}
          >
            <DateField source="datetime" />
          </ReferenceOneField>
        </ReferenceField>
        <ReferenceField
          reference="devices"
          label="Last message"
          source="deviceId"
          sortable={false}
        >
          <TimeAgoField source="latestDataFrame.ts" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
