import { EnumField } from "../../../fields/EnumField";
import {
  interventionsVisitStatusColors,
  interventionsVisitStatusLabels,
} from "../types";

export const InterventionVisitStatusField = ({ label }: { label?: string }) => (
  <EnumField
    label={label || "Visit Status"}
    source="visitStatus"
    colors={interventionsVisitStatusColors}
    labels={interventionsVisitStatusLabels}
  />
);
