import { Person } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { FieldItem } from "../../../display/FieldItem";
import { useCommunicationDetails } from "../data";
import type { Communication } from "../type";

export function CommunicationRecipient() {
  const communication = useRecordContext<Communication>();

  const { data, isLoading } = useCommunicationDetails(communication?.id);

  return (
    <FieldItem label="Recipient" icon={<Person />} isLoading={isLoading}>
      <Typography>{data?.recipient}</Typography>
    </FieldItem>
  );
}
