import { FilterButton, TopToolbar } from "react-admin";
import { SelectColumnsButton } from "../../../layout/SelectColumns";
import { ActionsMenu, CreateAction } from "../../../layout/actions-menu";
import { interventionColumns } from "../List";
import { SyncKizeoButton } from "./SyncKizeo";

export const InterventionActions = () => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton
      preference="interventions.list.columns"
      columns={interventionColumns}
    />
    <ActionsMenu>
      <CreateAction />
      <SyncKizeoButton />
    </ActionsMenu>
  </TopToolbar>
);
