import ExportIcon from "@mui/icons-material/GetApp";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useExport } from "../../../../actions/useExport";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";

type ExportDeviceFramesProps = {
  filter?: object;
};

export const ExportDeviceFrames = ({ filter }: ExportDeviceFramesProps) => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const { mutate } = useExport(() => {
    return dataProvider.exportFrames(filter ?? {}, startDate, endDate);
  });

  return (
    <DialogActionMenuItem
      icon={<ExportIcon />}
      label="Export Device Data"
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Export Device Data</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", padding: 3 }}>
              <TextField
                value={startDate}
                onChange={(event) => {
                  if (event.target.value) setStartDate(event.target.value);
                }}
                type="date"
                label="Start Date"
                fullWidth
                required
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", padding: 3 }}>
              <TextField
                value={endDate}
                onChange={(event) => {
                  if (event.target.value) setEndDate(event.target.value);
                }}
                type="date"
                label="End Date"
                fullWidth
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              onClick={(_, close) => {
                mutate();
                close();
              }}
            >
              Export
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
