import NotHeatingIcon from "@mui/icons-material/AcUnit";
import HeatingIcon from "@mui/icons-material/Whatshot";
import { Box, Tooltip } from "@mui/material";

export const RelayPower = ({ value }: { value: boolean }) => {
  const icon = value ? (
    <HeatingIcon color="error" />
  ) : (
    <NotHeatingIcon color="info" />
  );

  const title = value ? "Heating" : "Not heating";

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Tooltip title={title}>{icon}</Tooltip>
    </Box>
  );
};
