import {
  Datagrid,
  DateField,
  List,
  ListActions,
  ReferenceField,
  ReferenceManyCount,
  TextField,
} from "react-admin";

export const GroupList = () => (
  <List perPage={100} actions={<ListActions hasCreate />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="groupName" label="name" />
      <TextField source="groupDescription" label="description" />
      <ReferenceField source="groupOwner" reference="users" label="owner" />
      <ReferenceManyCount reference="devices" target="group" label="devices" />
      <DateField source="createdAt" label="creation date" />
    </Datagrid>
  </List>
);
