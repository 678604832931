import { DateField } from "react-admin";

export const ProjectDeploymentEndDate = ({ label }: { label?: string }) => {
  return (
    <DateField
      label={label || "Deployment End Date"}
      source="deployment.deploymentEndDate"
      variant="body1"
      emptyText="-"
    />
  );
};
