import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../../layout/actions-menu";

export const DemandResponseEntityActionsMenu = () => (
  <ActionsMenu>
    <EditAction />
    <DeleteAction />
  </ActionsMenu>
);
