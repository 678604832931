import Icon from "@mui/icons-material/WorkspacePremium";
import type { SvgIconTypeMap } from "@mui/material";
import { Box } from "@mui/material";
import type { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { Organization } from "../types";

export const ClientCategory = (
  props: DatagridOrFilterRequiredProps & DefaultComponentProps<SvgIconTypeMap>
) => {
  const record = useRecordContext<Organization>();

  if (!record?.category) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Icon htmlColor={colors[record.category]} {...props} />
    </Box>
  );
};

const colors = {
  "0_GOLD": "#D6AF36",
  "1_SILVER": "#A7A7AD",
  "2_BRONZE": "#A77044",
};
