import { Typography } from "@mui/material";
import { AppBar } from "react-admin";
import { DevProdLinker } from "./DevProdLinker";
import { ElaxSearch } from "./Search";
import logo from "./logo192.png";

export const Appbar = () => (
  <AppBar>
    <img src={logo} alt="logo" style={{ height: 40 }} />
    <Typography
      variant="h6"
      color="inherit"
      id="react-admin-title"
      sx={{ flexGrow: 1 }}
    />
    <ElaxSearch />
    <DevProdLinker />
  </AppBar>
);
