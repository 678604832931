import { Grid } from "@mui/material";
import { NoWheelNumberInput } from "../../../fields/NumberInput";
import { integer } from "../../../validations/integer";

export const SmartHPHCParameters = ({
  source,
  disabled,
}: {
  source: string;
  disabled?: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={1}
          max={99}
          label="History Prediction Weeks"
          defaultValue={defaultValues.history_prediction_weeks}
          source={`${source}.history_prediction_weeks`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Temp Base [1°C]"
          defaultValue={defaultValues.temp_base}
          source={`${source}.temp_base`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="HC min preheat [1°C]"
          defaultValue={defaultValues.HC_min_preheat}
          source={`${source}.HC_min_preheat`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="HC base temp. min [1°C]"
          defaultValue={defaultValues.HC_base_temperature_min}
          source={`${source}.HC_base_temperature_min`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="HC base temp. max [1°C]"
          defaultValue={defaultValues.HC_base_temperature_max}
          source={`${source}.HC_base_temperature_max`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="HC max preheat [1°C]"
          defaultValue={defaultValues.HC_max_preheat}
          source={`${source}.HC_max_preheat`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={1}
          step={0.1}
          label="HP requiered quantile"
          defaultValue={defaultValues.HP_required_quantile}
          source={`${source}.HP_required_quantile`}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Hysteresis HC base temp. [1°C]"
          defaultValue={defaultValues.hysteresis_hc_base_temp}
          source={`${source}.hysteresis_hc_base_temp`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Hysteresis HC HC_max_preheat [1°C]"
          defaultValue={defaultValues.hysteresis_hc_preheat}
          source={`${source}.hysteresis_hc_preheat`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export const SmartHPHCParamsToString = ({
  HC_min_preheat,
  temp_base,
  ...other
}: any) =>
  `${temp_base ?? defaultValues.temp_base}°C/${
    HC_min_preheat ?? defaultValues.HC_min_preheat
  }°C ${Object.entries(other)
    .filter(
      ([key, value]) =>
        defaultValues[key as keyof typeof defaultValues] !== value
    )
    .map(([key, value]) => `${key}=${value}`)
    .join(", ")}`;

const defaultValues = {
  temp_base: 41,
  history_prediction_weeks: 4,
  HC_base_temperature_min: 7,
  HC_base_temperature_max: 14,
  HC_min_preheat: 47,
  HC_max_preheat: 63,
  HP_required_quantile: 0.8,
  hysteresis_hc_base_temp: 10,
  hysteresis_hc_preheat: 10,
};
