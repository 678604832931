import { Box } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useRecordContext } from "react-admin";
import type { Device } from "../../types";
import DeviceInfoList from "./device/DeviceInfoList";
import FavoritesInfoList, {
  FavoritesReadyProvider,
} from "./favorite/FavoritesInfoList";
import HeaterInfoList from "./heater/HeaterInfoList";
import HousingInfoList from "./housing/HousingInfoList";
import IncidentsInfoList from "./incidents/IncidentsInfoList";

export default function Aside() {
  const record = useRecordContext<Device>();

  if (!record) return null;

  return (
    <FavoritesReadyProvider>
      <Box
        sx={{
          width: "20vw",
          marginLeft: 1,
          marginTop: 1,
        }}
      >
        <IfCanAccess resource="incidents" action="read">
          <IncidentsInfoList />
        </IfCanAccess>

        <FavoritesInfoList />

        <DeviceInfoList />

        <IfCanAccess resource="housings" action="read">
          <HousingInfoList />
        </IfCanAccess>

        <IfCanAccess resource="heaters" action="read">
          <HeaterInfoList />
        </IfCanAccess>
      </Box>
    </FavoritesReadyProvider>
  );
}
