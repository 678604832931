import { mapValues, max, min } from "lodash";

export const addHeatmapColors = (
  data: Record<string, { value: number }>,
  palette: string[]
) => {
  const minValue =
    min(Object.values(data).map((datum) => datum.value ?? 0)) ?? 0;
  const maxValue =
    max(Object.values(data).map((datum) => datum.value ?? 0)) ?? 1;

  return mapValues(data, (datum) => {
    const colorIndex = Math.min(
      Math.floor(
        ((datum.value - minValue) / (maxValue - minValue)) *
          (palette.length + 1)
      ),
      palette.length - 1
    );

    return {
      ...datum,
      color: palette[colorIndex],
    };
  });
};
