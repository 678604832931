import { Divider } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useRecordContext } from "react-admin";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../../layout/actions-menu";
import { AssignFirmware } from "../../devices/bulk-actions/AssignFirmware";
import { ChangePilotConfig } from "../../devices/bulk-actions/edit-device-config";
import { ExportDeviceFrames } from "../../devices/show/actions/ExportDeviceFrames";
import type { Group } from "../types";

export const GroupActions = () => {
  const record = useRecordContext<Group>();
  return (
    <ActionsMenu>
      <EditAction />
      <DeleteAction />
      <Divider />
      <ExportDeviceFrames filter={{ group: record?.id }} />
      <IfCanAccess resource="groups.actions" action="use">
        <ConsumptionExport filter={{ group: record?.id }} />
      </IfCanAccess>
      <Divider />
      <ChangePilotConfig filter={{ group: record?.id }} />
      <IfCanAccess resource="groups.actions" action="use">
        <AssignFirmware filter={{ group: record?.id }} />
      </IfCanAccess>
    </ActionsMenu>
  );
};
