import Icon from "@mui/icons-material/DoDisturb";
import type { SvgIconTypeMap } from "@mui/material";
import type { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { DemandResponse } from "../types";

type CancelledFieldProps = DefaultComponentProps<SvgIconTypeMap> &
  DatagridOrFilterRequiredProps;

export const CancelledField = (props: CancelledFieldProps) => {
  const record = useRecordContext<DemandResponse>();

  if (!record?.cancelledAt) {
    return null;
  }

  return <Icon color="error" {...props} />;
};
