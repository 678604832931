import TimerIcon from "@mui/icons-material/Timer";
import { Box } from "@mui/material";
import type { DatagridOrFilterRequiredProps } from "../../../../layout/types";

export const OngoingFilter = ({ label }: DatagridOrFilterRequiredProps) => (
  <Box
    sx={{
      backgroundColor: "#ff980052",
      color: "#ff9800",
      borderRadius: 1,
      textAlign: "center",
      fontWeight: "bold",
      paddingX: 1,
      paddingY: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "min-content",
      marginY: "12px",
    }}
    title={label}
  >
    <TimerIcon fontSize="small" />
    ONGOING
  </Box>
);
