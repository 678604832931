import DeviceTagIcon from "@mui/icons-material/LocalOffer";
import DeviceTagList from "./List";

export const deviceTagResource = {
  list: DeviceTagList,
  options: { label: "Device Tags" },
  icon: DeviceTagIcon,
  recordRepresentation: "name",
  hasShow: false,
  hasCreate: false,
  hasEdit: false,
};

export { default as DeviceTagIcon } from "@mui/icons-material/LocalOffer";
