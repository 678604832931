import { Box, Tooltip } from "@mui/material";
import { orange, purple, red } from "@mui/material/colors";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { AlertHistory } from "../types";
import { AlertCode } from "../types";

export const AlertCodeField = ({ label }: DatagridOrFilterRequiredProps) => {
  const record = useRecordContext<AlertHistory>();

  if (!record) {
    return null;
  }

  const data = codeToLabel[record.code];

  const component = (
    <Box
      sx={{
        backgroundColor: data ? data.color + "52" : undefined,
        color: data ? data.color : undefined,
        borderRadius: 1,
        textAlign: "center",
        fontWeight: "bold",
        paddingX: 1,
        paddingY: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
        whiteSpace: "nowrap",
        opacity: record.timeEnd ? 0.5 : 1,
      }}
      title={label}
    >
      {data?.label || record.code}
    </Box>
  );

  if (record.message) {
    return <Tooltip title={record.message}>{component}</Tooltip>;
  }

  return component;
};

export const codeToLabel: Record<
  AlertCode,
  { label: string; color: string; usedInIncidents?: boolean }
> = {
  [AlertCode.TARGET_TEMPERATURE]: {
    label: "Target Temperature",
    color: "#2196f3",
  },
  [AlertCode.LOST_CONNECTION]: {
    label: "Lost Connection",
    color: "#607d8b",
    usedInIncidents: true,
  },
  [AlertCode.LOST_POWER]: {
    label: "Lost Power",
    color: "#9e9e9e",
    usedInIncidents: true,
  },
  [AlertCode.NO_HEATING]: {
    label: "Heating Failure",
    color: "#673ab7",
    usedInIncidents: true,
  },
  [AlertCode.DEPRECATED_BROKEN_PROBE]: {
    label: "Broken Probe",
    color: "#03a9f4",
    usedInIncidents: false,
  },
  [AlertCode.BROKEN_PROBE_LOW]: {
    label: "Broken Probe (T°C too low)",
    color: "#03a9f4",
    usedInIncidents: true,
  },
  [AlertCode.BROKEN_PROBE_HIGH]: {
    label: "Broken Probe (T°C too high)",
    color: "#03a9f4",
    usedInIncidents: true,
  },
  [AlertCode.LEGIONELLOSE_SCHEDULE]: {
    label: "Legionellose Schedule",
    color: "#4caf50",
  },
  [AlertCode.LEGIONELLOSE_SETPOINT]: {
    label: "Legionellose SetPoint",
    color: "#3f51b5",
  },
  [AlertCode.THERMOSTAT]: {
    label: "Thermostat mécanique trop bas",
    color: "#00bcd4",
    usedInIncidents: true,
  },
  [AlertCode.ELECTRICAL_ISSUE]: {
    label: "Problème électrique partiel",
    color: orange["500"],
    usedInIncidents: true,
  },
  [AlertCode.RELAY_POWER_KO]: {
    label: "Relay Power KO",
    color: orange["500"],
    usedInIncidents: true,
  },
  [AlertCode.TRIGGER_ALERT]: { label: "Trigger Alert", color: "#e91e63" },
  legionellose: {
    label: "Legionellose",
    color: "#795548",
  },
  [AlertCode.VALVE_OBSTRUCTION]: {
    label: "Valve Obstruction",
    color: "#00bcd4",
  },
  [AlertCode.HEATING_ALERT]: { label: "Heating Alert", color: "#009688" },
  [AlertCode.VALVE_LEAKAGE]: { label: "Valve Leakage", color: "#607d8b" },
  [AlertCode.WATER_LEAKAGE]: { label: "Water Leakage", color: "#607d8b" },
  [AlertCode.WATER_LEAKAGE_1]: { label: "Water Leakage 1", color: "#607d8b" },
  [AlertCode.WATER_LEAKAGE_2]: { label: "Water Leakage 2", color: "#9e9e9e" },
  [AlertCode.CONTRACT_CHANGE]: {
    label: "Detected Mode Change",
    color: "#3f51b5",
  },
  [AlertCode.HP_HC_NOT_FOUND_1]: { label: "HP/HC not found", color: "#e91e63" },
  [AlertCode.HP_HC_NOT_FOUND_2]: { label: "HP/HC not found", color: "#e91e63" },
  [AlertCode.COOLING_SPEED]: { label: "Cooling Speed", color: "#009688" },
  [AlertCode.LOW_TEMPERATURE]: { label: "Low temperature", color: "#009688" },
  [AlertCode.SWITCH]: {
    label: "Bypass device button switched on",
    color: "#03a9f4",
    usedInIncidents: true,
  },
  [AlertCode.TOO_MANY_REBOOTS]: {
    label: "Too many Reboots",
    color: red["500"],
    usedInIncidents: true,
  },
  [AlertCode.DISCOMFORT_ALERT]: {
    label: "Discomfort",
    color: purple["500"],
    usedInIncidents: false,
  },
};
