import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/LibraryAdd";
import { Box, Grid, Typography } from "@mui/material";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { omit } from "lodash";
import type { ReactNode } from "react";
import {
  Datagrid,
  DateField,
  Loading,
  ReferenceManyField,
  TextField,
  WithRecord,
  useListContext,
  useRecordContext,
  useRefresh,
} from "react-admin";
import ReactMarkdown from "react-markdown";
import type { DatagridOrFilterRequiredProps } from "../../../../../layout/types";
import { DeviceConfigEditionForm } from "../../../../device-config/Edit";
import { ParametersField } from "../../../../device-config/parameters";
import {
  MODES_COMPATIBLE_WITH_PERSONAS,
  Mode,
} from "../../../../device-config/types";
import type { Device } from "../../../types";
import { CustomPilotSwitcher } from "./CustomPilot";
import { OffPeakHours } from "./OffPeakHours";
import { ScheduleChart } from "./Schedule";
import { ScheduleList } from "./ScheduleList";
import { SelectPersona } from "./SelectPersona";

dayjs.extend(weekday);

export const Pilot = () => {
  return (
    <Grid item xs={12}>
      <ReferenceManyField
        reference="device-configs"
        target="deviceId"
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={1000}
      >
        <DeviceConfigList />
      </ReferenceManyField>
    </Grid>
  );
};

const DeviceConfigList = () => {
  const { data } = useListContext();
  const record = useRecordContext<Device>();
  const refresh = useRefresh();

  if (!data || !record) {
    return <Loading />;
  }

  const history = data;
  const currentConfig = history[0];

  if (!currentConfig) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4">No pilot mode yet</Typography>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4">
          Device is in <b>{currentConfig.mode}</b> mode
          <CustomPilotSwitcher />
        </Typography>
        <Box display="flex" justifyContent="center">
          {MODES_COMPATIBLE_WITH_PERSONAS.includes(currentConfig.mode) && (
            <>
              <SelectPersona device={record} />
              <Box display="flex" alignItems="center">
                <Typography variant="caption">~ OR ~</Typography>
              </Box>
            </>
          )}
          <CreateInDialogButton
            record={{ deviceId: currentConfig.deviceId }}
            label="Change Mode"
            title="Change Pilot Mode"
            ButtonProps={{ variant: "outlined", sx: { margin: 1, width: 200 } }}
            icon={<EditIcon />}
            maxWidth="lg"
            mutationOptions={{ onSettled: () => refresh() }}
          >
            <DeviceConfigEditionForm device={record} />
          </CreateInDialogButton>
        </Box>
      </div>

      <Typography variant="body1">Off-Peak hours planning</Typography>

      <OffPeakHours offPeakHours={record.HoraireHCHP || []} />

      {history.length > 0 && (
        <>
          <Typography variant="h6">History</Typography>
          <Datagrid
            bulkActionButtons={false}
            expand={<ConfigDetails />}
            isRowExpandable={(record) =>
              record.notes || record.parameters.weeklyInstructions
            }
            data={history}
          >
            <TextField label="Mode" source="mode" />
            <DateField label="Date" source="createdAt" showTime />

            <CenterField label="Parameters">
              <ParametersField
                isHCHP={record.HoraireHCHP?.length > 0}
                majorHWVersion={record.majorHWVersion}
              />
            </CenterField>

            <WithRecord
              render={(config) => (
                <CreateInDialogButton
                  record={omit(config, "notes")}
                  label="Copy"
                  title="Change Pilot Mode"
                  ButtonProps={{ variant: "contained" }}
                  icon={<CopyIcon />}
                  maxWidth="lg"
                  mutationOptions={{ onSettled: () => refresh() }}
                >
                  <DeviceConfigEditionForm device={record} />
                </CreateInDialogButton>
              )}
            />
          </Datagrid>
        </>
      )}
    </>
  );
};

type CenterFieldProps = { children: ReactNode } & DatagridOrFilterRequiredProps;

const CenterField = ({ children }: CenterFieldProps) => (
  <Box textAlign="center">{children}</Box>
);

const ConfigDetails = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <>
      {record.mode === Mode.SCHEDULE && (
        <>
          <ScheduleChart
            weeklyInstructions={record.parameters.weeklyInstructions || []}
          />
          <ScheduleList
            instructions={record.parameters.weeklyInstructions || []}
          />
        </>
      )}
      <ReactMarkdown>{record.notes}</ReactMarkdown>
    </>
  );
};
