import { SingleFieldList } from "react-admin";
import type { ChipFieldProps } from "react-admin";
import type { Except } from "type-fest";
import TagField from "./TagField";

type TagArrayFieldProps = {
  label?: string;
  TagFieldProps?: Except<ChipFieldProps, "source">;
};

export default function TagArrayField({ TagFieldProps }: TagArrayFieldProps) {
  return (
    <SingleFieldList linkType={false}>
      <TagField {...TagFieldProps} />
    </SingleFieldList>
  );
}
