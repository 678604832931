import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListActions,
  ReferenceField,
  TextField,
} from "react-admin";
import type { ResidenceContact } from "./types";

export const ResidenceContactList = () => {
  return (
    <List perPage={200} actions={<ListActions hasCreate />}>
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="Organization"
          source="organizationId"
          reference="organizations"
        >
          <TextField label="Name" source="name" />
        </ReferenceField>
        <TextField label="Role" source="type" />
        <TextField label="First Name" source="firsName" />
        <TextField label="Last Name" source="lastName" />
        <TextField label="Company" source="company" />
        <TextField label="Email" source="email" />
        <TextField label="Phone" source="phone" />
        <FunctionField<ResidenceContact>
          label="Residences"
          render={(record) => record.residences.length}
        />
        <DateField label="Created At" source="createdAt" />
        <DateField label="Updated At" source="updatedAt" />
      </Datagrid>
    </List>
  );
};
