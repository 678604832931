import type { ReferenceOneFieldProps } from "react-admin";
import { ReferenceOneField, WithRecord } from "react-admin";

export const SafeReferenceOneField = (props: ReferenceOneFieldProps) => {
  return (
    <WithRecord
      render={(record) =>
        props.source && record[props.source] ? (
          <ReferenceOneField {...props} />
        ) : (
          <></>
        )
      }
    />
  );
};
