import SyncIcon from "@mui/icons-material/Sync";
import { useNotify } from "react-admin";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const SyncKizeoButton = () => {
  const notify = useNotify();
  return (
    <ActionMenuItem
      label="Sync Kizeo"
      icon={<SyncIcon />}
      onClick={async () => {
        await dataProvider.syncAllKizeo();
        notify("Synchronisation triggered");
      }}
    />
  );
};
