import Compress from "@mui/icons-material/Compress";
import Expand from "@mui/icons-material/Expand";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Grid, IconButton } from "@mui/material";
import { useRecordContext, useStore } from "react-admin";
import { SeriesName } from "../../../types";
import type { Device } from "../../../types";
import { EnergyChart } from "./Energy";
import { TemperatureChart } from "./charts/Temperature";
import { useChartParams } from "./charts/useChartParams";
import { AllFrames } from "./frames";
import { DeviceTimeNavigator } from "./time-navigation";

const widthSettings = [0, 4, 6, 8, 12];
const maxLeft = 0;
const maxRight = widthSettings.length - 1;
const defaultWidth = 3;

const useWidthSettings = () => useStore("frames.width", defaultWidth);

export const DataTab = () => {
  const { dateRange, displayedSeries, handleParamsOptions, cursor } =
    useChartParams([
      SeriesName.T1_AVG,
      SeriesName.T2_AVG,
      SeriesName.SCHEDULE,
      SeriesName.CONFIG_ACK,
      SeriesName.CONFIG,
      SeriesName.ENERGY_CUMULATIVE,
      SeriesName.ENERGY,
      SeriesName.HISTORY,
    ]);
  const record = useRecordContext<Device>();
  const [width, setWidth] = useWidthSettings();

  if (!record) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          backgroundColor: "#E7EBF0",
          borderRadius: 1,
        }}
        padding={1}
      >
        <DeviceTimeNavigator />
      </Box>

      <Grid container>
        {width !== maxLeft && (
          <Grid item sm={12} md={12} lg={widthSettings[width]}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="small"
                onClick={() =>
                  setWidth((w) => (w === maxRight ? defaultWidth : maxRight))
                }
              >
                {width === maxRight ? (
                  <Compress sx={{ transform: "rotate(90deg)" }} />
                ) : (
                  <Expand sx={{ transform: "rotate(90deg)" }} />
                )}
              </IconButton>
              <IconButton onClick={() => setWidth((w) => w - 1)} size="small">
                <ExpandMore sx={{ transform: "rotate(90deg)" }} />
              </IconButton>
            </Box>

            <TemperatureChart
              device={record}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              displayedSeries={displayedSeries}
              paramsOptions={handleParamsOptions}
              cursor={cursor}
            />
            <EnergyChart
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              paramsOptions={handleParamsOptions}
            />
          </Grid>
        )}
        {width !== maxRight && (
          <Grid item sm={12} md={12} lg={12 - widthSettings[width]}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton onClick={() => setWidth((w) => w + 1)} size="small">
                <ExpandMore sx={{ transform: "rotate(-90deg)" }} />
              </IconButton>
              <IconButton
                onClick={() =>
                  setWidth((w) => (w === maxLeft ? defaultWidth : maxLeft))
                }
                size="small"
              >
                {width === 0 ? (
                  <Compress sx={{ transform: "rotate(90deg)" }} />
                ) : (
                  <Expand sx={{ transform: "rotate(90deg)" }} />
                )}
              </IconButton>
            </Box>

            <AllFrames cursor={cursor} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
