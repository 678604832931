import RebootIcon from "@mui/icons-material/PowerSettingsNew";
import ConfigFrameIcon from "@mui/icons-material/Settings";
import type { ConfigAckFrame } from "../../../../../frames/config-ack/type";
import { IconInfo } from "./shared";
import type { FrameSpecs } from "./shared";

export const configAckFrameSpecs: FrameSpecs<ConfigAckFrame> = {
  icon: <ConfigFrameIcon sx={{ color: "#4caf50" }} />,
  data: [
    {
      key: "SetPoint" as const,
      label: (frame) =>
        frame.set_point === 90 ? (
          <IconInfo icon={<RebootIcon color="error" />}>Reboot</IconInfo>
        ) : (
          `${frame.set_point} ±${(frame.hysteresis / 10).toFixed(1)}°C`
        ),
    },
  ],
};
