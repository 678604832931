import SyncIcon from "@mui/icons-material/Sync";
import { useNotify } from "react-admin";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { axiosClient } from "../../../providers/data";

export const SyncDealsButton = () => {
  const notify = useNotify();

  const syncDeals = () =>
    axiosClient
      .put(`/projects/sync`)
      .then(() => notify("Synchronisation in progress..."))
      .catch((error) =>
        notify(`Synchronisation error: ${error}`, { type: "error" })
      );

  return (
    <ActionMenuItem
      label="Sync Pipedrive"
      icon={<SyncIcon />}
      onClick={syncDeals}
    />
  );
};
