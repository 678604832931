import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { SavingsV2Tag } from "../../../display/SavingsV2Tag";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { granularities, useGranularity } from "./useGranularity";

dayjs.extend(duration);
dayjs.extend(relativeTime);

type SavingsAverageGraphProps = {
  filter: any;
};

export const SavingsAverageGraph = ({ filter }: SavingsAverageGraphProps) => {
  const [granularity, setGranularity] = useGranularity();

  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getDevicesSavingsStats(
        filter,
        granularity.key,
        ["euros"],
        "avg"
      ),
    queryKey: [
      `devices/savings/average`,
      filter,
      granularity.key,
      "euros",
      "avg",
    ],
    enabled: !!filter,
  });

  const chartOptions: Highcharts.Options = {
    title: {
      text: "Moyenne des économies générées " + granularity.label,
    },
    plotOptions: {
      spline: {
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: "€",
        },
      },
    ],
  };

  return (
    <Paper elevation={1} sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          right: 40,
          top: 5,
          zIndex: 10,
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <SavingsV2Tag />
        <FormControl size="small" className="no-print">
          <InputLabel>Granularité</InputLabel>
          <Select
            label="Granularité"
            onChange={(event) => {
              setGranularity(
                granularities.find((g) => g.value === event.target.value)!
              );
            }}
            value={granularity.value}
          >
            {granularities.map((g) => (
              <MenuItem key={g.value} value={g.value}>
                {g.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Chart
        isLoading={!data}
        options={{
          ...chartOptions,
          subtitle: {
            text:
              data &&
              `Based on v2 only - Last Update: ${dayjs().to(
                data.lastUpdateDate
              )}`,
          },
          xAxis: {
            categories: data?.data.map((d) => d.key),
            crosshair: true,
          },
          series: data && [
            {
              name: "€",
              type: "spline",
              color: "#2196f3",
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 2,
                pointFormat: `{point.y}€ ({point.custom.deviceCount} devices)`,
              },
              data: data.data.map(
                (point) =>
                  ({
                    y: point.savings_euros_avg,
                    custom: {
                      deviceCount: point.device_count,
                    },
                  } as any)
              ),
            },
          ],
        }}
      />
    </Paper>
  );
};
