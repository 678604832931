import { Divider, Grid } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { NoWheelNumberInput } from "../../fields/NumberInput";
import { DeploymentLetterForm } from "./edit/DeploymentLetterForm";
import { InstallationAppointmentSettingsForm } from "./edit/InstallationAppointementSettingsForm";
import { ProjectStageField } from "./fields/Status";

export const ProjectEdit = () => (
  <Edit mutationMode="pessimistic" redirect={false}>
    <EditionForm />
  </Edit>
);

export const ProjectCreate = () => (
  <Create redirect="show">
    <EditionForm />
  </Create>
);

const EditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProjectStageField />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Name"
            source="name"
            required={true}
            fullWidth
            disabled
            helperText="From pipedrive"
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput
            label="Organization"
            source="organizationId"
            reference="organizations"
            required={true}
            fullWidth
          >
            <AutocompleteInput
              optionText="name"
              disabled
              helperText="From pipedrive"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <NoWheelNumberInput
            label="Pipedrive ID"
            source="pipedriveId"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            source="energyCustomer"
            label="Energy Customer"
            choices={["landlord", "end-user"].map((value) => ({
              id: value,
              name: value,
            }))}
            defaultValue="end-user"
            fullWidth
            helperText="Used to hide savings in QR Code App when landlord is selected"
          />
        </Grid>
        <Grid item xs={12}>
          <NoWheelNumberInput
            label="Quoted Devices"
            source="quotedDeviceCount"
            fullWidth
            disabled
            helperText="From pipedrive"
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput
            reference="maintenance-providers"
            source="deploymentMaintenanceProviderId"
            fullWidth
            perPage={1000}
          >
            <AutocompleteInput
              label="Deployment Maintenance Provider"
              optionText="name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Listing spreadsheet ID"
            source="listingSpreadsheetId"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Notes"
            source="notes"
            required={false}
            helperText="Support markdown (title #, lists: -, bold **, italic *, etc)"
            fullWidth
            multiline
            minRows={8}
          />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput source="isFirstRun" label="Premier passage" />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput
            source="isNewInterventionAfterAbsenceEnabled"
            label="Activer la génération d'une nouvelle intervention après une absence lors d'un rdv prévu"
          />
        </Grid>
      </Grid>

      <Divider flexItem sx={{ marginY: 4 }} />

      <InstallationAppointmentSettingsForm />

      <Divider flexItem sx={{ marginY: 4 }} />

      <DeploymentLetterForm />
    </SimpleForm>
  );
};
