import Icon from "@mui/icons-material/DoDisturb";
import { useRecordContext } from "react-admin";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { useUpdateWithNotification } from "../../../providers/data-hooks/useUpdateWithNotification";
import type { DemandResponse } from "../types";

export const CancelAction = () => {
  const record = useRecordContext<DemandResponse>();

  const [update] = useUpdateWithNotification<DemandResponse>(
    "demand-responses",
    record?.id,
    { cancelledAt: new Date() }
  );

  return (
    <ActionMenuItem onClick={() => update()} label="Cancel" icon={<Icon />} />
  );
};
