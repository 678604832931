import type { SvgIconProps, TypographyProps } from "@mui/material";
import { Divider, Stack, Typography, colors } from "@mui/material";
import type { Variant } from "@mui/material/styles/createTypography";
import { cloneElement } from "react";
import type { ReactElement } from "react";
import { LinearProgress, useRecordContext } from "react-admin";
import type { FieldProps } from "react-admin";
import { AttributeHistory } from "../fields/AttributeHistory";
import { useGetCount } from "../providers/data-hooks/useGetCount";

export const FieldItem = ({
  label,
  icon,
  isLoading,
  children,
  showHistory,
  onIconClick,
}: {
  label: string;
  icon?: ReactElement;
  onIconClick?: () => void;
  showHistory?: boolean;
  isLoading?: boolean;
  children: ReactElement<{
    source: string;
    variant?: Variant;
    emptyText?: string;
  }>;
}) => {
  const record = useRecordContext();
  const count = useGetCount(
    "attribute-history",
    {
      recordId: record?.id,
      attribute: children.props.source,
    },
    { enabled: !!record && showHistory }
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1} marginBottom={1}>
      {icon &&
        cloneElement<SvgIconProps>(icon, {
          htmlColor: onIconClick ? colors.blue[600] : colors.grey[700],
          fontSize: "medium",
          cursor: onIconClick ? "pointer" : undefined,
          onClick: onIconClick,
        })}
      <Divider orientation="vertical" flexItem />
      <Stack direction="column">
        <Typography
          variant="overline"
          lineHeight={1.5}
          fontWeight="bold"
          color={colors.grey[700]}
        >
          {label}{" "}
          {count ? (
            <AttributeHistory
              attribute={children.props.source}
              sx={{ fontSize: 17, padding: 0 }}
            >
              {cloneElement(children, {
                source: "value",
                variant: "body1",
                emptyText: "-",
              })}
            </AttributeHistory>
          ) : null}
        </Typography>
        <Stack direction="column">
          {isLoading ? (
            <LinearProgress />
          ) : (
            cloneElement<TypographyProps & FieldProps>(children, {
              variant: "body1",
              emptyText: "-",
            })
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
