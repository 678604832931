import type { Intervention } from "../../../../intervention/types";

type BlackboxDetailsProps = {
  intervention: Intervention;
};

export const BlackboxDetails = ({ intervention }: BlackboxDetailsProps) => {
  const data = intervention.data;

  if (!data) {
    return null;
  }

  return (
    <ul>
      <li>Sonde 1 : {data.probe1Measure}</li>
      <li>Sonde 2 : {data.probe2Measure}</li>
      <li>Energimètre : {data.energyMeterMeasure}</li>
      <li>Modèle : {data.model}</li>
      <li>
        Relais 1 :{" "}
        {`${data.relay1Control} ${data.heatingOrDhwCircuitNumberRelay1}`}
      </li>
      <li>
        Relais 2 :{" "}
        {`${data.relay2Control} ${data.heatingOrDhwCircuitNumberRelay2}`}
      </li>
    </ul>
  );
};
