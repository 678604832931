import { IfCanAccess } from "@react-admin/ra-rbac";
import { FunctionField, ReferenceArrayField, TextField } from "react-admin";
import { CopyRecordFieldValueToClipboard } from "../../../../../actions/CopyRecordFieldValueToClipboard";
import TagArrayField from "../../../../device-tags/fields/TagArrayField";
import BarcodeField from "../../../fields/BarcodeField";
import BypassedRemoteSwitchField from "../../../fields/BypassedRemoteSwitchField";
import { CertificateField } from "../../../fields/CertificateField";
import { ContractTypeField } from "../../../fields/ContractTypeField";
import DeviceModeField from "../../../fields/DeviceModeField";
import FirmwareVersionField from "../../../fields/FirmwareVersionField";
import QRCodeField from "../../../fields/QRCodeField";
import { DeviceStatusField } from "../../../fields/Status";
import type { Device } from "../../../types";
import InfoList from "../InfoList";
import EditTagsDialog from "./EditTagsDialog";

export default function DeviceInfoList() {
  return (
    <InfoList label="Device">
      <BypassedRemoteSwitchField />

      <InfoList.Item label="IMEI" secondaryAction={<BarcodeField />}>
        <TextField source="IMEI" variant="body1" />
      </InfoList.Item>

      <InfoList.Item label="Status">
        <DeviceStatusField />
      </InfoList.Item>

      <InfoList.Item label="Mode">
        <DeviceModeField />
      </InfoList.Item>

      <IfCanAccess resource="device-tags" action="read">
        <InfoList.Item label="Tags" secondaryAction={<EditTagsDialog />}>
          <ReferenceArrayField
            reference="device-tags"
            source="tags"
            sx={{ marginRight: 2 }}
          >
            <TagArrayField TagFieldProps={{ size: "small" }} />
          </ReferenceArrayField>
        </InfoList.Item>
      </IfCanAccess>

      <InfoList.Collapsible>
        <InfoList.Item label="Name">
          <TextField source="name" variant="body1" />
        </InfoList.Item>
        <InfoList.Item label="Hardware version">
          <TextField source="VersionHardware" variant="body1" />
        </InfoList.Item>
        <InfoList.Item label="Firmware version">
          <FirmwareVersionField />
        </InfoList.Item>

        <InfoList.Item label="SIM ICC ID">
          <TextField source="SimICCId" variant="body1" />
        </InfoList.Item>

        <InfoList.Item label="Telemetry">
          <FunctionField<Device>
            render={(record) => `${record.Telemetry} minutes`}
            variant="body1"
          />
        </InfoList.Item>

        <InfoList.Item label="Contract Type">
          <ContractTypeField />
        </InfoList.Item>

        <InfoList.Item label="Certificate">
          <CertificateField />
        </InfoList.Item>

        <InfoList.Item
          label="Resident App"
          secondaryAction={
            <>
              <QRCodeField />
              <CopyRecordFieldValueToClipboard<Device>
                formatValue={(record) => record.residentAppUrl}
              />
            </>
          }
        />
      </InfoList.Collapsible>
    </InfoList>
  );
}
