import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { IconButton, Tooltip } from "@mui/material";
import { useNotify, useRecordContext, useUpdate } from "react-admin";
import type { Device } from "../../../types";

export const CustomPilotSwitcher = () => {
  const record = useRecordContext<Device>();
  const notify = useNotify();

  const [update, { isLoading }] = useUpdate(
    "devices",
    {
      id: record?.id,
      data: { id: record?.id, custom_pilot: !record?.custom_pilot },
      previousData: record,
    },
    {
      onError: (error: any) => {
        notify(`Failed to update device: ${error.message}`, { type: "error" });
      },
    }
  );

  if (!record) {
    return null;
  }

  return (
    <Tooltip
      title={`${
        record.custom_pilot ? "Allow" : "Disallow"
      } group edition to override this mode`}
    >
      <IconButton
        onClick={() => update()}
        sx={{ opacity: isLoading ? 0.5 : 1 }}
        disabled={isLoading}
      >
        {record.custom_pilot ? <LockIcon /> : <LockOpenIcon />}
      </IconButton>
    </Tooltip>
  );
};
