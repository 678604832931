import InterventionIcon from "@mui/icons-material/Build";
import { InterventionCreate, InterventionEdit } from "./Edit";
import { InterventionList } from "./List";
import { InterventionShow } from "./Show";

export const interventionResource = {
  icon: InterventionIcon,
  list: InterventionList,
  recordRepresentation: "externalId",
  show: InterventionShow,
  edit: InterventionEdit,
  create: InterventionCreate,
};

export { default as InterventionIcon } from "@mui/icons-material/Build";
