import FirmwareIcon from "@mui/icons-material/Dvr";
import HardwareIcon from "@mui/icons-material/Memory";
import StatusFrameIcon from "@mui/icons-material/MonitorHeart";
import PowerSupplyIcon from "@mui/icons-material/PowerSettingsNew";
import SecurityIcon from "@mui/icons-material/Security";
import NetworkLossIcon from "@mui/icons-material/SignalCellularNodata";
import { Box, Tooltip } from "@mui/material";
import { RelayPower } from "../../../../../frames/status/fields/RelayPower";
import { RelayState } from "../../../../../frames/status/fields/RelayState";
import type { StatusFrame } from "../../../../../frames/status/type";
import { Flag, IconInfo } from "./shared";
import type { FrameSpecs } from "./shared";

export const statusFrameSpecs: FrameSpecs<StatusFrame> = {
  icon: <StatusFrameIcon sx={{ color: "#f44336" }} />,
  data: [
    {
      key: "RelayState" as const,
      label: (frame) => (
        <Tooltip
          title={`RelayState = ${frame.relay_state ? "closed" : "open"}`}
        >
          <Box sx={{ width: "40px", marginX: 1 }}>
            <RelayState value={frame.relay_state} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: "RelayPower" as const,
      label: (frame) => (
        <Box sx={{ marginX: 1 }}>
          <RelayPower value={frame.relay_power} />
        </Box>
      ),
    },
    {
      key: "HwVer" as const,
      label: (frame) => (
        <IconInfo icon={<HardwareIcon color="info" />}>{frame.hw_ver}</IconInfo>
      ),
    },
    {
      key: "FwVerFirmware" as const,
      label: (frame) => (
        <IconInfo icon={<FirmwareIcon color="success" />}>
          {frame.fw_ver}
        </IconInfo>
      ),
    },
    {
      key: "Switch" as const,
      label: (frame) => <Flag value={frame.switch}>Bypass mode</Flag>,
    },
    {
      key: "PowerSupply" as const,
      label: (frame) =>
        frame.power_supply ? (
          <Tooltip title="Power supply lost">
            <PowerSupplyIcon color="error" />
          </Tooltip>
        ) : null,
    },
    {
      key: "CellularNet" as const,
      label: (frame) =>
        frame.cellular_net ? (
          <>
            <Tooltip title={`Network lost for ${frame.cellular_net}s`}>
              <NetworkLossIcon color="action" />
            </Tooltip>
            {`${frame.cellular_net}s`}
          </>
        ) : null,
    },
    {
      key: "CellErrType" as const,
      label: (frame) =>
        getCellErrLabel(frame.cell_err_type) ? (
          <Flag value={true}>{getCellErrLabel(frame.cell_err_type)}</Flag>
        ) : null,
    },
    {
      key: "Sensor1" as const,
      label: (frame) => (
        <Flag value={frame.sensor1} title="Sensor 1 defective">
          Sensor 1
        </Flag>
      ),
    },
    {
      key: "Sensor2" as const,
      label: (frame) => (
        <Flag value={frame.sensor2} title="Sensor 2 defective">
          Sensor 2
        </Flag>
      ),
    },
    {
      key: "RelayVoltage" as const,
      label: (frame) => (
        <Flag value={frame.relay_voltage} title="Relay defective">
          Relay
        </Flag>
      ),
    },
    {
      key: "Stpm32Defect" as const,
      label: (frame) => (
        <Flag value={frame.stpm32_defect} title="STPM32 defective">
          STPM32
        </Flag>
      ),
    },
    {
      key: "Fota" as const,
      label: (frame) => (
        <Flag value={frame.fota} title="Fota defective">
          Fota
        </Flag>
      ),
    },
    {
      key: "StpmFreeze" as const,
      label: (frame) => <Flag value={frame.stpm_freeze}>StpmFreeze</Flag>,
    },
    {
      key: "StpmRestartVC" as const,
      label: (frame) => (
        <Flag value={frame.stpm_restart_vc}>StpmRestartVC</Flag>
      ),
    },
    {
      key: "StpmRestartEnergy" as const,
      label: (frame) => (
        <Flag value={frame.stpm_restart_energy}>StpmRestartEnergy</Flag>
      ),
    },
    {
      key: "StpmRestartPower" as const,
      label: (frame) => (
        <Flag value={frame.stpm_restart_power}>StpmRestartPower</Flag>
      ),
    },
    {
      key: "StpmRestartEnergyDecrease" as const,
      label: (frame) => (
        <Flag value={frame.stpm_restart_energy_decrease}>
          StpmRestartEnergyDecrease
        </Flag>
      ),
    },
    {
      key: "FwCorrupt" as const,
      label: (frame) => (
        <Flag value={frame.fw_corrupt}>New firmware corrupted</Flag>
      ),
    },
    {
      key: "PowerSupplyLost" as const,
      label: (frame) => (
        <Flag value={frame.power_supply_lost}>Regular power lost</Flag>
      ),
    },
    {
      key: "PowerSupplyRestored" as const,
      label: (frame) => (
        <Flag value={frame.power_supply_restored}>Short power lost</Flag>
      ),
    },
    {
      key: "StpmKo" as const,
      label: (frame) => (
        <Flag value={frame.stpm_ko}>STPM does not respond</Flag>
      ),
    },
    {
      key: "HardresetReq" as const,
      label: (frame) => (
        <Flag value={frame.hardreset_req}>Hard reset requested by server</Flag>
      ),
    },
    {
      key: "DataframeWatchdog" as const,
      label: (frame) => (
        <Flag value={frame.dataframe_watchdog}>
          No dataframe received by server after 3 * Telemetry
        </Flag>
      ),
    },
    {
      key: "FwUpdateComplete" as const,
      label: (frame) => (
        <Flag value={frame.fw_update_complete}>
          Firmware update is completed
        </Flag>
      ),
    },
    {
      key: "SocketCreateFail" as const,
      label: (frame) => (
        <Flag value={frame.socket_close_fail}>
          Fail creating network socket at boot
        </Flag>
      ),
    },
    {
      key: "SocketCloseFail" as const,
      label: (frame) => (
        <Flag value={frame.socket_close_fail}>
          Fail closing network socket when network/server issue occurs
        </Flag>
      ),
    },
    {
      key: "NetworkHookFail" as const,
      label: (frame) => (
        <Flag value={frame.network_hook_fail}>
          Fail hooking to network after several tries (LTE-M, NB-IoT)
        </Flag>
      ),
    },
    {
      key: "VpnFail" as const,
      label: (frame) => (
        <Flag value={frame.vpn_fail}>
          Fail getting server IP after several tries
        </Flag>
      ),
    },
    {
      key: "DtlsFail" as const,
      label: (frame) => (
        <Flag value={frame.dtls_fail}>
          Fail connecting to server after several tries
        </Flag>
      ),
    },
    {
      key: "CoapTxFail" as const,
      label: (frame) => (
        <Flag value={frame.coap_tx_fail}>
          Fail sending a message to server after several tries
        </Flag>
      ),
    },
    {
      key: "LibCoapFault" as const,
      label: (frame) => (
        <Flag value={frame.lib_coap_fault}>Internal fault in Coap library</Flag>
      ),
    },
    {
      key: "LibXccFault" as const,
      label: (frame) => (
        <Flag value={frame.lib_xcc_fault}>
          Internal fault in X-Cube-Cellular library
        </Flag>
      ),
    },
    {
      key: "DailyReboot" as const,
      label: (frame) => (
        <Flag value={frame.daily_reboot} variant="info">
          Reboot triggered every 24 hours by the firmware
        </Flag>
      ),
    },
    {
      key: "CertCaCrc" as const,
      label: (frame) =>
        frame.ca_cert_crc !== undefined && (
          <IconInfo icon={<SecurityIcon />}>
            Server: {frame.ca_cert_crc}
          </IconInfo>
        ),
    },
    {
      key: "CertDevCrc" as const,
      label: (frame) =>
        frame.device_cert_crc !== undefined && (
          <IconInfo icon={<SecurityIcon />}>
            Device: {frame.device_cert_crc}
          </IconInfo>
        ),
    },
  ],
};

const getCellErrLabel = (value: number) => {
  const values = [
    {
      label: "Cellular register fail",
      value: getBit(value, 0) === 1,
    },
    {
      label: "Cellular attach fail",
      value: getBit(value, 1) === 1,
    },
    {
      label: "VPN not reachable",
      value: getBit(value, 2) === 1,
    },
    {
      label: "DTLS service down",
      value: getBit(value, 3) === 1,
    },
    {
      label: "COAP basic transmission fail",
      value: getBit(value, 4) === 1,
    },
    {
      label: "COAP observe transmission fail",
      value: getBit(value, 5) === 1,
    },
  ];

  return values
    .filter(({ value }) => value)
    .map(({ label }) => label)
    .join(", ");
};

const getBit = (val: number, startBit: number) => {
  return (val >> startBit) & ((1 << 1) - 1);
};
