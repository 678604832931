import { Box, Grid } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { PDLDrawer } from "../fields/PDL";

export const HousingEdit = () => (
  <Edit>
    <EditionForm />
  </Edit>
);

export const HousingCreate = () => (
  <Create>
    <EditionForm />
  </Create>
);

const EditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceInput
            reference="residences"
            source="residenceId"
            fullWidth
            perPage={1000}
          >
            <AutocompleteInput label="Residence" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Unit ID"
            source="unitId"
            helperText="The unit ID (numéro de lot) used in landlord nomenclature"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            label="Additional Address"
            source="additionalAddress"
            helperText="App 7, floor 2"
            required={false}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <TextInput
            label="Address"
            source="address"
            helperText="1 rue de la Paix"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            label="Zip Code"
            source="zipCode"
            helperText="75006"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <TextInput
            label="City"
            source="city"
            helperText="Paris"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="start">
            <PDLDrawer />
            <TextInput label="PDL" source="pdl" fullWidth />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <BooleanInput
            source="multipleDevicesIsOK"
            label="It's normal that multiple devices are installed in this housing"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Comment"
            source="comment"
            fullWidth
            multiline
            minRows={4}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
