import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { WithLatestCommunicationSent } from "../../communications/fields/WithLatestCommunicationSent";

export const HasIncidentCommunicationSent = () => {
  return (
    <WithLatestCommunicationSent
      target="context.incidentId"
      render={({ date }) => (
        <Tooltip
          title={`Email envoyé au bailleur le ${dayjs(date).format(
            "DD/MM/YYYY"
          )}`}
        >
          <MarkEmailReadIcon fontSize="small" sx={{ mx: 1 }} />
        </Tooltip>
      )}
    />
  );
};
