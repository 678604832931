import { Divider, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { groupBy } from "lodash";
import { useRedirect } from "react-admin";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";

type DeviceStatsProps = {
  filter: any;
};

export const SavingsState = ({ filter }: DeviceStatsProps) => {
  const [yearlyNormalized] = useYearlyNormalized();
  const { data } = useQuery({
    queryFn: () => dataProvider.getDeviceStats(filter, yearlyNormalized),
    queryKey: [`devices/stats`, filter, yearlyNormalized],
  });
  const redirect = useRedirect();

  const groups = groupBy(data?.data ?? [], (point) => point.savingsState);

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={{
          title: {
            text: "État des économies",
          },
          subtitle: {
            text: data && `D'après ${data?.data.length} boitiers v2 installés`,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                distance: -35,
                formatter() {
                  return Math.round((this as any).percentage).toFixed(0) + "%";
                },
              },
              showInLegend: true,
              startAngle: -90,
              endAngle: 90,
              center: ["50%", "75%"],
              size: "110%",
            },
            series: {
              events: {
                click: (event) => {
                  redirect(
                    () =>
                      `devices?filter=${JSON.stringify({
                        id: (event.point as any).custom.ids,
                      })}`
                  );
                },
              },
            },
          },
          series: data && [
            {
              type: "pie",
              name: "Boîtier Elax",
              innerSize: "50%",
              data: [
                {
                  savingsState: "GOAL_REACHED",
                  label: "Économies > coût du pilotage annuel",
                  color: "#4caf50",
                },
                {
                  savingsState: "GOAL_UNREACHED",
                  label: "Économies < coût du pilotage annuel",
                  color: "#cddc39",
                },
                {
                  savingsState: "COMPUTING",
                  label:
                    "Économies en cours de calcul (moins de 15 jours de pilotage)",
                  color: "#9e9e9e",
                },
                {
                  savingsState: "OBSERVATION",
                  label: "En Observation",
                  color: "#607D8B",
                },
                {
                  savingsState: "MANUAL_PILOT",
                  label: "Pilotage par le locataire",
                  color: "#2196f3",
                },
                {
                  savingsState: "MISSING_DATA",
                  label: "Information manquante",
                  color: "#ffc107",
                },
                {
                  savingsState: "ERROR",
                  label: "Erreur lors du calcul",
                  color: "#f44336",
                },
              ]
                .map((series) => ({
                  name: series.label,
                  color: series.color,
                  y: groups[series.savingsState]?.length ?? 0,
                  custom: {
                    ids:
                      groups[series.savingsState]?.map((d) => d.deviceId) ?? [],
                  },
                }))
                .filter((data) => data.y > 0),
            },
          ],
        }}
        description={
          <>
            <Divider sx={{ color: "#ffc107", marginTop: 1 }}>Inconnu</Divider>
            <Typography variant="body2">
              Une perte de connection prolongée est généralement à l’origine du
              manque d’information
            </Typography>

            <Divider sx={{ color: "#2196f3", marginTop: 2 }}>
              Pilotage par le locataire
            </Divider>
            <Typography variant="body2">
              Utilisateur pilotant manuellement le ballon, impossibilité de
              piloter
            </Typography>

            <Divider sx={{ color: "#607D8B", marginTop: 2 }}>
              En observation
            </Divider>
            <Typography variant="body2">
              Toujours en phase d’observation
            </Typography>
          </>
        }
      />
    </Paper>
  );
};
