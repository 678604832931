import { useGetList } from "react-admin";

export const useDeviceConfigPersonas = () => {
  const { data: personas } = useGetList("device-config-personas", {
    pagination: {
      perPage: 1000,
      page: 1,
    },
  });

  return personas;
};
