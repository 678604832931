import { Grid } from "@mui/material";
import {
  DateField,
  ImageField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { InlineLabel } from "../../layout/InlineLabel";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../layout/actions-menu";

export const PostShow = () => {
  return (
    <Show actions={<PostActions />}>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InlineLabel label="Title">
              <TextField source="title" />
            </InlineLabel>
            <InlineLabel label="Subtitle">
              <TextField source="subtitle" />
            </InlineLabel>
            <InlineLabel label="Published Date">
              <DateField source="publishedAt" />
            </InlineLabel>
            <InlineLabel label="Thumbnail">
              <ImageField source="thumbnailSrc" />
            </InlineLabel>
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              source="body"
              purifyOptions={{
                ADD_TAGS: ["div", "iframe"],
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export const PostActions = () => (
  <ActionsMenu>
    <EditAction />
    <DeleteAction />
  </ActionsMenu>
);
