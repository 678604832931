import { useRef } from "react";
import { useStore } from "react-admin";
import { NumberParam, useQueryParam } from "use-query-params";
import { useDateRangeFilter } from "../../../../../../filters/useDateRangeFilter";
import type { SeriesName } from "../../../../types";

export const useChartParams = (initialDisplayedSeries: SeriesName[]) => {
  const { dateRange, setDateRange, zoomOut, previous, next, isMax } =
    useDateRangeFilter();

  const [displayedSeries, setDisplayedSeries] = useStore<SeriesName[]>(
    "device.temperature.displayedSeries",
    initialDisplayedSeries
  );

  const [cursor, setCursor] = useQueryParam("cursor", NumberParam);

  const options = useRef<Highcharts.Options>({
    plotOptions: {
      series: {
        events: {
          show: (event: any) => {
            setDisplayedSeries((_displayedSeries) =>
              [
                ..._displayedSeries.filter(
                  (name) => name !== event.target.name
                ),
                event.target.name,
              ].sort()
            );
          },
          hide: (event: any) => {
            setDisplayedSeries((_displayedSeries) =>
              _displayedSeries
                .filter((name) => name !== event.target.name)
                .sort()
            );
          },
          click: (event: any) => {
            setCursor(event.point.x);
          },
        },
      },
    },
    chart: {
      events: {
        selection: (event) => {
          if (event.xAxis && event.xAxis[0]) {
            setDateRange({
              startDate: new Date(event.xAxis[0].min),
              endDate: new Date(event.xAxis[0].max),
            });
          }
          return undefined;
        },
      },
    },
  });

  return {
    dateRange,
    setDateRange,
    displayedSeries,
    handleParamsOptions: options.current,
    zoomOut,
    previous,
    next,
    isMax,
    cursor: cursor || undefined,
  };
};
