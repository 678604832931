import SyncIcon from "@mui/icons-material/Sync";
import { useNotify } from "react-admin";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const SyncPipedriveButton = () => {
  const notify = useNotify();
  return (
    <ActionMenuItem
      label="Sync Pipedrive"
      icon={<SyncIcon />}
      onClick={async () => {
        await dataProvider.syncProjects();
        notify("Synchronisation done");
      }}
    />
  );
};
