import QrCode from "@mui/icons-material/QrCode";
import { Backdrop, IconButton, Portal, Tooltip } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import type { Device } from "../types";

export default function QRCodeField() {
  const record = useRecordContext<Device>();

  const [backdropDisplay, setBackdropDisplay] = useState(false);

  if (!record) {
    return null;
  }

  const url = record.residentAppUrl;

  return (
    <>
      <Tooltip title="Show QRCode">
        <IconButton onClick={() => setBackdropDisplay(true)} size="small">
          <QrCode fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Portal>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropDisplay}
          onClick={() => setBackdropDisplay(false)}
        >
          <a href={url} target="_blank" rel="noreferrer">
            <QRCodeSVG value={url} />
          </a>
        </Backdrop>
      </Portal>
    </>
  );
}
