import { blue, blueGrey, green } from "@mui/material/colors";
import { EnumField } from "../../../fields/EnumField";

export const ContactSourceField = ({ label }: { label?: string }) => (
  <EnumField
    defaultColor={blueGrey[300]}
    colors={{
      backOffice: blue[500],
      intervention: green[500],
    }}
    emptyText="-"
    label={label}
    source="source"
  />
);
