import { FunctionField } from "react-admin";
import type { Identifier } from "react-admin";
import { OptimizedReferenceOneField } from "../../../fields/OptimizedReferenceOneField";
import { CommunicationChip } from "../../communications/components/CommunicationChip";
import type {
  Communication,
  CommunicationMedium,
} from "../../communications/type";
import { ResidentCommunicationTopic } from "../../communications/type";

export const DeploymentCommunicationsSentField = ({
  label,
  projectId,
  medium,
}: {
  label?: string;
  projectId: Identifier;
  medium: CommunicationMedium;
}) => {
  return (
    <OptimizedReferenceOneField
      reference="communications"
      target="context.housingId"
      label="Deployment letter sent"
      sortable={false}
      fields={["id", "status", "statusDetails", "date"]}
      filter={{
        topic: ResidentCommunicationTopic.INSTALLATION_APPOINTMENT,
        "context.projectId": projectId,
      }}
      noDataChildren={<CommunicationChip communication={null} label={medium} />}
    >
      <FunctionField<Communication>
        label={label}
        render={(record) => (
          <CommunicationChip communication={record} label={medium} />
        )}
      />
    </OptimizedReferenceOneField>
  );
};
