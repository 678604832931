import { activityLogResource } from "./activity-logs";
import { alertResource } from "./alerts";
import { communicationResource } from "./communications";
import { dealResource } from "./deal";
import { demandResponseResource } from "./demand-response";
import { demandResponseEntityResource } from "./demand-response-entities";
import { deviceConfigPersonaResource } from "./device-config-persona";
import { deviceTagResource } from "./device-tags";
import { deviceResource } from "./devices";
import { electricityPriceResource } from "./electricity-prices";
import { endUserResource } from "./end-users";
import { feedbackResource } from "./feedback";
import { firmwareResource } from "./firmware";
import { groupResource } from "./group";
import { heaterResource } from "./heater";
import { housingResource } from "./housing";
import { incidentResource } from "./incidents";
import { interventionResource } from "./intervention";
import { listItemResource } from "./list-item";
import { maintenanceProviderResource } from "./maintenance-provider";
import { organizationResource } from "./organization";
import { postResource } from "./posts";
import { projectResource } from "./project";
import { residenceResource } from "./residence";
import { residenceContactResource } from "./residence-contact";
import { userResource } from "./users";

export const resources = {
  communications: communicationResource,
  devices: deviceResource,
  alerts: alertResource,
  users: userResource,
  groups: groupResource,
  firmwares: firmwareResource,
  "end-users": endUserResource,
  residences: residenceResource,
  "residence-contacts": residenceContactResource,
  housings: housingResource,
  interventions: interventionResource,
  projects: projectResource,
  "electricity-prices": electricityPriceResource,
  heaters: heaterResource,
  feedbacks: feedbackResource,
  organizations: organizationResource,
  "activity-logs": activityLogResource,
  "demand-response-entities": demandResponseEntityResource,
  "demand-responses": demandResponseResource,
  "device-config-personas": deviceConfigPersonaResource,
  posts: postResource,
  incidents: incidentResource,
  "list-items": listItemResource,
  "device-tags": deviceTagResource,
  "maintenance-providers": maintenanceProviderResource,
  deals: dealResource,
};
