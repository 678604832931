import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
  Select,
} from "@mui/material";
import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  ReferenceOneField,
  TextField,
  useRecordContext,
} from "react-admin";
import { JsonParam, useQueryParam } from "use-query-params";
import { OptimizedReferencesField } from "../../../fields/OptimizedReferenceOneField";
import { LatestCriticalAlert } from "../../alerts/fields/LatestAlert";
import { CommunicationMedium } from "../../communications/type";
import { DeviceIcon } from "../../devices";
import { NameField } from "../../end-users/fields/NameField";
import { InterventionSourcingStatusField } from "../../intervention/fields/InterventionSourcingStatusField";
import { InterventionVisitStatusField } from "../../intervention/fields/InterventionVisitStatusField";
import { AddressField } from "../fields/Address";
import { DeploymentCommunicationsSentField } from "../fields/DeploymentCommunicationsSentField";
import { HousingLastInstallationField } from "../fields/HousingLastInstallationField";
import { HousingStatusField } from "../fields/Status";
import { HousingStatus, housingStatusLabels } from "../types";

export const DeploymentHousingList = ({
  target,
}: {
  target: "projectId" | "dealId";
}) => {
  const record = useRecordContext();
  const [filters] = useQueryParam("filters", JsonParam);

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <ReferenceManyField
        reference="housings"
        target={target}
        pagination={<Pagination />}
        filter={{ ...filters }}
      >
        <HousingFilterForm />
        <Datagrid bulkActionButtons={false}>
          <TextField label="Unit ID" source="unitId" />
          <AddressField label="Address" />
          <ReferenceOneField
            reference="end-users"
            target="housingIds"
            label="End User"
            sortable={false}
            link="show"
          >
            <NameField />
          </ReferenceOneField>
          <ReferenceOneField
            reference="devices"
            target="housingId"
            label="Device Critical Alert"
            sortable={false}
          >
            <LatestCriticalAlert />
          </ReferenceOneField>
          <OptimizedReferencesField
            reference="devices"
            target="housingId"
            label="Device"
            sortable={false}
            fields={["name"]}
            getLink={(device) => `/devices/${device.id}/show`}
          >
            <Box>
              <DeviceIcon fontSize="small" />
              <TextField source="name" />
            </Box>
          </OptimizedReferencesField>
          <HousingStatusField label="Status" source="status" />
          <HousingLastInstallationField label="Sourcing Status">
            <InterventionSourcingStatusField />
          </HousingLastInstallationField>
          <HousingLastInstallationField label="Visit Status">
            <InterventionVisitStatusField />
          </HousingLastInstallationField>
          <HousingLastInstallationField label="Equiped At">
            <DateField source="datetime" />
          </HousingLastInstallationField>
          <HousingLastInstallationField label="Planned At">
            <DateField source="plannedSlot.startDate" showTime />
          </HousingLastInstallationField>
          {target === "projectId" && (
            <DeploymentCommunicationsSentField
              label="Deployment letter sent"
              projectId={record.id}
              medium={CommunicationMedium.LETTER}
            />
          )}
          {target === "projectId" && (
            <DeploymentCommunicationsSentField
              label="Deployment email sent"
              projectId={record.id}
              medium={CommunicationMedium.EMAIL}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </Grid>
  );
};

const HousingFilterForm = () => {
  const [filters, setFilters] = useQueryParam("filters", JsonParam);

  return (
    <Box display="flex" alignItems="flex-end" mb={1}>
      <Box component="span" mr={2}>
        <MuiTextField
          helperText={false}
          label="Search"
          onChange={(event) => {
            setFilters({ ...filters, q: event.target.value || undefined });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
        />
      </Box>
      <Box component="span" mr={2}>
        <Select
          value={filters?.status || ""}
          label="Status"
          onChange={(event) =>
            setFilters({ ...filters, status: event.target.value || undefined })
          }
          size="small"
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {Object.values(HousingStatus).map((value) => (
            <MenuItem key={value} value={value}>
              {housingStatusLabels[value]}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};
