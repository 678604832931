import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import type { ReactNode } from "react";
import { useRecordContext } from "react-admin";
import type { BaseFieldProps } from "./types";

dayjs.extend(relativeTime);

export function TimeAgoField({ source }: BaseFieldProps): ReactNode {
  const record = useRecordContext();
  const value = record?.[source];

  return (
    <>
      {value && (
        <Tooltip title={dayjs(value).format("YYYY-MM-DD HH:mm:ss")}>
          <div>{dayjs(value).fromNow()}</div>
        </Tooltip>
      )}
    </>
  );
}
