import type { TypographyProps } from "@mui/material";
import { Typography } from "@mui/material";
import { useGetOne } from "react-admin";

export const UserField = ({
  userId,
  emptyText,
  ...props
}: { userId?: string; emptyText?: string } & TypographyProps) => {
  const { data } = useGetOne<{ id: string; email: string }>(
    "users",
    { id: userId! },
    { enabled: !!userId }
  );
  return (
    <Typography {...props}>
      {data?.email.split("@")[0] ?? emptyText ?? "-"}
    </Typography>
  );
};
