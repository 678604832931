import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  AutocompleteArrayInput,
  CreateBase,
  Form,
  ReferenceArrayInput,
  SaveButton,
  useCreateSuggestionContext,
} from "react-admin";
import TagField from "../fields/TagField";
import TagForm from "./TagForm";

export default function AutocompleteTagInput() {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <ReferenceArrayInput label="Tags" source="tags" reference="device-tags">
        <AutocompleteArrayInput
          create={<CreateTagDialog />}
          optionText={<TagField />}
          inputText={(choice) => choice.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <TagField
                size="small"
                record={option}
                {...getTagProps({ index })}
              />
            ))
          }
          sx={{ minWidth: "300px" }}
          fullWidth
        />
      </ReferenceArrayInput>
      <Box>
        <Button
          href="/#/device-tags"
          sx={{ whiteSpace: "nowrap", paddingX: 2, marginTop: 1.5 }}
          startIcon={<LocalOfferIcon />}
        >
          Tags list
        </Button>
      </Box>
    </Stack>
  );
}

const CreateTagDialog = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>Create a new tag</DialogTitle>
      <CreateBase
        resource="device-tags"
        mutationOptions={{
          onSuccess: (data) => {
            onCreate(data);
          },
        }}
      >
        <Form defaultValues={{ name: filter || "" }}>
          <DialogContent>
            <TagForm />
          </DialogContent>
          <DialogActions>
            <SaveButton alwaysEnable />
            <Button onClick={onCancel}>Cancel</Button>
          </DialogActions>
        </Form>
      </CreateBase>
    </Dialog>
  );
};
