import { Grid } from "@mui/material";
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";

const EditionForm = () => {
  return (
    <SimpleForm toolbar={<IncidentEditToolbar />}>
      <Grid container spacing={2}>
        <Grid item xs={12} justifyContent="center" display="flex">
          <TextInput source="title" />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const IncidentEdit = () => (
  <Edit redirect={false} mutationMode="pessimistic">
    <EditionForm />
  </Edit>
);

const IncidentEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
