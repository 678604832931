import { ReferenceField, TextField } from "react-admin";
import { OptimizedReferenceOneField } from "../../../fields/OptimizedReferenceOneField";
import { InterventionType } from "../../intervention/types";

export const DeviceLastProjectField = ({ label }: { label?: string }) => {
  return (
    <OptimizedReferenceOneField
      label={label || "Last project"}
      reference="interventions"
      target="deviceId"
      filter={{
        type: [InterventionType.INSTALLATION, InterventionType.REPLACEMENT],
      }}
      sortField="datetime"
      sortOrder="desc"
      fields={["projectId"]}
    >
      <ReferenceField reference="projects" source="projectId" link="show">
        <TextField source="name" />
      </ReferenceField>
    </OptimizedReferenceOneField>
  );
};
