import { useRecordContext } from "react-admin";
import ReactMarkdown from "react-markdown";

type MarkdownPanelProps = {
  source: string;
};

export const MarkdownPanel = ({ source }: MarkdownPanelProps) => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return <ReactMarkdown>{record[source]}</ReactMarkdown>;
};
