import type { ReactNode } from "react";
import { Datagrid, List } from "react-admin";
import { communicationFields } from "./fields";
import { communicationFilters } from "./filters";

export function CommunicationList(): ReactNode {
  return (
    <List
      resource="communications"
      perPage={100}
      filters={Object.values(communicationFilters)}
      sort={{ field: "date", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false}>
        {Object.values(communicationFields)}
      </Datagrid>
    </List>
  );
}
