import { useBulkActionFilter } from "../../../actions/useBulkActionFilter";
import { ExportIcon, useExport } from "../../../actions/useExport";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const ExportDevicesOrEndUsers = ({
  resource,
}: {
  resource: "devices" | "end-users";
}) => {
  const filter = useBulkActionFilter();
  const { mutate } = useExport(() => dataProvider.exportData(resource, filter));

  return (
    <ActionMenuItem
      label="Export Deployment"
      icon={<ExportIcon />}
      onClick={mutate}
    />
  );
};
