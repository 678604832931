import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import { NoWheelNumberInput } from "../../fields/NumberInput";

const EditionForm = () => {
  return (
    <SimpleForm>
      <TextInput source="model" fullWidth />
      <TextInput source="supplier" fullWidth />
      <NoWheelNumberInput source="power" fullWidth />
      <NoWheelNumberInput source="volume" fullWidth />
      <BooleanInput source="vertical" fullWidth />
      <BooleanInput source="individual" fullWidth />
      <TextInput source="electrical_connection" fullWidth />
      <TextInput source="probes_installation" fullWidth />
      <TextInput source="comment" fullWidth />
    </SimpleForm>
  );
};
export const HeaterEdit = () => (
  <Edit>
    <EditionForm />
  </Edit>
);
