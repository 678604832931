import { Grid, Typography } from "@mui/material";
import { FunctionField, Show, TabbedShowLayout } from "react-admin";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../../layout/actions-menu";
import { EditConsents } from "../actions/EditConsents";
import { serializeEndUserName } from "../fields/NameField";
import type { EndUser } from "../type";
import { CommunicationTab } from "./tabs/Communications";
import { EndUserShowTab } from "./tabs/EndUser";
import { ZendeskTicketsTab } from "./tabs/ZendeskTickets";

export const EndUserShow = () => {
  return (
    <Show actions={<EndUserActions />}>
      <FunctionField<EndUser>
        render={(record) => (
          <Typography variant="h6" textAlign="center" margin={2}>
            {serializeEndUserName(record)}
          </Typography>
        )}
      />
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Data">
          <Grid container spacing={2}>
            <EndUserShowTab />
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Zendesk">
          <ZendeskTicketsTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Communications">
          <CommunicationTab />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const EndUserActions = () => (
  <ActionsMenu>
    <EditAction />
    <EditConsents />
    <DeleteAction />
  </ActionsMenu>
);
