import { Box, Typography } from "@mui/material";
import type { ReactNode } from "react";

type InlineLabelProps = {
  label: string;
  children: ReactNode | ReactNode[];
};

export const InlineLabel = ({ label, children }: InlineLabelProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" fontWeight="bold" marginRight={1}>
        {label}:
      </Typography>

      {children}
    </Box>
  );
};
