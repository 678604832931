import { FunctionField } from "react-admin";
import type { Heater } from "../type";

export const getHeaterPower = (heater: Heater) => {
  return `${heater.power ? `${heater.power}W` : "Unknown"}${
    heater.computed_electric_power
      ? ` (${heater.computed_electric_power}W observed)`
      : ""
  }`;
};

export default function HeaterPowerField() {
  return <FunctionField<Heater> render={getHeaterPower} variant="body1" />;
}
