import { Box, Tooltip } from "@mui/material";
import { cloneElement } from "react";
import type { ReactElement, ReactNode } from "react";

type FrameSpecsData<T = any> = {
  key: string;
  label: (frame: T) => ReactNode;
};

export type FrameSpecs<T = any> = {
  icon: ReactElement;
  data: FrameSpecsData<T>[];
};

export type FrameType =
  | "data"
  | "config"
  | "config-ack"
  | "debug"
  | "production"
  | "status"
  | "network";

export const IconInfo = ({
  first,
  icon,
  title,
  children,
}: {
  first?: boolean;
  title?: string;
  icon: ReactElement;
  children: ReactNode;
}) => (
  <Box
    display="flex"
    alignItems="center"
    marginRight={2}
    marginLeft={first ? 0 : 2}
  >
    <Tooltip title={title}>
      {cloneElement(icon, { sx: { marginRight: 0.5 } })}
    </Tooltip>
    {children}
  </Box>
);

export const Flag = ({
  value,
  title,
  children,
  variant,
}: {
  value: boolean | undefined;
  title?: string;
  children: ReactNode;
  variant?: string;
}) => {
  if (!value) {
    return null;
  }

  const color = variant === "info" ? "#0288d1" : "#d32f2f";

  return (
    <Tooltip title={title}>
      <Box
        border={`solid 1px ${color}`}
        borderRadius={1}
        color={color}
        marginX={0.5}
        paddingX={1}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
