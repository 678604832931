import { Chip } from "@mui/material";
import { TextField } from "react-admin";
import { PipedriveIcon } from "../../../icons/PipedriveIcon";

export const PipedriveStageField = () => {
  return (
    <Chip
      icon={<PipedriveIcon />}
      label={<TextField source="pipedriveStage" emptyText="-" />}
    />
  );
};
