import { EnumField } from "../../../fields/EnumField";
import { interventionTypeColors } from "../types";

export const InterventionTypeField = ({ label }: { label?: string }) => (
  <EnumField
    label={label || "Type"}
    source="type"
    colors={interventionTypeColors}
  />
);
