import { Grid } from "@mui/material";
import {
  Create,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ContractType } from "../devices/types";

export const DemandResponseEntityEditionForm = ({
  isCreate,
}: {
  isCreate: boolean;
}) => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <TextInput source="name" fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <NumberInput
            source="maxCapacityInMW"
            label="Maximum capacity in MW"
            fullWidth
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <SelectInput
            source="type"
            disabled={!isCreate}
            fullWidth
            choices={[
              {
                id: "Profilé",
                name: "Profilé (contient des sites de consommation <36 kVA)",
              },
              {
                id: "Télérelevé",
                name: "Télérelevé (contient des sites de consommation >36kVA)",
              },
            ]}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <SelectInput
            source="method"
            fullWidth
            choices={[
              {
                id: "RDRC",
                name: "RDRC : rectangle à double référence corrigée",
              },
              {
                id: "RA",
                name: "RA : rectangle algébrique site à site",
              },
            ]}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <SelectInput
            source="contractType"
            fullWidth
            choices={Object.values(ContractType).map((type) => ({
              id: type,
              name: type,
            }))}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput source="comment" multiline minRows={4} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const DemandResponseEntityEdit = () => (
  <Edit redirect="show" mutationMode="pessimistic">
    <DemandResponseEntityEditionForm isCreate={false} />
  </Edit>
);

export const DemandResponseEntityCreate = () => (
  <Create redirect="show">
    <DemandResponseEntityEditionForm isCreate={true} />
  </Create>
);
