import { useGetManyReference, useRecordContext } from "react-admin";
import { Mode } from "../../../../device-config/types";
import type { DeviceConfig } from "../../../../device-config/types";

export const useDevicePeriods = () => {
  const record = useRecordContext();

  const { data: deviceConfigs } = useGetManyReference<DeviceConfig>(
    "device-configs",
    {
      id: record?.deviceId || record?.id,
      target: "deviceId",
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "createdAt", order: "ASC" },
    },
    { staleTime: 1000 * 60 * 5 }
  );

  const referencePeriod = deviceConfigs?.reduce(
    (period, config) => {
      if (Mode.OBSERVATION === config.mode) {
        if (!period.startDate || period.endDate) {
          period.startDate = new Date(config.createdAt);
          period.endDate = undefined;
        }
      } else {
        if (period.startDate && !period.endDate) {
          period.endDate = new Date(config.createdAt);
        }
      }
      return period;
    },
    { startDate: undefined, endDate: undefined } as {
      startDate: undefined | Date;
      endDate: undefined | Date;
    }
  ) || { startDate: undefined, endDate: undefined };

  return {
    initialization:
      deviceConfigs && deviceConfigs.length > 0
        ? deviceConfigs[0].createdAt
        : undefined,
    reference: referencePeriod,
  };
};
