import { Grid } from "@mui/material";
import { useEffect } from "react";
import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { DeviceLocationAfterRemovalInput } from "./fields/DeviceLocationAfterRemovalInput";
import { RemovalResponsibleInput } from "./fields/RemovalResponsibleInput";
import { ReplacedDeviceInput } from "./fields/ReplacedDeviceInput";
import {
  InterventionStatus,
  InterventionType,
  interventionClosedStatuses,
  interventionPriorityLabels,
  interventionStatusLabels,
} from "./types";
import type { InterventionPriority } from "./types";

export const InterventionEdit = () => (
  <Edit mutationMode="pessimistic" redirect={false}>
    <SimpleForm>
      <InterventionEditionForm />
    </SimpleForm>
  </Edit>
);

export const InterventionCreate = () => (
  <Create>
    <SimpleForm>
      <InterventionEditionForm />
    </SimpleForm>
  </Create>
);

function InterventionEditionForm() {
  const { setValue } = useFormContext();

  const interventionType = useWatch({ name: "type" });
  useEffect(() => {
    if (interventionType !== InterventionType.REMOVAL) {
      setValue("removalResponsible", undefined);
      setValue("deviceLocationAfterRemoval", undefined);
    }
    if (interventionType !== InterventionType.REPLACEMENT) {
      setValue("replacedDeviceId", undefined);
    }
  }, [interventionType, setValue]);

  const interventionStatus = useWatch({ name: "status" });
  const canEditDatetime =
    interventionClosedStatuses.includes(interventionStatus);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectInput
          source="type"
          label="Type"
          choices={Object.values(InterventionType).map((value) => ({
            id: value,
            name: value,
          }))}
          fullWidth
        />
      </Grid>
      {interventionType === InterventionType.REMOVAL ? (
        <>
          <Grid item xs={12}>
            <RemovalResponsibleInput />
          </Grid>
          <Grid item xs={12}>
            <DeviceLocationAfterRemovalInput />
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <SelectInput
          source="status"
          label="Status"
          choices={Object.values(InterventionStatus).map((value) => ({
            id: value,
            name: interventionStatusLabels[value],
            disabled: ![
              InterventionStatus.TO_BE_PLANNED,
              InterventionStatus.PLANNED,
            ].includes(value),
          }))}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ReplacedDeviceInput />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="devices"
          source="deviceId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput label="Device" optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="housings"
          source="housingId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput label="Housing" optionText="unitId" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="projects"
          source="projectId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput label="Project" optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <DateTimeInput
          label="Intervention Date"
          source="datetime"
          required={false}
          fullWidth
          disabled={!canEditDatetime}
          helperText={
            canEditDatetime
              ? undefined
              : "Ne peut pas être mise à jour pour une intervention non terminée (succès, échec ou pas de RDV)."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DateTimeInput
          label="Planned At"
          source="plannedSlot.startDate"
          required={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Kizeo ID" source="externalId" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="maintenance-providers"
          source="maintenanceProviderId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput
            label="Maintenance Provider"
            optionText="name"
            fullWidth
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Mission Order"
          source="missionOrder"
          required={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          source="priority"
          label="Priority"
          choices={[1, 2, 3, 4].map((value) => ({
            id: value,
            name: interventionPriorityLabels[value as InterventionPriority],
          }))}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Comment" source="comment" required={true} fullWidth />
      </Grid>
    </Grid>
  );
}
