import { Alert } from "@mui/material";
import { useGetCount } from "../../../providers/data-hooks/useGetCount";

export const V1Alert = ({ filter }: { filter: any }) => {
  const count = useGetCount("devices", {
    ...filter,
    VersionHardware: {
      $regex: "^1",
    },
  });

  if (!count) {
    return <></>;
  }

  return (
    <Alert severity="error">
      {count} devices v1 are in use in the project. Their consumption are not
      taken into account in the following dashboard
    </Alert>
  );
};
