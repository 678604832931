import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/material";
import type { DatagridOrFilterRequiredProps } from "../../../../layout/types";

export const CriticalFilter = ({ label }: DatagridOrFilterRequiredProps) => (
  <Box
    sx={{
      backgroundColor: "#f4433652",
      color: "#f44336",
      borderRadius: 1,
      textAlign: "center",
      fontWeight: "bold",
      paddingX: 1,
      paddingY: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "min-content",
      marginY: "12px",
    }}
    title={label}
  >
    <ErrorIcon />
    CRITICAL
  </Box>
);
