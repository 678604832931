import { useRecordContext } from "react-admin";
import { UpdateActivityLog } from "./actions/Update";
import { UpdateManyActivityLog } from "./actions/UpdateMany";

export const ShowActivityLog = () => {
  const record = useRecordContext();

  if (!record) {
    return <></>;
  }

  if (record.action === "updateMany") {
    return <UpdateManyActivityLog payload={record.payload} />;
  }

  if (record.action === "update") {
    return <UpdateActivityLog payload={record.payload} />;
  }

  return <>No view for {record.action} action</>;
};
