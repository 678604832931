import { Box, CircularProgress, Typography } from "@mui/material";

type MetricProps = {
  title: string;
  unit?: string;
  value: number | null | undefined;
  reference?: number | null | undefined;
  total?: number | null | undefined;
  isLoading?: boolean;
  precision?: number;
};

export const Metric = ({
  title,
  unit,
  value,
  reference,
  total,
  isLoading,
  precision = 1,
}: MetricProps) => {
  return (
    <Box marginY={1}>
      <Typography variant="h4" textAlign="center">
        {title}
      </Typography>
      <Typography variant="h2" textAlign="center">
        {isLoading ? (
          <CircularProgress />
        ) : value !== null && value !== undefined ? (
          value.toFixed(precision)
        ) : (
          "-"
        )}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {unit}
        {reference &&
          !isLoading &&
          getComparisionString(value, reference, total)}
      </Typography>
    </Box>
  );
};

const getComparisionString = (
  value: number | null | undefined,
  ref: number | null | undefined,
  total: number | null | undefined
) => {
  if (value === null || value === undefined || !ref) {
    return "";
  }

  return ` (${value > ref ? "+" : "-"}${(
    (Math.abs(ref - value) / (total ?? ref)) *
    100
  ).toFixed(1)}%)`;
};
