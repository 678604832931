import { Typography } from "@mui/material";
import {
  Datagrid,
  DateField,
  ReferenceManyCount,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { DeploymentProgress } from "../../../project/fields/DeploymentProgress";
import { ProjectStageField } from "../../../project/fields/Status";

export const ClientDetails = () => {
  return (
    <>
      <Typography variant="h6">Projects</Typography>
      <ReferenceManyField
        reference="projects"
        target="organizationId"
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={1000}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Name" source="name" />
          <ProjectStageField label="Status" />
          <DeploymentProgress
            entity="project"
            label="Deployment"
            showCount={false}
          />
          <DateField label="Created At" source="createdAt" />
          <ReferenceManyCount
            label="Housings"
            reference="housings"
            target="projectId"
          />
          <ReferenceManyCount
            label="Devices"
            reference="devices"
            target="projectId"
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
