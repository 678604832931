import { Typography } from "@mui/material";
import type { ReactElement, ReactNode } from "react";
import { cloneElement } from "react";
import { RecordContextProvider, WithListContext } from "react-admin";

export function FunctionList({
  children,
  emptyText,
}: {
  children: ReactElement;
  emptyText?: ReactNode;
}) {
  return (
    <WithListContext
      render={({ data }) =>
        data ? (
          <>
            {data.length > 0 ? (
              data.map((record) => (
                <RecordContextProvider key={record.id} value={record}>
                  {cloneElement(children)}
                </RecordContextProvider>
              ))
            ) : (
              <Typography variant="body1">{emptyText || "-"}</Typography>
            )}
          </>
        ) : null
      }
    />
  );
}
