import {
  CalendarMonth,
  CheckCircle,
  LocalOffer,
  Send,
  Subject,
} from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import { Show, TextField } from "react-admin";
import { FieldItem } from "../../display/FieldItem";
import { communicationFields } from "./fields";
import { CommunicationContext } from "./fields/CommunicationContext";
import { CommunicationPreview } from "./fields/CommunicationPreview";
import { CommunicationRecipient } from "./fields/CommunicationRecipientField";
import { CommunicationStatusField } from "./fields/CommunicationStatus";

export const CommunicationShow = () => {
  return (
    <Show>
      <Grid container padding={2}>
        <Grid item md={4}>
          <FieldItem label="Date" icon={<CalendarMonth />}>
            {communicationFields.date}
          </FieldItem>
          <FieldItem label="Topic" icon={<LocalOffer />}>
            {communicationFields.topic}
          </FieldItem>
          <FieldItem label="Medium" icon={<Send />}>
            {communicationFields.medium}
          </FieldItem>
        </Grid>
        <Grid item md={4}>
          <FieldItem label="Status" icon={<CheckCircle />} showHistory={true}>
            <CommunicationStatusField source="status" label="Status" />
          </FieldItem>
          <FieldItem
            label="Status Details"
            icon={<Subject />}
            showHistory={true}
          >
            <TextField source="statusDetails" />
          </FieldItem>
          <CommunicationRecipient />
        </Grid>
        <Grid item md={4}>
          <CommunicationContext />
        </Grid>
      </Grid>
      <Divider />
      <CommunicationPreview />
    </Show>
  );
};
