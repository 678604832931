import OpenInNew from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import { FunctionField, ReferenceField } from "react-admin";
import type { EndUserZendesk } from "../type";

type ZendeskUserUrlFieldProps = {
  label?: string;
  title?: string;
};

export const ZendeskUserUrlField = ({ title }: ZendeskUserUrlFieldProps) => {
  return (
    <ReferenceField reference="end-user-zendesk" source="id">
      <FunctionField<EndUserZendesk>
        render={(endUserZendesk) => {
          if (!endUserZendesk.zendeskUrl) return null;
          return (
            <Button
              variant="text"
              href={endUserZendesk.zendeskUrl}
              target="_blank"
              startIcon={<OpenInNew />}
              sx={{ marginLeft: 2 }}
            >
              {title || "Open user in Zendesk"}
            </Button>
          );
        }}
      />
    </ReferenceField>
  );
};
