import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { ResidenceContact } from "../types";

export const ResidenceContactDetailsField = () => {
  const residenceContact = useRecordContext<ResidenceContact>();

  if (!residenceContact) {
    return null;
  }

  return (
    <>
      <Typography fontWeight="bold">{residenceContact.type}</Typography>
      <Typography paddingLeft={2}>{`${residenceContact.firstName ?? ""} ${
        residenceContact.lastName ?? ""
      }`}</Typography>
      <Typography paddingLeft={2}>{`${residenceContact.phone ?? ""} ${
        residenceContact.email ?? ""
      }`}</Typography>
    </>
  );
};
