import { Typography } from "@mui/material";
import { Create, Edit, SimpleForm, TextInput } from "react-admin";
import { SmartParameters } from "../device-config/parameters/smart";
import { SmartHPHCParameters } from "../device-config/parameters/smart-hphc";

export const DeviceConfigPersonaCreate = () => {
  return (
    <Create>
      <Form />
    </Create>
  );
};

export const DeviceConfigPersonaEdit = () => (
  <Edit mutationMode="pessimistic">
    <Form />
  </Edit>
);

const Form = () => (
  <SimpleForm>
    <TextInput source="name" fullWidth />
    <TextInput source="description" fullWidth multiline minRows={4} />

    <Typography variant="h5" marginBottom={2}>
      Parameters HP/HC
    </Typography>
    <SmartHPHCParameters source="parameters_hp_hc" />

    <Typography variant="h5" marginBottom={2}>
      Parameters Base
    </Typography>
    <SmartParameters source="parameters_base" />
  </SimpleForm>
);
