import { Box, ClickAwayListener, InputAdornment, Popper } from "@mui/material";
import { useState } from "react";
import type { MouseEvent } from "react";
import { TextInput, useInput } from "react-admin";
import type { TextInputProps } from "react-admin";
import { ChromePicker } from "react-color";
import type { ColorChangeHandler } from "react-color";

export default function ColorPicker(props: TextInputProps) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const pickerDisplay = !!anchor;

  const { field } = useInput(props);

  const togglePicker = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchor((state) => (state ? null : event.currentTarget));
  };

  const handleChange: ColorChangeHandler = (color) => {
    field.onChange({ target: { value: color.hex } });
  };

  return (
    <Box>
      <TextInput
        onClick={togglePicker}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  backgroundColor: field.value,
                }}
              />
            </InputAdornment>
          ),
        }}
        {...props}
      />
      <ClickAwayListener onClickAway={() => setAnchor(null)}>
        <Popper
          open={pickerDisplay}
          anchorEl={anchor}
          placement="bottom-start"
          sx={{
            zIndex: 1400, // because dialogs have a z-index of 1300
          }}
        >
          <ChromePicker color={field.value} onChange={handleChange} />
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}
