import BoltIcon from "@mui/icons-material/Bolt";
import CancelIcon from "@mui/icons-material/Cancel";
import Co2Icon from "@mui/icons-material/Co2";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TimelineIcon from "@mui/icons-material/Timeline";
import TodayIcon from "@mui/icons-material/Today";
import UpdateIcon from "@mui/icons-material/Update";
import { Box, Typography } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  Show,
  TextField,
} from "react-admin";
import { DemandResponseIcon } from "..";
import { FieldGrid } from "../../../display/FieldGrid";
import { FieldItem } from "../../../display/FieldItem";
import { RoundedNumberField } from "../../../fields/RoundedNumberField";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../../layout/actions-menu";
import { CancelAction } from "../actions/CancelAction";
import { CancelledField } from "../fields/CancelledField";
import { DemandResponseTypeField } from "../fields/DemandResponseTypeField";

export const DemandResponseShow = () => {
  return (
    <Show actions={<Actions />}>
      <Box padding={2}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <DemandResponseIcon fontSize="large" sx={{ marginRight: 1 }} />
          <ReferenceField
            reference="demand-response-pools"
            source="pool"
            link={false}
          >
            <ReferenceField
              reference="demand-response-entities"
              source="entity"
              link={false}
            >
              <TextField source="name" variant="h4" sx={{ marginRight: 1 }} />
            </ReferenceField>
          </ReferenceField>
          <CancelledField fontSize="large" />
          <DemandResponseTypeField fontSize="large" />
        </Box>

        <Typography variant="h6" mt={2}>
          Date
        </Typography>
        <FieldGrid>
          <FieldItem label="Start date" icon={<TodayIcon />}>
            <DateField source="startDate" showTime={true} />
          </FieldItem>

          <FieldItem label="End date" icon={<TodayIcon />}>
            <DateField source="endDate" showTime={true} />
          </FieldItem>

          <FieldItem label="Cancelled" icon={<CancelIcon />}>
            <DateField source="cancelledAt" showTime={true} />
          </FieldItem>

          <FieldItem label="Type" icon={<UpdateIcon />}>
            <TextField source="type" />
          </FieldItem>
        </FieldGrid>

        <Typography variant="h6" mt={2}>
          Power
        </Typography>
        <FieldGrid>
          {["Declared", "Valorized", "Measured", "Realized"].map(
            (name, index) => (
              <FieldItem label={name} icon={<BoltIcon />} key={index}>
                <RoundedNumberField source={`power${name}InMW`} unit="MW" />
              </FieldItem>
            )
          )}
        </FieldGrid>

        <Typography variant="h6" mt={2}>
          Analysis
        </Typography>
        <FieldGrid>
          <FieldItem label="Price amount" icon={<ReceiptIcon />}>
            <RoundedNumberField source="priceAmountInEuros" unit="€" />
          </FieldItem>

          <FieldItem label="Profits" icon={<ReceiptIcon />}>
            <RoundedNumberField source="profitsInEuros" unit="€" />
          </FieldItem>

          <FieldItem label="Declared accuracy" icon={<TimelineIcon />}>
            <RoundedNumberField source="accuracyDeclared" />
          </FieldItem>

          <FieldItem label="Efficiency" icon={<TimelineIcon />}>
            <RoundedNumberField source="efficiency" />
          </FieldItem>

          <FieldItem label="Realized accuracy" icon={<TimelineIcon />}>
            <RoundedNumberField source="accuracyRealized" />
          </FieldItem>

          <FieldItem label="Avoided CO2 (average)" icon={<Co2Icon />}>
            <RoundedNumberField
              source="avoidedCO2WithAverageMethodInKg"
              unit="kg"
            />
          </FieldItem>

          <FieldItem label="Avoided CO2 (marginal)" icon={<Co2Icon />}>
            <RoundedNumberField
              source="avoidedCO2WithMarginalMethodInKg"
              unit="kg"
            />
          </FieldItem>
        </FieldGrid>

        <Typography variant="h6" mt={2}>
          Events
        </Typography>
        <ArrayField source="events" fullWidth>
          <Datagrid bulkActionButtons={false}>
            <TextField source="type" label="Type" variant="body1" />
            <DateField
              source="date"
              label="Date"
              showTime={true}
              variant="body1"
            />
          </Datagrid>
        </ArrayField>
      </Box>
    </Show>
  );
};

const Actions = () => (
  <ActionsMenu>
    <EditAction />
    <DeleteAction />
    <CancelAction />
  </ActionsMenu>
);
