import { IconButton, Stack, Tooltip } from "@mui/material";
import {
  FunctionField,
  Link,
  ReferenceField,
  useRecordContext,
} from "react-admin";
import { CopyRecordFieldValueToClipboard } from "../../../actions/CopyRecordFieldValueToClipboard";
import ZendeskIcon from "../../../icons/ZendeskIcon";
import type { EndUser, EndUserZendesk } from "../type";
import { NameField, serializeEndUserName } from "./NameField";

export function EndUserNameFieldWithLinks() {
  const record = useRecordContext<EndUser>();

  if (!record) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Link to={`/end-users/${record.id}/show`}>
        <NameField record={record} variant="body1" />
      </Link>

      <Stack direction="row" alignItems="center">
        <ReferenceField
          reference="end-user-zendesk"
          source="id"
          record={record}
        >
          <FunctionField<EndUserZendesk>
            render={(endUserZendesk) => {
              if (!endUserZendesk.zendeskUrl) return null;
              return (
                <Link to={endUserZendesk.zendeskUrl} target="_blank">
                  <Tooltip title="Open in Zendesk">
                    <IconButton color="primary" size="small">
                      <ZendeskIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Link>
              );
            }}
          />
        </ReferenceField>
        <CopyRecordFieldValueToClipboard formatValue={serializeEndUserName} />
      </Stack>
    </Stack>
  );
}
