import { MenuItem } from "@mui/material";
import type { ReactNode } from "react";
import { useStore } from "react-admin";
import type { FrameType } from "./shared";

export const FrameDetailToggler = ({
  type,
  detailKey,
}: {
  detailKey: string;
  type: FrameType;
}) => {
  const [isVisible, setIsVisible] = useFrameDetailVisibility(type, detailKey);

  return (
    <MenuItem
      selected={isVisible}
      onClick={() => {
        setIsVisible((v) => !v);
      }}
    >
      {detailKey}
    </MenuItem>
  );
};

export const FrameDetail = ({
  type,
  detailKey,
  children,
}: {
  detailKey: string;
  type: FrameType;
  children: ReactNode;
}) => {
  const [isVisible] = useFrameDetailVisibility(type, detailKey);

  if (!isVisible) {
    return null;
  }

  return <>{children}</>;
};

const useFrameDetailVisibility = (type: FrameType, detailKey: string) => {
  return useStore(`frames.${type}.${detailKey}.visibility`, true);
};
