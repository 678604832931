import NotObserving from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import Observing from "@mui/icons-material/Wifi";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { Device } from "../types";

export const ObserveField = ({ label }: DatagridOrFilterRequiredProps) => {
  const record = useRecordContext<Device>({ label });

  if (!record || record.majorHWVersion === 1) {
    return null;
  }

  return record.isObserving ? <Observing /> : <NotObserving />;
};
