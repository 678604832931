import EditIcon from "@mui/icons-material/Edit";
import { DialogActions, DialogContent } from "@mui/material";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { Form, SaveButton } from "react-admin";
import AutocompleteTagInput from "../../../../device-tags/inputs/AutocompleteTagInput";

export default function EditTagsDialog() {
  return (
    <EditInDialogButton inline icon={<EditIcon fontSize="small" />}>
      <Form>
        <DialogContent>
          <AutocompleteTagInput />
        </DialogContent>
        <DialogActions>
          <SaveButton />
        </DialogActions>
      </Form>
    </EditInDialogButton>
  );
}
