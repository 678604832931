import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout";
import {
  Datagrid,
  DeleteButton,
  List,
  SimpleForm,
  TextField,
  TopToolbar,
} from "react-admin";
import { ListItemForm } from "./inputs/ListItemForm";

export const ListItemList = () => {
  return (
    <List perPage={100} actions={<ListActions />}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="List" source="listId" />
        <TextField label="Value" source="value" />
        <TextField label="Label" source="label" />
        <EditInDialogButton>
          <SimpleForm>
            <ListItemForm />
          </SimpleForm>
        </EditInDialogButton>
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const ListActions = () => {
  return (
    <TopToolbar>
      <CreateInDialogButton>
        <SimpleForm>
          <ListItemForm />
        </SimpleForm>
      </CreateInDialogButton>
    </TopToolbar>
  );
};
