import CommunicationIcon from "@mui/icons-material/Email";
import { CommunicationList } from "./List";
import { CommunicationShow } from "./Show";

export const communicationResource = {
  icon: CommunicationIcon,
  options: { label: "Communications" },
  list: CommunicationList,
  show: CommunicationShow,
};

export { default as CommunicationIcon } from "@mui/icons-material/Email";
