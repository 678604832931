import { Box, LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { get, groupBy, mapValues, orderBy } from "lodash";
import { cloneElement, useEffect, useMemo } from "react";
import type { ReactElement, ReactNode } from "react";
import type { RaRecord } from "react-admin";
import {
  RecordContextProvider,
  ReferenceFieldContextProvider,
  ReferenceFieldView,
  ResourceContextProvider,
  useDataProvider,
  useGetPathForRecord,
  useListContext,
  useRecordContext,
} from "react-admin";

type OptimizedReferenceOneFieldProps = {
  reference: string;
  target: string;
  sortField?: string;
  sortOrder?: "asc" | "desc";
  label: string;
  sortable?: boolean;
  link?: string | false;
  children: ReactNode;
  fields: string[];
  filter?: any;
  noDataChildren?: ReactNode;
};

export const OptimizedReferenceOneField = <Reference extends RaRecord>({
  reference,
  target,
  sortField = "id",
  sortOrder = "asc",
  children,
  fields,
  filter,
  link,
  noDataChildren,
}: OptimizedReferenceOneFieldProps) => {
  const { data } = useListContext();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: references,
    refetch,
    error,
  } = useQuery<Reference[], Error>({
    queryKey: [
      reference,
      "referenceOneField",
      { sortField, sortOrder, target, fields, filter },
    ],
    queryFn: () =>
      dataProvider
        .getList<Reference>(reference, {
          pagination: { perPage: 10_000, page: 1 },
          sort: { field: "id", order: "ASC" },
          filter: { ...filter, [target]: data?.map(({ id }) => id) },
          meta: { fields: [...fields, target, sortField] },
        })
        .then(({ data }) => {
          return data;
        }),

    enabled: !!data,
  });

  const indexedReferences = useMemo(
    () =>
      mapValues(
        groupBy(references, (v) => get(v, target)),
        (v) => orderBy(v, sortField, sortOrder)[0]
      ),
    [references, sortField, sortOrder, target]
  );

  useEffect(() => {
    refetch();
  }, [data, refetch]);

  const item = record && indexedReferences[record.id];

  const path = useGetPathForRecord({
    record: item,
    resource: reference,
    link,
  });

  if (!references || !record) {
    return <LinearProgress />;
  }

  if (!item) {
    return noDataChildren ?? null;
  }

  const context = {
    referenceRecord: item,
    error,
    isFetching: false,
    isLoading: false,
    isPending: false,
    refetch: refetch as any,
    link: path,
  };

  return (
    <ResourceContextProvider value={reference}>
      <ReferenceFieldContextProvider value={context}>
        <RecordContextProvider value={item}>
          <ReferenceFieldView reference={reference} source="id">
            {children}
          </ReferenceFieldView>
        </RecordContextProvider>
      </ReferenceFieldContextProvider>
    </ResourceContextProvider>
  );
};

export const useOptimizedReferences = <R extends RaRecord>({
  reference,
  target,
  fields,
  filter,
}: {
  reference: string;
  target: string;
  fields: string[];
  filter?: any;
}) => {
  const { data } = useListContext<R>();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: references,
    refetch,
    error,
  } = useQuery({
    queryKey: [
      target,
      "useOptimizedReferences",
      { target, fields },
      data?.map(({ id }) => id).join("-"),
    ],
    queryFn: () =>
      dataProvider
        .getList<R>(reference, {
          pagination: { perPage: 10_000, page: 1 },
          sort: { field: "id", order: "ASC" },
          filter: { ...filter, [target]: data?.map(({ id }) => id) },
          meta: { fields: [...fields, target] },
        })
        .then(({ data }) => groupBy(data, (v) => v[target])),
    enabled: !!data,
  });

  return {
    data: (record && references?.[record.id]) || [],
    refetch,
    error,
  };
};

type OptimizedReferencesFieldProps<R extends RaRecord> = {
  reference: string;
  target: string;
  label: string;
  sortable?: boolean;
  fields: string[];
  filter?: any;
  getLink?: (record: R) => string;
  children: ReactElement;
};

export const OptimizedReferencesField = <R extends RaRecord>({
  reference,
  target,
  children,
  fields,
  filter,
  getLink,
}: OptimizedReferencesFieldProps<R>) => {
  const { data, refetch, error } = useOptimizedReferences<R>({
    reference,
    target,
    fields,
    filter,
  });
  const record = useRecordContext();

  if (!data || !record) {
    return <LinearProgress />;
  }

  return (
    <ResourceContextProvider value={reference}>
      <Box>
        {data.map((item) => {
          const context = {
            referenceRecord: item,
            error,
            isFetching: false,
            isLoading: false,
            isPending: false,
            refetch: refetch as any,
            link: getLink && getLink(item),
          };

          return (
            <ReferenceFieldContextProvider value={context}>
              <RecordContextProvider value={item}>
                <ReferenceFieldView reference={reference} source="id">
                  {cloneElement(children)}
                </ReferenceFieldView>
              </RecordContextProvider>
            </ReferenceFieldContextProvider>
          );
        })}
      </Box>
    </ResourceContextProvider>
  );
};
