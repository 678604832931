import { DateField } from "react-admin";

export const ProjectDeploymentStartDate = ({ label }: { label?: string }) => {
  return (
    <DateField
      label={label || "Deployment Start Date"}
      source="deployment.deploymentStartDate"
      variant="body1"
      emptyText="-"
    />
  );
};
