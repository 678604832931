import {
  Search,
  SearchResultItem,
  SearchResultsPanel,
} from "@react-admin/ra-search";
import { resources } from "../resources";

export const ElaxSearch = () => (
  <Search>
    <SearchResultsPanel>
      <SearchResultItem
        label={(record) => {
          const Icon = resources[record!.type as keyof typeof resources].icon;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon />
              <span style={{ marginLeft: 5 }}>{record!.content.label}</span>
            </div>
          );
        }}
      />
    </SearchResultsPanel>
  </Search>
);
