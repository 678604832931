import ConfigFrameIcon from "@mui/icons-material/Send";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { serializeConfigFrame } from "../../../../../frames/config/type";
import type { ConfigFrame } from "../../../../../frames/config/type";
import type { FrameSpecs } from "./shared";

export const configFrameSpecs: FrameSpecs<
  ConfigFrame & { acknowledgedAt: Date }
> = {
  icon: <ConfigFrameIcon sx={{ color: "#3f51b5" }} />,
  data: [
    {
      key: "Acknowledged" as const,
      label: (frame) =>
        frame.acknowledgedAt ? (
          <Tooltip title={dayjs(frame.acknowledgedAt).format("HH:mm:ss")}>
            <span style={{ marginRight: 3 }}>✅</span>
          </Tooltip>
        ) : frame.acknowledgedAt === false ? (
          <span style={{ marginRight: 3 }}>❌</span>
        ) : (
          <Tooltip title="Not enough data loaded to check acknowledgement">
            <span style={{ marginRight: 3 }}>❔</span>
          </Tooltip>
        ),
    },
    {
      key: "SetPoint" as const,
      label: (frame) => serializeConfigFrame(frame),
    },
    {
      key: "Origin" as const,
      label: (frame) => ` (${frame.origin})`,
    },
  ],
};
