import { Tooltip } from "@mui/material";
import type { TooltipProps } from "@mui/material";

export const TooltipWithDisabledSupport = ({
  children,
  ...props
}: TooltipProps) => (
  <Tooltip {...props}>
    <span>{children}</span>
  </Tooltip>
);
