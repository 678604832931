import { Download, Upload } from "@mui/icons-material";
import { useRecordContext } from "react-admin";
import { DemandResponseType } from "../types";
import type { DemandResponse } from "../types";

export const DemandResponseTypeField = ({
  fontSize,
}: {
  label?: string;
  fontSize?: "large";
}) => {
  const record = useRecordContext<DemandResponse>();

  if (record?.type === DemandResponseType.DOWN) {
    return <Download color="primary" fontSize={fontSize} />;
  } else {
    return <Upload color="warning" fontSize={fontSize} />;
  }
};
