import DealIcon from "@mui/icons-material/BusinessCenter";
import { DealList } from "./List";
import { DealShow } from "./Show";

export const dealResource = {
  icon: DealIcon,
  list: DealList,
  show: DealShow,
  recordRepresentation: "name",
};

export { default as DealIcon } from "@mui/icons-material/BusinessCenter";
