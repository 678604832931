import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout";
import {
  Datagrid,
  List,
  Pagination,
  ReferenceManyCount,
  ReferenceManyField,
  SimpleForm,
  SimpleList,
  TopToolbar,
} from "react-admin";
import TagField from "./fields/TagField";
import TagForm from "./inputs/TagForm";

export default function DeviceTagList() {
  return (
    <List perPage={100} actions={<ListActions />}>
      <Datagrid bulkActionButtons={false} expand={<DeviceList />}>
        <TagField label="Name" />
        <ReferenceManyCount
          reference="devices"
          target="tags"
          label="Devices concerned"
        />
        <EditInDialogButton>
          <SimpleForm>
            <TagForm />
          </SimpleForm>
        </EditInDialogButton>
      </Datagrid>
    </List>
  );
}

const DeviceList = () => {
  return (
    <ReferenceManyField
      reference="devices"
      target="tags"
      pagination={<Pagination />}
    >
      <SimpleList primaryText={(record) => record.name} linkType="show" />
    </ReferenceManyField>
  );
};

const ListActions = () => {
  return (
    <TopToolbar>
      <CreateInDialogButton>
        <SimpleForm>
          <TagForm />
        </SimpleForm>
      </CreateInDialogButton>
    </TopToolbar>
  );
};
