import FilterIcon from "@mui/icons-material/FilterList";
import FilterOffIcon from "@mui/icons-material/FilterListOff";
import { IconButton } from "@mui/material";
import { JsonParam, useQueryParam } from "use-query-params";

export const ToggleFiltersButton = () => {
  const [isVisible, setIsVisible] = useQueryParam("filter", JsonParam);
  return (
    <IconButton onClick={() => setIsVisible(isVisible ? undefined : {})}>
      {isVisible ? <FilterOffIcon /> : <FilterIcon />}
    </IconButton>
  );
};
