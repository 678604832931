import Icon from "@mui/icons-material/LocalFireDepartment";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecordContext } from "react-admin";
import CardWithIcon from "../../../layout/CardWithIcon";
import { heatingStates } from "../../../layout/dashboard/widgets/HeaterState";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const HeaterField = () => {
  const record = useRecordContext();

  return (
    <CardWithIcon
      icon={Icon}
      color="#ff5722"
      data={
        record
          ? [
              {
                label: "Model",
                value: record.model ?? "Unknown",
              },
              {
                label: "Power",
                value: `${record.power ? `${record.power}W` : "Unknown"}${
                  record.computed_electric_power
                    ? ` (${record.computed_electric_power}W observed)`
                    : ""
                }`,
              },
              {
                label: "Volume",
                value: record.volume ? `${record.volume}L` : "Unknown",
              },
              {
                label: "Position",
                value:
                  record.vertical === true
                    ? "Vertical"
                    : record.vertical === false
                    ? "Horizontal"
                    : "Unknown",
              },
              {
                label: "Heating Quality",
                value: heatingStates.find((s) => s.selector === record.state)
                  ?.label,
              },
            ]
          : []
      }
    />
  );
};
