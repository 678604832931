import { Alert, Stack, Tooltip, Typography, alertClasses } from "@mui/material";
import type { ReactNode } from "react";
import { LinearProgress } from "react-admin";

export const RateBox = ({
  icon,
  title,
  value,
  total,
  tooltip,
}: {
  icon: ReactNode;
  title: string;
  value?: number;
  total?: number;
  tooltip?: string;
}) => {
  const isLoading = value === undefined;

  const isPercentage = total === undefined;

  return (
    <Alert
      icon={icon}
      severity="info"
      sx={{
        width: "100%",
        [`& .${alertClasses.message}`]: { width: "100%" },
      }}
    >
      <Typography fontWeight="bold">{title}</Typography>

      <Stack direction="row" alignItems="center" spacing={2}>
        <LinearProgress
          variant={isLoading ? "indeterminate" : "determinate"}
          value={
            isLoading ? undefined : isPercentage ? value : (100 * value) / total
          }
          sx={{ flexGrow: 1, height: 10, borderRadius: 5 }}
        />
        {!isLoading && (
          <Tooltip title={tooltip || ""}>
            <Typography variant="h5">
              {isPercentage ? `${value} %` : `${value} / ${total}`}
            </Typography>
          </Tooltip>
        )}
      </Stack>
    </Alert>
  );
};
