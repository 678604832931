import { Button, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useRedirect } from "react-admin";
import { StringParam, useQueryParam } from "use-query-params";
import { setToken } from "../../providers/auth";
import { dataProvider } from "../../providers/data";
import { NoLayoutCard } from "../NoLayoutCard";

export const SetPassword = () => {
  const [token] = useQueryParam("token", StringParam);
  const redirect = useRedirect();

  const [password, setPassword] = useState("");
  const mutation = useMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      setToken(token);
      const { data } = await dataProvider.setPassword(password);
      setToken(data.token);
      redirect("/");
    },
  });

  return (
    <NoLayoutCard>
      <TextField
        type="password"
        label="Password"
        size="small"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />
      <Button
        variant="contained"
        sx={{ marginY: 2 }}
        onClick={() => {
          mutation.mutate();
        }}
        disabled={mutation.isPending}
      >
        Save your password
      </Button>
    </NoLayoutCard>
  );
};
