import YouTubeIcon from "@mui/icons-material/YouTube";
import { ToggleButton } from "@mui/material";
import type { ToggleButtonProps } from "@mui/material";
import { useEditorSelection, useTiptapEditor } from "ra-input-rich-text";
import type { Except } from "type-fest";

export const YoutubeButton = (props: Except<ToggleButtonProps, "value">) => {
  const editor = useTiptapEditor();
  const currentTextSelection = useEditorSelection();

  const handleClick = () => {
    const url = currentTextSelection || window.prompt("URL");

    if (!url) {
      return;
    }

    editor.commands.setYoutubeVideo({
      src: url,
    });
  };

  return (
    <ToggleButton
      title="Youtube"
      {...props}
      disabled={!editor?.isEditable}
      value="youtube"
      onClick={handleClick}
      selected={editor && editor.isActive("link")}
    >
      <YouTubeIcon fontSize="inherit" />
    </ToggleButton>
  );
};
