import { omit } from "lodash";
import { useCallback } from "react";
import { JsonParam, useQueryParam } from "use-query-params";

export const useToggleFilter = (source: string) => {
  const [filters, setFilters] = useQueryParam("filters", JsonParam);

  const toggle = useCallback(
    (value: any) => {
      if (filters && filters[source] === value) {
        setFilters(omit(filters, [source]));
      } else {
        setFilters({ ...filters, [source]: value });
      }
    },
    [setFilters, source, filters]
  );

  return [filters?.[source], toggle] as const;
};
