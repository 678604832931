import ResidenceIcon from "@mui/icons-material/Apartment";
import { ResidenceCreate, ResidenceEdit } from "./Edit";
import { ResidenceList } from "./List";
import { ResidenceShow } from "./show";

export const residenceResource = {
  icon: ResidenceIcon,
  list: ResidenceList,
  edit: ResidenceEdit,
  create: ResidenceCreate,
  show: ResidenceShow,
  recordRepresentation: "esi",
};

export { default as ResidenceIcon } from "@mui/icons-material/Apartment";
