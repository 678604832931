import { sumBy } from "lodash";
import { Chart } from "../../Chart";

type CountEnumChartProps = {
  title: string;
  onClick?: (point: { name: string; custom?: any }) => void;
  data: { name: string; y: number; custom?: any }[] | undefined;
};

export const CountEnumChart = ({
  title,
  onClick,
  data,
}: CountEnumChartProps) => {
  const total = sumBy(data, (point) => point.y);

  return (
    <Chart
      options={{
        chart: {
          type: "pie",
        },
        title: {
          text: title,
        },
        subtitle: {
          text: `Total: ${total}`,
        },
        series: [
          {
            type: "pie",
            data: data?.map((point) => ({
              ...point,
              custom: {
                ...point.custom,
                percentage: (point.y / total) * 100,
              },
            })),
          },
        ],
        tooltip: {
          headerFormat: undefined,
          pointFormat:
            "{point.name}: <b>{point.custom.percentage:.1f}% ({point.y})</b>",
        },
        plotOptions: {
          pie: {
            size: "60%",
            dataLabels: {
              alignTo: "connectors",
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.custom.percentage:.1f}%",
            },
            events: onClick
              ? {
                  click: (event) => {
                    onClick(event.point);
                  },
                }
              : {},
          },
        },
      }}
      isLoading={!data}
    />
  );
};
