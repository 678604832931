import { Grid } from "@mui/material";
import { useCanAccess } from "@react-admin/ra-rbac";
import {
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import type { Device } from "../devices/types";
import { SchedulePreview } from "./SchedulePreview";
import { Parameters } from "./parameters";
import { Mode } from "./types";

export const DeviceConfigEditionForm = ({
  device,
  ...props
}: {
  device: Device;
}) => {
  const allowedModes = useAllowedModes(
    device,
    device.HoraireHCHP ? device.HoraireHCHP.length > 0 : null
  );

  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: "none" }}>
          <ReferenceInput reference="devices" source="deviceId" />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            source="mode"
            choices={allowedModes.map((mode) => ({
              id: mode,
              name: mode,
            }))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Why do you do this change?"
            source="notes"
            fullWidth
            multiline
            minRows={3}
            required
          />
        </Grid>
        <Grid item container xs={12}>
          <Parameters
            device={device}
            source="parameters"
            isHPHC={device.HoraireHCHP ? device.HoraireHCHP.length > 0 : false}
          />
        </Grid>
        <Grid item container xs={12}>
          <SchedulePreview device={device} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const useAllowedModes = (
  device: Pick<Device, "bypassedRemoteSwitch" | "majorHWVersion">,
  isHPHC: boolean | null
) => {
  const { canAccess } = useCanAccess({
    action: "edit",
    resource: "device-configs.complex-modes",
  });

  if (!canAccess) {
    return [Mode.SET_POINT, Mode.SCHEDULE];
  }

  if (device.majorHWVersion === 3) {
    return [Mode.SET_POINT, Mode.SCHEDULE];
  }

  if (device.majorHWVersion === 2) {
    return [
      Mode.OBSERVATION,
      Mode.SET_POINT,
      Mode.SCHEDULE,
      Mode.SMART,
      Mode.SMART_ENERGY,
    ];
  }

  if (device.bypassedRemoteSwitch && isHPHC) {
    return [Mode.SET_POINT, Mode.PRE_OBSERVATION, Mode.SCHEDULE, Mode.SMART];
  }

  if (isHPHC === null) {
    return [Mode.SET_POINT, Mode.SMART];
  }

  return [
    Mode.SET_POINT,
    Mode.PRE_OBSERVATION,
    Mode.SCHEDULE,
    Mode.SMART,
    Mode.SMART_ENERGY,
    Mode.SMART_FLEX,
    Mode.SMART_FLEX_v2,
  ];
};
