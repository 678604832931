import { Box } from "@mui/material";
import { TextInput } from "react-admin";
import ColorPicker from "../../../fields/ColorPicker";

export default function TagForm() {
  return (
    <Box sx={{ minWidth: 300 }}>
      <TextInput label="Name" source="name" fullWidth required autoFocus />
      <ColorPicker label="Color" source="color" fullWidth />
    </Box>
  );
}
