import type { ReactNode } from "react";
import type { Identifier, RaRecord } from "react-admin";
import {
  Link,
  useCreatePath,
  useRecordContext,
  useReferenceManyFieldController,
} from "react-admin";
import type { Communication } from "../type";

export const WithLatestCommunicationSent = ({
  target,
  render,
}: {
  target: string;
  render: (lastCommunication: Communication) => ReactNode;
}) => {
  const record = useRecordContext();

  const createPath = useCreatePath();

  const { data, total } = useReferenceManyFieldController<
    RaRecord<Identifier>,
    Communication
  >({
    reference: "communications",
    target,
    record,
    source: "id",
    page: 1,
    perPage: 1,
    sort: { field: "date", order: "DESC" },
  });

  return total ? (
    <Link
      to={createPath({
        resource: "communications",
        id: data[0].id,
        type: "show",
      })}
    >
      {render(data[0])}
    </Link>
  ) : null;
};
