import dayjs from "dayjs";
import { range } from "lodash";
import { SelectInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

export const StartTimeInput = () => {
  const { resetField } = useFormContext();

  return (
    <SelectInput
      source="startTime"
      fullWidth
      choices={range(48)
        .map((i) =>
          dayjs()
            .startOf("days")
            .add(i * 30, "minutes")
            .format("HH:mm")
        )
        .map((value) => ({ id: value, name: value }))}
      onChange={() => {
        resetField("endTime");
      }}
    />
  );
};

export const EndTimeInput = () => {
  const startTime = useWatch({ name: "startTime" });

  return (
    <SelectInput
      source="endTime"
      fullWidth
      disabled={!startTime}
      choices={range(4)
        .map((i) =>
          dayjs(startTime, "HH:mm")
            .add((i + 1) * 30, "minutes")
            .format("HH:mm")
        )
        .map((value) => ({ id: value, name: value }))}
    />
  );
};
