import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { Except } from "type-fest";
import { axiosClient } from "../data";

export const useAxiosQuery = <R>(
  axiosConfig: AxiosRequestConfig,
  config?: Except<
    UseQueryOptions<R, HTTPError>,
    "queryKey" | "queryFn" | "retry"
  >
) => {
  return useQuery<R, HTTPError>({
    ...config,
    queryKey: [
      axiosConfig.url!,
      axiosConfig.data,
      axiosConfig.headers,

      JSON.stringify(axiosConfig.params),
    ],
    queryFn: async () => {
      try {
        const response = await axiosClient.request<R>(axiosConfig);
        return response.data;
      } catch (error: any) {
        return throwHTTPError(error);
      }
    },
    retry: (failureCount, error) =>
      (!error.response?.status || error.response?.status >= 500) &&
      failureCount < 2,
  });
};

export const useAxiosMutation = <D, R = unknown>(
  axiosConfig: AxiosRequestConfig,
  config?: Except<UseMutationOptions<R, HTTPError, D>, "mutationFn">
) => {
  return useMutation({
    ...config,
    mutationFn: async (data) => {
      try {
        const response = await axiosClient.request<R>({
          ...axiosConfig,
          data: { ...axiosConfig.data, ...data },
        });
        return response.data;
      } catch (error: any) {
        return throwHTTPError(error);
      }
    },
  });
};

export class HTTPError extends Error {
  status: number | null;
  constructor(
    readonly message: string,
    public readonly response: AxiosResponse
  ) {
    super(message);
    this.status = response?.status ?? null;
  }
}

const throwHTTPError = (error: any) => {
  if (error.request && !error.response) {
    throw new HTTPError("UNKNOWN_ERROR", error.response);
  }

  if (!error.request || !error.response) {
    throw new HTTPError("UNKNOWN_ERROR", error.response);
  }

  if (error.response!.status >= 500) {
    throw new HTTPError("SERVER_ERROR", error.response);
  }

  throw new HTTPError(error.response!.data?.message, error.response);
};

export const isHTTPError = (error: unknown): error is HTTPError => {
  return (error as any).status !== undefined;
};
