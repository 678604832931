import { HowToReg } from "@mui/icons-material";
import { ImportAction } from "../../../actions/ImportAction";

export function ImportEndUserConsents() {
  return (
    <ImportAction
      endpoint="/end-users/consents"
      title="Import Multiple Consents"
      icon={<HowToReg />}
      templateURL="https://docs.google.com/spreadsheets/d/1YaNxnQR5bULOvurjtigLJeIy0qxTUCXv8v4lh5zsKYM"
    />
  );
}
