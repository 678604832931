import { Grid } from "@mui/material";
import { InterventionsGraph } from "./widgets/Interventions";
import { InterventionsFlow } from "./widgets/InterventionsFlow";

export const InterventionsDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InterventionsGraph />
      </Grid>
      <Grid item xs={12} md={12}>
        <InterventionsFlow />
      </Grid>
    </Grid>
  );
};
