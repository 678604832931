import { Divider } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import { useBulkActionFilter } from "../../../actions/useBulkActionFilter";
import { ActionsMenu } from "../../../layout/actions-menu";
import { DeviceTagIcon } from "../../device-tags";
import { ExportDevicesOrEndUsers } from "../../end-users/actions/Export";
import { GroupIcon } from "../../group";
import { ExportDeviceFrames } from "../show/actions/ExportDeviceFrames";
import { AssignFirmware } from "./AssignFirmware";
import { EditDevicesButton } from "./BulkEditDevices";
import { ChangePilotConfig } from "./edit-device-config";

export const DeviceBulkActionButtons = () => {
  const filter = useBulkActionFilter();
  return (
    <>
      <ActionsMenu>
        <ExportDevicesOrEndUsers resource="devices" />
        <ExportDeviceFrames filter={filter} />
        <IfCanAccess resource="devices.reports" action="read">
          <ConsumptionExport filter={filter} />
        </IfCanAccess>
        <Divider />
        <EditDevicesButton
          resource="groups"
          source="group"
          label="Groups"
          icon={<GroupIcon />}
        />
        <EditDevicesButton
          resource="device-tags"
          source="tag"
          label="Tags"
          icon={<DeviceTagIcon />}
        />
        <ChangePilotConfig filter={filter} />
        <AssignFirmware filter={filter} />
      </ActionsMenu>
    </>
  );
};
