import { EnumField } from "../../../fields/EnumField";
import { zendeskTicketStatusColors } from "../type";

export const ZendeskTicketStatusField = ({ label }: { label?: string }) => (
  <EnumField
    label={label || "Status"}
    source="status"
    colors={zendeskTicketStatusColors}
  />
);
