import Photo from "@mui/icons-material/Photo";
import {
  Button,
  Dialog,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { flatMap } from "lodash";
import { useState } from "react";
import { TextField, useGetList, useRecordContext } from "react-admin";
import { InlineLabel } from "../../../layout/InlineLabel";
import { bucket } from "../../intervention/fields/Documents";

const useHousingPDLPictures = () => {
  const record = useRecordContext();
  const { data: interventions } = useGetList(
    "interventions",
    { filter: { housingId: record?.id } },
    { enabled: !!record }
  );
  const { data: docs } = useGetList(
    "documents",
    {
      filter: {
        id: flatMap(interventions, (i) => i.documentIds),
        context: ["PDL or PRM photo", "enduser_PRM"],
      },
    },
    { enabled: !!interventions }
  );

  return docs;
};

export const PDL = () => {
  const docs = useHousingPDLPictures();
  const [doc, selectedDoc] = useState<any>();

  return (
    <>
      <InlineLabel label="PDL">
        <TextField source="pdl" defaultValue="-" />
        {docs?.map((doc) => (
          <IconButton onClick={() => selectedDoc(doc)}>
            <Photo />
          </IconButton>
        ))}
        <Dialog open={!!doc} onClose={() => selectedDoc(undefined)}>
          <img
            alt={doc?.context}
            src={`${bucket}/${doc?.id}.${doc?.extension}`}
          />
        </Dialog>
      </InlineLabel>
    </>
  );
};

export const PDLDrawer = () => {
  const docs = useHousingPDLPictures();
  const [doc, selectedDoc] = useState<any>();

  return (
    <>
      {docs?.map((doc) => (
        <Tooltip title={dayjs(doc.createdAt).format("YYYY-MM-DD")}>
          <IconButton onClick={() => selectedDoc(doc)}>
            <Photo />
          </IconButton>
        </Tooltip>
      ))}
      <Drawer
        open={!!doc}
        onClose={() => selectedDoc(undefined)}
        anchor="right"
        variant="persistent"
      >
        <img
          alt={doc?.context}
          src={`${bucket}/${doc?.id}.${doc?.extension}`}
          width={400}
        />
        <Typography variant="caption">
          {doc?.createdAt && dayjs(doc.createdAt).format("YYYY-MM-DD")}
        </Typography>
        <Button onClick={() => selectedDoc(undefined)}>Close</Button>
      </Drawer>
    </>
  );
};
