import { blue } from "@mui/material/colors";
import type { ReactNode } from "react";
import { EnumField } from "../../../fields/EnumField";
import { ContractType } from "../types";

export const contractTypeLabels: Record<ContractType, string> = {
  [ContractType.BASE]: "Base",
  [ContractType.HP_HC]: "HC/HP",
  [ContractType.UNKNOWN]: "Unknown",
};

export const ContractTypeField = ({
  source = "contractType",
  inline,
}: {
  source?: string;
  label?: string;
  inline?: boolean;
}): ReactNode => {
  return (
    <EnumField
      inline={inline}
      source={source}
      labels={contractTypeLabels}
      colors={{
        [ContractType.BASE]: blue[800],
        [ContractType.HP_HC]: blue[500],
        [ContractType.UNKNOWN]: blue[300],
      }}
    />
  );
};
