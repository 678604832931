import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  Create,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const ResidenceEdit = () => (
  <Edit>
    <EditionForm />
  </Edit>
);

export const ResidenceCreate = () => (
  <Create>
    <EditionForm />
  </Create>
);

const EditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput label="ESI" source="esi" required={true} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            label="Landlord"
            source="organizationId"
            reference="organizations"
            required={true}
            fullWidth
            disabled
            helperText="From pipedrive"
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Address"
            source="address"
            helperText="1 rue de la Paix"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            label="Zip Code"
            source="zipCode"
            helperText="75006"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <TextInput
            label="City"
            source="city"
            helperText="Paris"
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Comment"
            source="comment"
            fullWidth
            multiline
            minRows={8}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
