import { Divider } from "@mui/material";
import { useRecordContext } from "react-admin";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import { ActionsMenu, EditAction } from "../../../layout/actions-menu";
import { AssignFirmware } from "../../devices/bulk-actions/AssignFirmware";
import { ChangePilotConfig } from "../../devices/bulk-actions/edit-device-config";
import type { Project } from "../type";
import { ExportInterventions } from "./Export";
import { SynchroniseListing } from "./SynchroniseListing";

export const ProjectShowActions = () => {
  const project = useRecordContext<Project>();

  return (
    <ActionsMenu>
      <EditAction />
      <AssignFirmware filter={{ projectId: project?.id }} />
      <ChangePilotConfig filter={{ projectId: project?.id }} />
      <Divider />
      <ConsumptionExport filter={{ projectId: project?.id }} />
      <ExportInterventions />
      <Divider />
      <SynchroniseListing />
    </ActionsMenu>
  );
};
