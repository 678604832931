import {
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
} from "react-admin";
import { SelectProject } from "../../../devices/fields/SelectProject";
import { codeToLabel } from "../../fields/AlertCodeField";
import type { AlertCode } from "../../types";
import { CriticalFilter } from "./CriticalFilter";
import { OngoingFilter } from "./OngoingFilter";

export const alertFilters = [
  <CriticalFilter
    label="Critical Alerts"
    source="critical"
    defaultValue={true}
  />,
  <OngoingFilter label="Ongoing" source="ongoing" defaultValue={true} />,
  <ReferenceArrayInput source="code" reference="alert-codes">
    <SelectArrayInput
      optionText={(record) => codeToLabel[record.name as AlertCode]?.label}
    />
  </ReferenceArrayInput>,
  <ReferenceInput source="deviceId" reference="devices" label="Device">
    <AutocompleteInput optionText="name" fullWidth />
  </ReferenceInput>,
  <SelectProject label="Project" source="projectId" />,
];
