import { Divider } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useRecordContext } from "react-admin";
import { ActionsMenu, EditAction } from "../../../../layout/actions-menu";
import type { Device } from "../../types";
import { ExportDeviceFrames } from "./ExportDeviceFrames";
import { ReplaceButton } from "./Replace";
import {
  HardResetAction,
  RequestDebugAction,
  RequestDeviceCertificateUpdateAction,
  RequestRecoveryAction,
  RequestServerCaUpdateAction,
  RequestStatusAction,
} from "./send-config-flags";
import { UninstallButton } from "./uninstall";

export const DeviceShowActions = () => {
  const device = useRecordContext<Device>();

  return (
    <ActionsMenu>
      <EditAction />
      <ExportDeviceFrames filter={{ id: device?.id }} />
      <Divider />
      <RequestStatusAction />
      <HardResetAction />
      <RequestRecoveryAction />
      <RequestDebugAction />
      <RequestServerCaUpdateAction />
      <RequestDeviceCertificateUpdateAction />
      <Divider />
      <IfCanAccess resource="devices" action="uninstall">
        <UninstallButton />
        <ReplaceButton />
      </IfCanAccess>
    </ActionsMenu>
  );
};
