import RemoveIcon from "@mui/icons-material/Remove";
import {
  Button,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";

export const RemoveFromGroupButton = ({ groupId }: { groupId: string }) => {
  const device = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdate(
    "devices",
    {
      id: device?.id,
      data: { group: device?.group.filter((g: string) => g !== groupId) },
    },
    {
      onSuccess: () => {
        refresh();
        notify("Done");
      },
      onError: (error: any) =>
        notify(`Error: ${error.message}`, { type: "warning" }),
    }
  );

  return (
    <Button
      label="Remove"
      color={"error" as any}
      variant="contained"
      startIcon={<RemoveIcon />}
      onClick={() => update()}
    />
  );
};
