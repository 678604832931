import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";

export const PipedriveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 32 32"
        fill="#017737"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_503_588)">
          <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"></path>
          <path
            d="M24.9842 13.4564C24.9842 17.8851 22.1247 20.914 18.036 20.914C16.0923 20.914 14.4903 20.1136 13.8906 19.1134L13.9189 20.142V26.4847H9.74512V10.0846C9.74512 9.85644 9.68836 9.79843 9.4304 9.79843H8V6.31321H11.4889C13.0896 6.31321 13.4907 7.68461 13.6042 8.28525C14.2337 7.22834 15.8911 6 18.2359 6C22.2679 5.99871 24.9842 8.99802 24.9842 13.4564ZM20.724 13.4847C20.724 11.1131 19.1801 9.48523 17.2351 9.48523C15.6344 9.48523 13.8325 10.5421 13.8325 13.5144C13.8325 15.4568 14.9186 17.4855 17.1783 17.4855C18.837 17.4842 20.724 16.2843 20.724 13.4847Z"
            fill="#FFFFFF"
          ></path>
        </g>
      </svg>
    </SvgIcon>
  );
};
